import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs';
import {  HistoryDTO, IHistory,IObjectListDetalle,IObjectListHistory, IObjectListTransmission, IuserInformation } from '../../models/history/history';
import { MappingService } from '../mapping/mapping.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  public historyUrl = environment.api+"registry/"

  public username: string = "";


  constructor(private http: HttpClient, private mappingService: MappingService) {
    this.loadUsername()
  }

  /**
   * Obtiene los valores del detalle de las tablas.
   */
  list(page:number,table:string, crmType:string,elements=parseInt("7")) {
    return this.http.get<any>(this.historyUrl+"findAllInTablePaginated?page="+page+"&elements="+elements+"&tableName="+table+"&crmType="+crmType).pipe(
      map((response:any)=>{
        if(table==="VCategorizacion"){
          return {
            total: response.data.totalElements,
            data: response.data.objectList.map((item:IObjectListHistory)=>(
              this.mappingService.historyCategorizationBtoF(item)
            ))
          };
        }else if(table==="VTransmision"){
          return {
            total: response.data.totalElements,
            data: response.data.objectList.map((item:IObjectListTransmission)=>(
              this.mappingService.historyTransmisionBtoF(item)
            ))
          };
        }else{
          return {
            total: response.data.totalElements,
            data: response.data.objectList.map((item:IObjectListDetalle)=>(
              this.mappingService.historyDetailBtoF(item)
            ))
          };
        }

      })
    );
  }

    download(data:HistoryDTO,crmType:string,download:boolean=false){
      return this.http.post(this.historyUrl + "filter?crmType="+crmType+"&download="+download, data).pipe(
        map((response:any)=>{
          if(data.table==="VCategorizacion"){
            return {
              total: response.data.resultRegistry.totalElements,
              data: response.data.resultRegistry.objectList.map((item:IObjectListHistory)=>(
                this.mappingService.historyCategorizationBtoF(item)
              ))
            };
          }else if(data.table==="VTransmision"){
            return {
              total: response.data.resultRegistry.totalElements,
              data: response.data.resultRegistry.objectList.map((item:IObjectListTransmission)=>(
                this.mappingService.historyTransmisionBtoF(item)
              ))
            };
          }else{
            return {
              total: response.data.resultRegistry.totalElements,
              data: response.data.resultRegistry.objectList.map((item:IObjectListHistory)=>(
                this.mappingService.historyDetailBtoF(item)
              ))
            };
          }

        })
      )
    }

  /**
   * Obtiene el nombre del usuario logueado y lo almacena en el localStorage.
   * @return retorna la informacion completa del usuario logueado
   */
   name() {
    return this.http.get<IuserInformation>("https://graph.microsoft.com/v1.0/me")
      .pipe(
        tap(name => localStorage.setItem('name', JSON.stringify(name.displayName)))
      )
  }

    /**
   * Obtiene el nombre del usuario logueado y lo almacena en el localStorage.
   */
   loadUsername() {
    if (!localStorage.getItem('name')) {
      this.name();
    }
    this.username = JSON.parse(localStorage.getItem('name')!)
  };

}
