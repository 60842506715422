import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TransmissionService } from 'src/app/core/services/transmission/transmission.service';
import { BaseTable } from '../../../../shared/components/baseTable/BaseTable';
import { colors } from 'src/app/shared/constants/colors';
import { TransmissionDetailStoreService } from 'src/app/core/stores/transmissionDetailStore/transmission-detail-store.service';
import { MappingService } from 'src/app/core/services/mapping/mapping.service';
import Swal from 'sweetalert2';
import { IFilterDetail } from 'src/app/core/models/transmission/IFilterDetail';
import { Subscription } from 'rxjs';
import { ISfcValues } from 'src/app/core/models/transmission/ITransmission';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';

@Component({
  selector: 'app-transmission-detail-table',
  templateUrl: './transmission-detail-table.component.html',
  styleUrls:['./transmission-detail-table.component.scss']
})
export class TransmissionDetailTableComponent extends BaseTable implements OnInit {
  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;
  public crmValue: string | null = "";
  public field:string=""
  public columns: string[] = [];
  public filterText: string = "";
  public filterColumns: any[] = [{}];
  public filtersApply: Set<string> = new Set();
  public loading = true;
  public columnOptionsFilter: { [key: string]: string[] } = {};
  public momento: number = 1;
  public editColumns=new Set(["Código SFC","Nombre SFC"])
  public notReadColumns=new Set(["Código SFC"])
  public valuesAutocomplete:{[key:string]:any}={
    "Código SFC":{
    },
  };
  public pairDictionary: { [key: string]: string } = {
    "Código SFC": "Nombre SFC",
  }
  public newColumn=false;
  public isFilter=false;

  public columnsIcon: { [key: string]: string } = {};
  public qr: IFilterDetail = {
    page: 0,
    elements: 7,
    keyWord: "",
    filterObject: {
        hmlCod: "",
        crmHRefCod: "",
        crmValue: "",
        crmDetail: "",
        sfcHRefCod: "",
        sfcValue: "",
        sfcDetail: ""
    }
  };

  subscriptionReload: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private transmissionService: TransmissionService,
    private transmissionDetailStoreService: TransmissionDetailStoreService,
    private mappingService: MappingService
  ) {
    super(transmissionService, transmissionDetailStoreService);
  }


  ngOnInit(): void {
    this.crmValue = localStorage.getItem('crmType');
    this.transmissionDetailStoreService.getColumnsIcon().subscribe((value:any)=>{
      this.columnsIcon=value
    })

    this.transmissionDetailStoreService.getField().subscribe((value:string)=>{
      this.field=value
    })

    this.transmissionDetailStoreService.setRequestData(this.qr)

    this.transmissionDetailStoreService.getMomentSelect().subscribe((value:number)=>{
      this.momento=value;
    })

    this.transmissionDetailStoreService.getLoading().subscribe((value:boolean)=>{
      this.loading = value;
    })

    this.transmissionDetailStoreService.getData().subscribe((data: any) => {
      if (Object.keys(data).length != 0) {
        this.reorganizarData(data);
        this.loading = false;
      }
    });

    this.transmissionDetailStoreService.getIsFilter().subscribe((value: boolean) => {
      this.isFilter = value;
    })

    this.transmissionDetailStoreService
      .getCurrentPage()
      .subscribe((pageNumber: number) => {
        this.currentPage = pageNumber;
      });


    this.transmissionDetailStoreService
    .getTotal()
    .subscribe((totalElements: number) => {
      this.total = totalElements;
      if(this.total==0){
        this.loading=true
      }
    });

    this.transmissionDetailStoreService
    .getValuesAutocomplete()
    .subscribe((ans: any) => {
      this.valuesAutocomplete = ans;
    });

    this.subscriptionReload= this.transmissionDetailStoreService.getReload().subscribe(()=>{
      this.getTransmissionDetail();
    })

    this.transmissionDetailStoreService.getRequestData().subscribe((value:any)=>{
      this.qr=value
    })

  }

  ngOnDestroy(): void {
    this.subscriptionReload.unsubscribe();
  }



  onChangePage(paginationData: any) {
    this.circleLoading.show()
    this.itemsPerPage=paginationData.PerPage;
    this.currentPage=paginationData.CurrentPage;
    const changePage = this.currentPage - 1;
    const valuesPage = this.transmissionDetailStoreService.getData().getValue();
    const exists = Object.keys(valuesPage).includes(
      changePage.toString()
    );
    if(exists){
      this.reorganizarData(valuesPage);
    }else{
      this.getTransmissionDetail();
    }
  }


  editRow(row: any) {
    for (const filter of Object.keys(this.valuesAutocomplete)) {
      if(Object.keys(this.valuesAutocomplete[filter]).length==0){
        this.transmissionService.filterSfcValues(this.field).subscribe((value:any) => {
          value.forEach((item:ISfcValues)=>{
            this.valuesAutocomplete[filter][item.value]=[item.detail,item.hmlRefCod];
          })
        });
      }
    }
    const copyRow = JSON.parse(JSON.stringify(row));
    this.transmissionDetailStoreService.setPreviousEditData(copyRow)
    row['Editando'] = true;
    row['Color'] = colors['green'];
    this.transmissionDetailStoreService.setPageforCancel(this.currentPage - 1);
    this.transmissionDetailStoreService.setNewEditData(row);
  }

  isReadOnly(row:any,column:string){
    if(row["Editando"]){
      if(this.notReadColumns.has(column)){
        return false;
      }
      return true
    }
    return true

  }

  cancelRowTransmission(row:any){
    this.transmissionDetailStoreService.removeNewValuesColumns(row["ID"])
    this.cancelRow(row)
  }


  updateValue(inputGeneric:any,row:any,column:string){
    const actualValue = (inputGeneric.target as HTMLInputElement).value;
    row[column]=actualValue

    this.transmissionDetailStoreService.setNewEditData(row);

    if(column=="Código SFC"){
      const inputDetail=<HTMLInputElement>document.getElementById("Nombre SFC"+row["ID"]);
      if(actualValue in this.valuesAutocomplete[column]){
        inputDetail.value=this.valuesAutocomplete[column][actualValue][0];
        inputDetail.readOnly=true
        row["Nombre SFC"]=this.valuesAutocomplete[column][actualValue][0];
        row["Codigo hrefSFC"]=this.valuesAutocomplete[column][actualValue][1];
        this.newColumn=false
      }else{
        inputDetail.value="";
        inputDetail.readOnly=false
        row["Nombre SFC"]="";
        row["Codigo hrefSFC"]=null
        this.newColumn=true
      }
    }

    if(this.newColumn){
      const valueNew={
        "id":row["ID"],
        "codigo":row["Código SFC"],
        "nombre":row["Nombre SFC"],
      }
      this.transmissionDetailStoreService.setNewValuesColumns(valueNew)
    }else{
      this.transmissionDetailStoreService.removeNewValuesColumns(row["ID"])
    }

  }


  getTransmissionDetail() {
    if(this.isFilter){
      this.qr.page = this.currentPage - 1;
      this.transmissionService.listFilterDetail(this.qr,this.field,this.momento.toString(),this.crmValue!).subscribe({
        next: (resData: any) => {
          this.organizeData(resData);
          this.transmissionDetailStoreService.setRequestData(this.qr);
        },
        complete: ()=> {
          this.circleLoading.hide();
        },
        error: () => {
          this.circleLoading.hide();
        }
      });
    }else{
    this.transmissionService.listDetail(this.momento,this.field,this.crmValue!,this.currentPage-1).subscribe({
      next: (resData: any) => {
        this.organizeData(resData);
      },
      complete: ()=> {
        this.circleLoading.hide();
      },
      error: () => {
        this.circleLoading.hide();
      }
    }
    )
  }
  }


  organizeData(resData:any){
    resData.data=resData.data.sort((a:any, b:any) => a["Código SFC"] - b["Código SFC"]);
    this.total = resData.total;
    this.transmissionDetailStoreService.setTotal(this.total);
    if (this.total != 0) {
      this.columns = Object.keys(resData.data[0])
    }
    this.arrayData = resData.data;
    this.currentPageItems = this.arrayData;
    resData.page=this.currentPage-1;
    this.transmissionDetailStoreService.setData(resData);
    this.loading = false;
    this.cdr.detectChanges();
  }

  reorganizarData(data: any) {
    this.arrayData = data[this.currentPage - 1];
    this.arrayData.sort((a: any, b: any) => a["Código SFC"] - b["Código SFC"]);
    this.currentPageItems = this.arrayData;
    this.cdr.detectChanges();
    setTimeout(() => {   
      this.circleLoading.hide();
    }, parseInt("200"));
  }

    /**
   * Habilita el dropdown menu del filtro especifico
   */
    toggleDropdown(event: MouseEvent, dropdown: any, column: string) {
      event.stopPropagation();
        const columna = this.mappingService.transmissionDetailColumnNames(column);
        this.transmissionService.detailFilter(columna,Number(this.field),this.momento,this.crmValue!).subscribe({
          next: (data: any) => {
            this.columnOptionsFilter[column] = data.data;
          },
        });

      if (dropdown) {
        if (this.activeDropdown != null) {
          this.activeDropdown.classList.remove('active');
        }

        if (this.activeDropdown === dropdown) {
          this.activeDropdown = null;
        } else if (this.disableAll) {
            this.activeDropdown = null;
          } else {
            this.activeDropdown = dropdown;
            this.activeDropdown.classList.toggle('active');
          }
      }
    }

    clearFilter($event:any,column:string,option:number){
      this.searchFilter($event,column,option)
    }

    /**
  * Permite hacer la busqueda por el valor en una columna especifica.
  */
  searchFilter($event:any,column:string,option:number) {
    this.transmissionDetailStoreService.emptyData();

    const columnSearch = this.mappingService.transmissionColumnFtoB(column);
    let qr: IFilterDetail = this.transmissionDetailStoreService.getRequestData().getValue();
    if (
      Object.keys(this.transmissionDetailStoreService.getPreviousEditData())
        .length != 0
    ) {
      Swal.fire({
        title:
          "<p style='color:black'>Hay cambios sin guardar,esta seguro que desea continuar</p>",
        position: 'top',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        allowOutsideClick: false,
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'customButton customButton-primary',
          cancelButton: 'customButton customButton-secondary',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          qr = this.updateQr(qr, option, columnSearch, $event);
          this.filterConfiguration(column, option, qr);
        }
        else{
          this.activeDropdown.classList.remove('active');
        }
      });
    } else {
      this.loading = true;
      qr = this.updateQr(qr, option, columnSearch, $event);
      this.filterConfiguration(column, option, qr);
    }
  }


  updateQr(
    qr: any,
    option: number,
    columnSearch: string[],
    selectedValue: string
  ) {
    if (option == 0) {
        qr.filterObject[columnSearch[0]] = selectedValue;
    } else {
        qr.filterObject[columnSearch[0]] = '';
    }
    this.activeDropdown.classList.remove('active');
    qr.page = 0;

    return qr;
  }


  filterConfiguration(column: string, option: number, qr: any) {
    this.transmissionDetailStoreService.emptyPreviousEditData();
    this.transmissionDetailStoreService.emptyNewEditData();
    this.filtersApply.delete(column);
    if (option == 0 || this.filtersApply.size != 0) {
      this.transmissionDetailStoreService.setIsFilter(true);
    } else {
      this.transmissionDetailStoreService.setIsFilter(false);
    }
    this.applyFilter(qr, option, column);
    if (option == 0) {
      this.filtersApply.add(column);
    }
  }


    /**
   * @ignore
   */
    applyFilter(qr: any, option: number, column: string) {
      const isFilter = this.transmissionDetailStoreService.getIsFilter();
      if (isFilter) {
        this.transmissionService.listFilterDetail(qr,this.field,this.momento.toString(),this.crmValue!).subscribe({
          next: (data: any) => {
            this.postResponseFilter(data, qr, option, column, 0);
          },
        });
      } else {
        this.transmissionService.listFilterDetail(qr,this.field,this.momento.toString(),this.crmValue!).subscribe({
          next: (data: any) => {
            this.postResponseFilter(data, qr, option, column, 1);
          },
        });
      }
    }


      // type 0 is qr search 1 is normal search
  /**
   * @ignore
   */
  postResponseFilter(
    data: any,
    qr: any,
    option: number,
    column: string,
    type: number
  ) {

    data['page'] = 0;
    this.transmissionDetailStoreService.setCurrentPage(1);
    this.currentPageItems = this.arrayData;
    data.page=this.currentPage-1;
    data.data=data.data.sort((a:any, b:any) => a["ID"] - b["ID"]);
    this.total = data.total;
    this.transmissionDetailStoreService.setTotal(this.total);
    if (this.total != 0) {
      this.columns = Object.keys(data.data[0])
    }
    this.arrayData = data.data;
    this.transmissionDetailStoreService.setData(data);
    this.loading = false;
    this.columnsIcon[column] = option == 0 ? 'filter' : 'arrow2-down';

    if (type == 0) {
      this.transmissionDetailStoreService.setRequestData(qr);
    }
  }


}
