<ol class="sublist">
  <li *ngIf="data.operation == 'new'">
    <p class="bc-opensans-font-style-2-regular">
      Errores en la creación de la {{ data.page }}
    </p>
    <ol class="sublist" type="a">
      <li *ngFor="let element of data.detail.data.validationDetail | keyvalue">
        <p class="bc-opensans-font-style-2-regular">Verifica los campos:</p>
        <ol class="sublist" type="i">
          <li
            *ngFor="
              let field of convertDetail(
                data.detail.data.validationDetail[convertString(element.key)]
              ) | keyvalue
            "
          >
            <p class="bc-opensans-font-style-2-regular">{{ field.key }}</p>
            <ul>
              <li
                class="sublist"
                *ngFor="let error of convertArray(field.value)"
              >
                <p class="bc-opensans-font-style-2-regular">{{ error }}</p>
              </li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li *ngIf="data.operation == 'edit'">
    <p class="bc-opensans-font-style-2-regular">
      Errores en la edición de las {{ data.page }}
    </p>
    <ol class="sublist" type="a">
      <li *ngFor="let element of data.detail.data.validationDetail | keyvalue">
        <p class="bc-opensans-font-style-2-regular">
          Verifica las categorizaciones con ID de tipología
          {{ extractNumber(element.key, 1) }} y el ID de producto
          {{ extractNumber(element.key, 0) }}. En los campos:
        </p>
        <ol class="sublist" type="i">
          <li
            *ngFor="
              let field of convertDetail(
                data.detail.data.validationDetail[convertString(element.key)]
              ) | keyvalue
            "
          >
            <p class="bc-opensans-font-style-2-regular">{{ field.key }}</p>
            <ul>
              <li
                class="sublist"
                *ngFor="let error of convertArray(field.value)"
              >
                <p class="bc-opensans-font-style-2-regular">{{ error }}</p>
              </li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li *ngIf="data.operation == 'delete'">
    <p class="bc-opensans-font-style-2-regular">
      Error en la eliminación de la {{ data.page }}
    </p>
  </li>
  <li *ngIf="data.operation == 'newData'">
      Error en la edición de la {{ data.page }}
      <ol class="sublist" type="a">
        <li *ngFor="let element of data.detail.data.validationDetail | keyvalue">
          <p class="bc-opensans-font-style-2-regular">
            Verifica la transmision . En los campos:
          </p>
          <ol class="sublist" type="i">
            <li
            *ngFor="
              let field of convertDetail(
                data.detail.data.validationDetail[convertString(element.key)]
              ) | keyvalue
            "
          >
            <p class="bc-opensans-font-style-2-regular">{{ field.key }}</p>
            <ul>
              <li
                class="sublist"
                *ngFor="let error of convertArray(field.value)"
              >
                <p class="bc-opensans-font-style-2-regular">{{ error }}</p>
              </li>
            </ul>
          </li>
          </ol>
        </li>
      </ol>
  </li>
</ol>
