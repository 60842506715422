import { KeyValue } from '@angular/common';
import { colors } from 'src/app/shared/constants/colors';


export class BaseTable{

  public itemsPerPage = parseInt("7");
  public currentPage=1;
  public pages:any[] = [];
  public currentPageItems: Array<any> = [];
  public arrayData:any[] = [];
  public total = 0;
  public activeDropdown: any = null
  public disableAll:boolean=false

  constructor(
    private service:any,
    private storeService: any
    ) {

    }


    /**
   * Permite actualizar la página al momento de realizar paginación.
   */
  onChangePageStandard(data: any) {
    this.itemsPerPage = data.PerPage;
    this.currentPage = data.CurrentPage;
    this.reorganizarDataStandard()
  }

  /**
 * Reorganiza la información de una página antes de mostrarsela al usuario.
 */
  reorganizarDataStandard() {
    this.pages = [];
    let tempArray = [];
    tempArray = [...this.arrayData];
    const finallyArray = [...tempArray];
    for (let i = 0; i < tempArray.length; i = i + this.itemsPerPage) {
      const pagina = finallyArray.splice(0, this.itemsPerPage);
      this.pages.push(pagina);
    }
    this.currentPageItems = this.pages[this.currentPage - 1];
    this.total = 0;
    this.pages.forEach(p => {
      this.total += p.length;
    });
  }



    /**
   * Permite eliminar un registro de una tabla.
   */
    deleteRow(row:any){
      row["Editando"] = false;
      if(row["Color"]==colors["green"]){
        this.storeService.cancelPreviousEditData(row)
        this.storeService.cancelNewEditData(row)
      }
      row["Color"]= colors["red"]
      this.storeService.setPageforCancel(this.currentPage-1)
      this.storeService.setColorData(row,this.currentPage-1)
      this.storeService.setDeleteData(row)

    }

    /**
   * Permite cancelar la edicion/eliminación de una fila especifica de una tabla.
   */
    cancelRow(row:any){
      if(row["Color"]==colors["green"]){
        const dictionary=this.storeService.getPreviousEditData()
        const valueOriginal=dictionary[row["ID"]]
        valueOriginal["Color"]=colors["white"]
        this.storeService.setDataCancel(valueOriginal,this.currentPage-1)
      }
      this.storeService.cancelPreviousEditData(row)
      this.storeService.cancelNewEditData(row)
      this.storeService.cancelDeleteData(row)
      row["Color"]= colors["white"]
      row["Editando"]=false
    }

    getRowStyle(row:any,column:string,editColumns:Set<string>){

      if(row["Color"]==colors["green"] && !editColumns.has(column)){
        return { 'background-color': colors["gray"] };
      }
      return {'background-color': row["Color"] }
    }

    keyAscOrder = (
      a: KeyValue<string, string>,
      b: KeyValue<string, string>
    ): number => {
      let result = 0;
      if (parseInt(a.key) > parseInt(b.key)) {
        result = 1;
      } else if (parseInt(b.key) > parseInt(a.key)) {
        result = -1;
      }
      return result;
    };
}
