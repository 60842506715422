<div class="bc-container bc-margin-bottom-4">
  <div class="bc-row bc-margin-top-4">
    <div class="bc-col-12">
      <h1 id="titulo inicio">Inicio</h1>
      <div class="bc-row bc-margin-top-4"></div>
      <h2 class="text-center" id="bienvenida">¡Bienvenidos al módulo administrativo de SmartSupervision!</h2>
    </div>
  </div>
  <div class="bc-container-16">
    <div class="bc-row bc-margin-top-4" id="logos entidades">
      <div class="bc-col-6">
        <div class="bc-row centralize bc-margin-top-1">
          <img src="assets/images/Bancolombia.png" alt="Logo Bancolombia">
        </div>
        <div class="bc-row bc-flex bc-justify-content-center bc-margin-top-1">
          <img class="img2" src="assets/images/Fiduciaria.png" alt="Logo Fiduciaria">
          <img class="img2" src="assets/images/Valores.png" alt="Logo Valores">
        </div>
        <div class="bc-row bc-flex bc-justify-content-center bc-margin-top-1">
          <img class="img2" src="assets/images/BancadeInversion.png" alt="Logo Banca de Inversion">
        </div>
      </div>
      <div class="bc-col-6 bc-flex bc-justify-content-center bc-margin-top-1">
        <img src="assets/images/Nequi.png" alt="Logo Nequi">
      </div>
    </div>
  </div>


  <div class="bc-row bc-margin-top-4 ">
    <div class="bc-col-12 centralize">
      <button class="bc-button-primary bc-button-small bc-col-6" disabled="true" id="boton logs general">
        Descargar log general
      </button>
    </div>
  </div>

</div>
