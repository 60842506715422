import { ChangeDetectorRef, Component,OnDestroy,OnInit,ViewChild } from '@angular/core';
import { TransmissionService } from 'src/app/core/services/transmission/transmission.service';
import { BasePage } from '../../../../shared/components/basePage/BasePage';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';
import { ICreateHomologation,  ISfcValues } from 'src/app/core/models/transmission/ITransmission';
import { MappingService } from 'src/app/core/services/mapping/mapping.service';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { CustomValidator, validateColumn } from 'src/app/shared/validators/CustomValidators';
import { TransmissionDetailStoreService } from 'src/app/core/stores/transmissionDetailStore/transmission-detail-store.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ISaveRequest } from 'src/app/core/models/transmission/ISaveRequest';
import { forkJoin } from 'rxjs';

export const defaultFilterDetail  = {
  page: 0,
  elements: 7,
  keyWord: "",
  filterObject: {
      hmlCod: "",
      crmHRefCod: "",
      crmValue: "",
      crmDetail: "",
      sfcHRefCod: "",
      sfcValue: "",
      sfcDetail: ""
  }
}

@Component({
  selector: 'app-transmission-detail',
  templateUrl: './transmission-detail.component.html',
  styleUrls: ['./transmission-detail.component.scss']
})


export class TransmissionDetailComponent extends BasePage implements OnInit, OnDestroy {


  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;
  @ViewChild('modalNew', { static: true })
  modalNew!: BcModalComponent;

  @ViewChild('modalNew2', { static: true })
  modalNew2!: BcModalComponent;
  @ViewChild('modalConfirm', { static: true }) modalConfirm!: BcModalComponent;
  @ViewChild('modalConfirmNew',{static:true}) modalConfirmNew!: BcModalComponent;
  @ViewChild('modalCancel', { static: true }) modalCancel!: BcModalComponent;
  @ViewChild('modalNewField', { static: true })
  modalNewField!: BcModalComponent;
  public columnDetail: string = "";
  public momentSelected: number = 1;
  public campo!: string;
  public crmType: string| null = "";
  public indiceCrm: number|null =null;
  public moments: string[] = ["1", "2 y 3", "4"];
  public fieldName: string | null = "";
  public newHomologationForm!: FormGroup;
  public crmCodeValues: string[] = [];
  public crmNameValues: string[] = [];
  public sfcCodeValues: string[] = [];
  public sfcNameValues: string[] = [];
  public sfcNameDisable: boolean |null = false;
  public textNewHomologation : string = ''
  public haveNewField = false;
  public filters = [
    { name: "crmCode", values: this.crmCodeValues },
    { name: "crmName", values: this.crmNameValues },
    { name: "sfcCode", values: this.sfcCodeValues },
    { name: "sfcName", values: this.sfcNameValues }
  ];

  public pairsSFCHrefCode : {[key: string]: any} = {};
  public formatType = 'simple';
  public values = [
    {
      label: 'Momento 1 y 4',
      value: 1
    },
    {
      label: 'Momento 2 y 3',
      value: 2
    }
  ];
  public crms = [
    {
      label:'SAP',
      value:'SAP'
    },
    {
      label:'SALESFORCE',
      value:'SALESFORCE'   
    }
  ]
  public saveData: ISaveRequest = {
    previousDataToUpdate: [],
    newDataToUpdate: []
  };
  public newField: any[] = [];
  public newFieldId=new Set()
  public newHomologation = [["Código SFC","Nombre SFC","Código CRM","Nombre CRM"],
  ["Código CRM","Nombre CRM","Código SFC","Nombre SFC"]]
  public newHomologationData : { [key: string]: string } = {}
  public sfcData: ISfcValues[] = [];
  constructor(
    private cd: ChangeDetectorRef,
    public transmissionService: TransmissionService,
    public transmissionDetailStoreService: TransmissionDetailStoreService,
    public mappingService: MappingService,
    public downloadService: DownloadService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public toastr: ToastrService,
  ) {
    super(transmissionService, transmissionDetailStoreService, 'Homologacion');
  }


  ngOnInit() {
    
    this.fieldName = localStorage.getItem('detailFieldName');
    this.crmType = localStorage.getItem('crmType');
    this.indiceCrm = this.encontrarIndice(this.crmType!);
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.campo = params["cod"];
        this.transmissionDetailStoreService.setField(this.campo);
      }
    );


    this.transmissionDetailStoreService
      .getTotal()
      .subscribe((totalElements: number) => {
        this.total = totalElements;
      });

    this.newHomologationForm = this.formBuilder.group({
      sfcCode: ['', Validators.required],
      sfcName: ['', Validators.required],
      crmCode: ['', Validators.required],
      crmName: ['', Validators.required]
    });

  }

  ngOnDestroy(): void {
    this.transmissionDetailStoreService.emptyPreviousEditData()
    this.transmissionDetailStoreService.emptyNewEditData()
    this.transmissionDetailStoreService.emptyData()
    this.transmissionDetailStoreService.setTotal(0)
    this.transmissionDetailStoreService.emptyValuesAutocomplete()
    this.transmissionDetailStoreService.setRequestData({
      page: 0,
      elements: 7,
      keyWord: "",
      filterObject: {
          hmlCod: "",
          crmHRefCod: "",
          crmValue: "",
          crmDetail: "",
          sfcHRefCod: "",
          sfcValue: "",
          sfcDetail: ""
      }
    })
    this.transmissionDetailStoreService.setMomentSelect(1)
    this.transmissionDetailStoreService.setColumnsIcons({
      "Código SFC" : 'arrow2-down',
      "Nombre SFC" : 'arrow2-down',
      "Código CRM" : 'arrow2-down',
      "Nombre CRM" : 'arrow2-down'
    })
  }

  encontrarIndice(valor: string): number {
    return this.crms.findIndex(crm => crm.value === valor);
  }

  onSelect(event: any) {
    if (event != this.momentSelected) {

      if(Object.keys(this.transmissionDetailStoreService.getNewEditData()).length>0){
        Swal.fire({
          title: "<p style='color:black'>Hay cambios sin guardar,esta seguro que desea continuar</p>",
          position: 'top',
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          allowOutsideClick: false,
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: 'customButton customButton-primary',
            cancelButton:'customButton customButton-secondary'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.momentSelected=event
            this.defaultData()
            this.resetData();
          }else{
            this.cd.detectChanges();
          }
        });
      }else{
        this.momentSelected=event
        this.transmissionDetailStoreService.setIsFilter(false);
        this.defaultData()
        this.resetData();
        
        
      }
       
    }

  }


  defaultData(){
    this.transmissionDetailStoreService.setMomentSelect(this.momentSelected);
    this.transmissionDetailStoreService.setRequestData({
      page: 0,
      elements: 7,
      keyWord: "",
      filterObject: {
          hmlCod: "",
          crmHRefCod: "",
          crmValue: "",
          crmDetail: "",
          sfcHRefCod: "",
          sfcValue: "",
          sfcDetail: ""
      }
    })
    this.transmissionDetailStoreService.setColumnsIcons({
      "Código SFC" : 'arrow2-down',
      "Nombre SFC" : 'arrow2-down',
      "Código CRM" : 'arrow2-down',
      "Nombre CRM" : 'arrow2-down'
    })
  }


  postResponseEditDelete(){
    this.alertMessage('Grabado exitosamente', { status: 'ok' });
    this.transmissionDetailStoreService.emptyValuesAutocomplete()
    this.resetData()
  }

  resetData(){
    this.transmissionDetailStoreService.emptyPreviousEditData()
    this.transmissionDetailStoreService.emptyNewEditData()
    this.transmissionDetailStoreService.emptyData()
    this.transmissionDetailStoreService.emptyNewValuesColumns();
    this.transmissionDetailStoreService.setCurrentPage(1);
    this.transmissionDetailStoreService.setTotal(0);
    this.transmissionDetailStoreService.setReload(true);
  }

  /**
 * Descarga el excel con la información de la tabla.
 */
  downloadExcel() {
    this.circleLoading.show();
    this.transmissionService.downloadDetail(this.momentSelected, this.campo,this.crmType!).subscribe({
      next: (data: any) => {
        if (data) {
          this.downloadService.downloadExcelTransmissionDetail("Tabla detalle transmisión", "Hoja 1", data.data, this.momentSelected-1,this.crmType!)
        }
      },
      error: () => { this.circleLoading.hide();
      },
      complete: () => {
        this.circleLoading.hide(); this.isClicked = true;
      }
    })
  }

  openNewHomologation(){
    const observable1 = this.transmissionService.filterSfcValues(this.campo);
    const observable2 = this.transmissionService.filterSfcValues(this.campo, this.momentSelected);

    forkJoin([observable1, observable2]).subscribe(([response1, response2]: [ISfcValues[], ISfcValues[]]) => {
      this.sfcCodeValues = response1.map(obj => obj.value);
      this.filters[parseInt("2")].values = response1.map(obj => obj.value);
      this.filters[parseInt("3")].values = response1.map(obj => obj.detail);
      this.sfcData = response1;
      this.filters[0].values = response2.map(obj => obj.value);
      this.filters[1].values = response2.map(obj => obj.detail);

      this.addValidators();

      response1.forEach(obj => {
        this.pairsSFCHrefCode[obj.value] = obj.hmlRefCod;
      });
    });

    if(this.momentSelected==1){
      this.modalNew.openModal();
    }else{
          this.modalNew2.openModal();
        }
  }

  clickNew() {
    if(Object.keys(this.transmissionDetailStoreService.getNewEditData()).length>0){
      Swal.fire({
       title: "<p style='color:black'>Hay cambios sin guardar,esta seguro que desea continuar</p>",
       position: 'top',
       showCancelButton: true,
       confirmButtonText: "Confirmar",
       allowOutsideClick: false,
       cancelButtonText: "Cancelar",
       customClass: {
         confirmButton: 'customButton customButton-primary',
         cancelButton:'customButton customButton-secondary'
       },
       buttonsStyling: false
     }).then((result) => {
       if(result.isConfirmed){
         this.defaultData()
         this.resetData();
         this.openNewHomologation();
       }

     });
   }else{
    this.openNewHomologation();
   }
  }


  close() {
    if(!(this.momentSelected-1)){
      this.modalNew.shutDown();
    }
    else{
      this.modalNew2.shutDown();
    }
    this.restartValues();
    this.newHomologationForm.reset();
  }


  acceptOrCancel(e: any) {
    if(e){
      if(!(this.momentSelected-1)){
        this.modalNew.shutDown();
      }
      else{
        this.modalNew2.shutDown();
      }
      this.modalConfirmNew.openModal();
      this.selectMessage();
      this.organizeData();
    }
    else{
      this.newHomologationForm.reset();
    }
  }

  selectMessage(){
    const IsIn = this.sfcCodeValues.includes(this.newHomologationForm.value['sfcCode'])
    if(IsIn){
      this.textNewHomologation = '¿Está seguro que desea guardar la información adicional?'
    }
    else{
      this.textNewHomologation = '¿Estas seguro que deseas guardar la información?'
    }

  }

  organizeData() {
    if (this.momentSelected) {
      this.newHomologationData = {"Código SFC" :this.newHomologationForm.value['sfcCode'],
      "Nombre SFC" :this.newHomologationForm.value['sfcName'],
      "Código CRM" :this.newHomologationForm.value['crmCode'],
      "Nombre CRM" :this.newHomologationForm.value['crmName']};


    } else {
      this.newHomologationData = {"Código CRM" :this.newHomologationForm.value['crmCode'],
      "Nombre CRM" :this.newHomologationForm.value['crmName'],
      "Código SFC" :this.newHomologationForm.value['sfcCode'],
      "Nombre SFC" :this.newHomologationForm.value['sfcName']};
    }

  }

  restartValues() {
    for (const value of this.filters) {
      value.values = []
    }
  }

  addValidators(){
    
    this.filters.forEach(field => {
      this.newHomologationForm.get(field.name)?.setValidators([
        Validators.required,
        validateColumn(field.values, 'exist'),
      ]);
    });

    if(this.momentSelected-1){
      this.newHomologationForm.get("sfcCode")?.clearValidators();
      this.newHomologationForm.get("sfcName")?.clearValidators();
      this.newHomologationForm.updateValueAndValidity();
      this.newHomologationForm.get("sfcCode")?.setValidators([Validators.required, CustomValidator.numeric]);
      this.newHomologationForm.get("sfcName")?.setValidators([Validators.required]);
    }
  }



  isValidField(field: string) {
    return (
      this.newHomologationForm.controls[field].errors &&
      !this.newHomologationForm.controls[field].pristine
    );
  }


  /**
* Indica cual es el error presentado en los campos del formulario de nueva Homologacion.
* @param field campo que sera analizado
* @return mensaje que sera muestra en el formulario dependiendo del error o null en caso de no tener errores
*/
  getFieldError(field: string) {
    if (!this.newHomologationForm.controls[field]) {
      return null;
    }
    const errors = this.newHomologationForm.controls[field].errors ?? {};
    for (const key of Object.keys(errors)) {
      switch (key) {
        case 'required':
          return 'El campo es requerido';
        case 'exist':
          return 'Valor existente';
        case 'numeric':
          return 'El campo debe ser numérico';
      }
    }
    return null;
  }

  onAcceptDeleteEdit(event:any){
    if(event){
      this.modalConfirm.shutDown()
      this.createRequestEdit()
      this.circleLoading.show();
      this.transmissionService.editDetail(this.saveData,this.momentSelected,this.campo,this.crmType!).subscribe({
        next: (data: any) => {
          if (data) {
            this.postResponseEditDelete()
          }
        },
        error: () => { this.circleLoading.hide(); },
        complete: () => {
          this.circleLoading.hide(); this.isClicked = true;
        }
      })
    }else{
      this.modalConfirm.shutDown()
    }
  }

  createRequestEdit(){
    const previousRowsEdited=this.transmissionDetailStoreService.getPreviousEditData()
    const newRowsEdited=this.transmissionDetailStoreService.getNewEditData()
    const previousRowsEditedFinal:any=[];
    const newRowsEditedFinal:any=[];
    Object.keys(previousRowsEdited).forEach((item:any)=>{
      previousRowsEditedFinal.push(previousRowsEdited[item])
      newRowsEditedFinal.push(newRowsEdited[item])
    })
    const previousFinal=this.mappingService.transmissionDetailEditFtoB(previousRowsEditedFinal)
    const newFinal=this.mappingService.transmissionDetailEditFtoB(newRowsEditedFinal)
    this.saveData.previousDataToUpdate=previousFinal
    this.saveData.newDataToUpdate=newFinal
  }

  alertMessage(message: string, payload: any) {
    this.toastr.show(message, '', {
      tapToDismiss: false,
      closeButton: true,
      progressBar: true,
      timeOut: 12000,
      extendedTimeOut: 10000,
      toastClass: 'toast-icon',
      payload: payload,
      positionClass: 'toast-top-center',
    });
  }



  showSelectedValue(){
    const value = this.newHomologationForm.value["sfcCode"];
    const obj = this.sfcData.find(item => item.value === value)
    if(obj){
      this.newHomologationForm.patchValue({
        sfcName: obj.detail
      });
      this.sfcNameDisable = true;
    }
    else{
      this.newHomologationForm.patchValue({
        sfcName: ""
      });
      this.sfcNameDisable = null;
    }
  }

  /**
   * @ignore
   */
  canDeactivate() {
    const elementsToEdit = this.transmissionDetailStoreService.getNewEditData();
    if (
      Object.keys(elementsToEdit).length == 0
    ) {
      return true;
    }
    return false;
  }

  openModalNewField() {
    this.haveNewField = false
    const keysEditData = Object.keys(
      this.transmissionDetailStoreService.getNewEditData()
    );
    const editData = this.transmissionDetailStoreService.getNewEditData();
    this.newField=[]
    this.newFieldId=new Set()
    keysEditData.forEach((ele: string) => {
      const valueEdit = editData[parseInt(ele)];
      if(valueEdit["Codigo hrefSFC"]==null){
        this.haveNewField = true
        const valueEditNew={
          "Código SFC":valueEdit["Código SFC"],
          "Nombre SFC":valueEdit["Nombre SFC"],
        }
        if(!this.newFieldId.has(valueEdit["Código SFC"])){
          this.newFieldId.add(valueEdit["Código SFC"])
          this.newField.push(valueEditNew)
        }
      }
    })
    if (this.haveNewField) {
      this.modalNewField.openModal();
    } else {
      this.openModalSave(this.modalConfirm, 1);
    }

  }

  openModalOperations(event: any) {
    this.modalNewField.shutDown();
    if (event) {
      this.openModalSave(this.modalConfirm, 1);
    }
  }

  cancelDeleteorEdit(event:any){
    if(event==1){
      this.resetData()
      this.modalCancel.shutDown()
    }else{
      this.modalCancel.shutDown()
    }
  }

  confirmNewHomologation(e : any){
    if(e){
      this.modalConfirmNew.shutDown()
      this.newHomologationForm.reset();
      this.createNewHomologation();
    }
    else{
      this.modalConfirmNew.shutDown()
      if(!(this.momentSelected-1)){
        this.modalNew.openModal();
      }
      else{
        this.modalNew2.openModal();
      }
    }
  };

  createNewHomologation(){
    let object : ICreateHomologation = this.mappingService.newHomologationTransmissionFtoB(this.newHomologationData)
    object = this.addHrefCode(object);
    this.circleLoading.show();
    this.transmissionService
      .createHomologation(object, this.momentSelected, this.campo, this.crmType! )
      .subscribe({
        next: () => {
          this.alertMessage('Homologación creada exitosamente', {
            status: 'ok',
          });
        },
        error: () => { this.circleLoading.hide();
        },
        complete: () => {
          this.circleLoading.hide();
          this.transmissionDetailStoreService.setReload(true);
        }
      });
  }

  addHrefCode(object:ICreateHomologation){
    if(this.pairsSFCHrefCode[object["sfcValue"]]){
      object["sfcHRefCod"] = this.pairsSFCHrefCode[object["sfcValue"]].toString()
    }
    return object;
  }

}
