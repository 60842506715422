<bc-header [fixed]="false" [menuInteraction]="false" 
headerType="bc-header-wrapper-black">

  <div class="bc-container-fluid" id="header inicio">
    <div class="bc-row">
      <div class="bc-col-2 bc-align-self-center">
        <bc-icon aria-hidden="true" aria-label="Bancolombia" role="img" [size]="size" class="bc-icon bc-lg"
          id="logo bancolombia login">
          bancolombia-horizontal
        </bc-icon>
      </div>
      <div class="bc-col-8 bc-align-self-center">
        <div class="bc-row bc-justify-content-center">
          <h4><strong>Módulo Administrativo de SmartSupervision</strong></h4>
        </div>
      </div>
      <div class="bc-col-2 bc-align-self-center">
        <div class="bc-row ">
          <button bc-button sizeButton="small" (click)="login()" id="boton_login">INICIAR SESIÓN</button>
        </div>

      </div>
    </div>
  </div>

</bc-header>
<iframe title="Tablero SmartSupervision" [style.width.px]="iframeWidth" [style.height.px]="iframeHeight" [src]="powerBi"
  allowFullScreen="true" id="Tablero de powerBi"></iframe>