import { IProducts } from "src/app/modules/categorization/pages/categorization/categorization.component";

export const defaultValuesForm = {
    identity: '',
    entity: '',
    idproduct: '',
    product: '',
    productName: '',
    idtypology: '',
    typologyName: '',
    productCode: '',
    productDetail: '',
    motiveCode: '',
    motiveDetail: '',
    channelCode: '',
    channelDetail: '',
    digitalProductCode: '',
    digitalProduct: '',
};

export const newProductDefault: IProducts[] = [{ label: 'Crear nuevo', value: null, ref: '' }];

export  const defaultNewTypology = {
    idOrEntity: 0,
    idEntity: '',
    entityName: '',
    idOrProduct: null,
    idProduct: '',
    product: '',
    idTypology: '',
    typology: '',
    sfcProduct: {
      sfcId: '',
      sfcDetail: '',
      hmlRefId: null,
    },
    sfcMotive: {
      sfcId: '',
      sfcDetail: '',
      hmlRefId: null,
    },
    sfcChannel: {
      sfcId: 'NoDataSSv',
      sfcDetail: 'null vacio',
      hmlRefId: 49,
    },
    sfcDigitalProduct: {
      sfcId: '',
      sfcDetail: '',
      hmlRefId: null,
    },
  };