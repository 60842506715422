import { Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { CategorizationStoreService } from 'src/app/core/stores/categorizationStore/categorization-store.service'
import Swal from 'sweetalert2'
import { TransmissionDetailStoreService } from '../stores/transmissionDetailStore/transmission-detail-store.service';
import {  ActivatedRouteSnapshot } from '@angular/router';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}


@Injectable()
export class PendingChangesGuard  {

  page: string="";

  constructor(
    private categorizationStoreService: CategorizationStoreService,
    private transmissionDetailStoreService: TransmissionDetailStoreService
  ) {
  }

  /**
   * Antes de salirse de la página valida si hay ediciones/eliminaciones pendientes por guardar.
   */
  canDeactivate(component: CanComponentDeactivate,currentRoute: ActivatedRouteSnapshot): Promise<boolean> {
    this.page=currentRoute.data['page'];
    if(!component.canDeactivate()){
      return this.modalConfirm()
    }else{
      return Promise.resolve(true)
    }
  }

  modalConfirm(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      Swal.fire({
        title: "<p style='color:black'>Hay cambios sin guardar,esta seguro que desea continuar</p>",
        position: 'top',
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        allowOutsideClick: false,
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: 'customButton customButton-primary',
          cancelButton:'customButton customButton-secondary'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.isConfirmed) {
          if(this.page === 'transmission'){
            this.transmissionDetailStoreService.emptyPreviousEditData();
          }else{
            this.categorizationStoreService.emptyDeleteData();
            this.categorizationStoreService.emptyPreviousEditData();
          }

        }
        resolve(result.isConfirmed);
      });
    });
  }


}
