import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomToastComponent } from './shared/components/custom-toast/custom-toast.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { BcHeaderModule } from '@bancolombia/design-system-web/bc-header';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './modules/layout/layout.module';
import { HeadersService } from './core/interceptors/headers.service';
import { ErrorNotifierService } from './core/interceptors/errors.service';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { DatePipe } from '@angular/common';

export function loggerCallback(message:string) {
  console.log(message);
}

export function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureConfig.clientId,
      redirectUri: environment.azureConfig.redirectUri,
      authority: `https://login.microsoftonline.com/${environment.azureConfig.tenantId}`,
      postLogoutRedirectUri: `${environment.host}/authentication/login`,
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  });
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);
  if(environment.enableMsal==="true"){
    protectedResourceMap.set(environment.apiProtected, [environment.azureConfig.clientId+"/.default"]);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: 'authentication/login',
  };
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BcIconModule.forRoot({
      path: 'https://library-sdb.apps.bancolombia.com/bds/6.17.5',
    }),
    BcAccordionModule,
    BcHeaderModule,
    BrowserAnimationsModule,

    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
    }),
    LayoutModule,
    SharedModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory
    },
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    { provide: HTTP_INTERCEPTORS, useClass: HeadersService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorNotifierService, multi: true },
    DatePipe
  ],
})
export class AppModule {}
