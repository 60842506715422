<div class="bc-container">
  <div class="bc-row">
    <bc-table-container
      class="bc-col-12"
      [dataTable]="currentPageItems"
      componentId="containerTable"
      *ngIf="!loading && total > 0"
    >

      <bc-table-header title="">
        <div class="right-align">
          <bc-paginator
          type="full"
          [totalRegisters]="total!.toString()"
          [perPage]="itemsPerPage!.toString() ? itemsPerPage!.toString() : '7'"
          (onChangePage)="onChangePage($event)"
          perMultiple="7"
        >
        </bc-paginator>
        </div>
      </bc-table-header>
      <br>
      <bc-table-content>
          <div class="table-container">
              <table bc-table  aria-describedby="Lista de Datos" id="table">
                <thead>
                  <tr>
                    <th>Operaciones</th>
                    <th
                      *ngFor="let column of columns.slice(1, 5)"
                      bc-cell
                      >
                      <div class="center-text">
                        <div
                        #drop
                        class="menu"
                        (click)="toggleDropdown($event, drop, column)"
                    >
                      {{ column }}
                      <span
                        class="bc-icon"
                        [id]="column"
                        aria-hidden="true"
                        aria-label="icono"
                      >
                        {{columnsIcon[column]}}</span
                      >
                      <app-dropdown
                      [columnOptionsFilter]="columnOptionsFilter[column]"
                      (search)="searchFilter($event,column,0)"
                      (clear)="clearFilter($event,column,1)"></app-dropdown>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let row of currentPageItems; let i = index">
                    <td [ngStyle]="getRowStyle(row, 'exist',editColumns)">
                      <div class="bc-row">
                        <div class="bc-col-3">
                          <bc-icon-button
                            (click)="editRow(row)"
                            type="ghost"
                            size="medium"
                            customIcon="edit"
                          >
                          </bc-icon-button>
                        </div>
                        <div class="bc-col-3">
                          <bc-icon-button
                            (click)="cancelRowTransmission(row)"
                            type="ghost"
                            size="medium"
                            customIcon="error"
                          >
                          </bc-icon-button>
                        </div>
                      </div>
                    </td>
                    <td
                      *ngFor="let column of columns.slice(1, 5)"
                      bc-cell
                      [ngStyle]="getRowStyle(row, column,editColumns)"
                    >
                      <input
                        [id]="column+row['ID']"
                        class="bc-margin-top-1 center-text no-border"
                        [ngStyle]="getRowStyle(row, column,editColumns)"
                        [value]="row[column]"
                        [readonly]="isReadOnly(row,column)"
                        (input)="updateValue($event,row,column)"
                        [attr.list]="column | lowercase"
                      />
                        <datalist  [id]="column | lowercase">
                          <option
                            *ngFor="
                              let option of valuesAutocomplete[column] | keyvalue : keyAscOrder
                            "
                            [value]="option.key"
                          ></option>
                        </datalist>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
      </bc-table-content>
    </bc-table-container>
    <div class="loader centralize" *ngIf="loading">
      <bc-loader speedLoader="2" sizeLoader="xl"></bc-loader>
    </div>
    <div class="loader centralize" *ngIf="!loading && total == 0">
      <br>
      <app-no-data></app-no-data>
    </div>
  </div>
</div>
<app-circle-loading></app-circle-loading>