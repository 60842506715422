import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { IFilterRequest } from 'src/app/core/models/categorization/IFilterRequest';
import { ISaveRequest } from 'src/app/core/models/categorization/ISaveRequest';
import { MappingService } from 'src/app/core/services/mapping/mapping.service';
import { ISaveResponse } from '../../models/categorization/ISaveResponse';
import { Observable } from 'rxjs';
import { INewTypology } from '../../models/categorization/INewTypology';
import { CategorizationStoreService } from '../../stores/categorizationStore/categorization-store.service';
import { IDownloadExcelData } from '../../models/categorization/ICategorizationTable';



@Injectable({
  providedIn: 'root'
})
export class CategorizationService {

  public categorizationUrl = environment.api+"categorization/"
  public listOptions = ['product', 'channel', 'motive', 'digital'];




  constructor(
    private http: HttpClient,
    private mappingService: MappingService,
    private categorizationStoreService: CategorizationStoreService
  ) { }

  /**
   * Lista todas las homologaciones.
   */
  list(crmType:string) {
    return this.http.get<IDownloadExcelData[]>(this.categorizationUrl + "download?crmType=" + crmType);
  }

   /**
   * Lista las homologaciones dada una página y la cantidad de elementos solicitados.
   */
  listData(page: number, elements: number, crmType:string) {
    return this.http.get<any>(this.categorizationUrl + "table?page=" + page + "&elements=" + elements + "&crmType=" + crmType);
  }

  /**
   * Lista las homologaciones dado un filtro.
   */
  listFilter(data: IFilterRequest, crmType:string) {
    return this.http.post(this.categorizationUrl + "filter?crmType=" + crmType , data)
  }

    /**
   * Lista de las entidades.
   */
  entitiesList(crmType:string) {
    return this.http.get<any[]>(this.categorizationUrl + "new/getEntities?crmType=" + crmType);
  }

    /**
   * Lista de productos dependiendo del id de la entidad.
   */
  productsList(id: string, crmType: string) {
    return this.http.get<any[]>(this.categorizationUrl + "new/getProducts?entityId=" + id + "&crmType=" + crmType);
  }

    /**
   * Lista las tipologias dependiendo el id del producto.
   */
  typologiesList(id: string) {
    return this.http.get<any[]>(this.categorizationUrl + "new/getTypologies?productId=" + id);
  }

  /**
   * Lista todas los valores unicos dada una columna (Incluye los no utilizados).
   */
  sfcFields(field:string){
    return this.http.get<any[]>(this.categorizationUrl+"filter/findall/sfc?name="+field);
  }

  /**
   * Crea una nueva tipología
   */
  createTypology(newTypology: INewTypology, crmType:string): Observable<ISaveResponse> {
    return this.http.post(this.categorizationUrl + "new?crmType=" + crmType, newTypology) as Observable<ISaveResponse>
  }

  /**
   * Lista los valores unicos dada una columna (Incluye solo los usados).
   */
  listFilterEspecific(column: string , crmType: string) {
    const columnBack = this.mappingService.categorizationFilterFtoB(column)
    return this.http.get<any[]>(this.categorizationUrl + "filter/words?columnName=" + columnBack + "&crmType=" + crmType)
  }

  /**
   * Edita o elimina una nueva homologación
   */
  save(data: ISaveRequest, crmType:string): Observable<ISaveResponse> {
    return this.http.post(this.categorizationUrl + "save?crmType=" + crmType, data) as Observable<ISaveResponse>
  }

  /**
   * @ignore
   */
  removeSymbolFilter(){
    const columnsFilter = this.categorizationStoreService
        .getColumns()
        .getValue();
    columnsFilter.forEach((column: string) => {
        const componentem = document.getElementById(column);
        if (componentem) {
          componentem.innerHTML = 'arrow2-down';
        }
    });
  }


  /**
   * Obtiene los valores para autocompletar la tabla.
   */
  getAutocompleteTable() {
    let allvaluelist={}
    this.listOptions.forEach((el: string) => {
      this.sfcFields(el).subscribe({
        next: (data: any) => {
          const result = this.mappingService.categorizationUniqueBtoF(data, el);
          allvaluelist = {
            ...allvaluelist,
            [Object.keys(result)[0]]: Object.values(result)[0],
          };
          this.categorizationStoreService.setValuesAutocomplete(allvaluelist);
        },
      });
    });

  }


}