import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { RouterModule} from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BcIconModule} from '@bancolombia/design-system-web/bc-icon';
import { BcTableModule} from '@bancolombia/design-system-web/bc-table';
import { BcPopOverModule} from '@bancolombia/design-system-web/bc-pop-over';
import { BcModalModule} from '@bancolombia/design-system-web/bc-modal';
import { BcInputDateModule} from '@bancolombia/design-system-web/bc-input-date';
import { BcBreadcrumbModule} from '@bancolombia/design-system-web/bc-breadcrumb';
import { BcInputDateRangeModule} from '@bancolombia/design-system-web/bc-input-date-range';
import { BcOffCanvasModule} from '@bancolombia/design-system-web/bc-off-canvas';
import { BcInputModule} from '@bancolombia/design-system-web/bc-input';
import { BcInputSelectModule} from '@bancolombia/design-system-web/bc-input-select';
import { BcAccordionModule} from '@bancolombia/design-system-web/bc-accordion';
import { BcPaginatorModule} from '@bancolombia/design-system-web/bc-paginator';
import { BcCheckboxModule} from '@bancolombia/design-system-web/bc-checkbox';
import {BcFileDownloadModule} from '@bancolombia/design-system-web/bc-file-download'
import {BcButtonModule} from '@bancolombia/design-system-web/bc-button';
import {BcIconButtonModule} from '@bancolombia/design-system-web/bc-icon-button';
import { BcHeaderModule} from '@bancolombia/design-system-web/bc-header';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import { BcSwitchModule } from '@bancolombia/design-system-web/bc-switch';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcCardSelectModule } from '@bancolombia/design-system-web/bc-card-select';
import { BcLoaderModule } from '@bancolombia/design-system-web/bc-loader';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { BcIllustrationModule } from '@bancolombia/design-system-web/bc-illustration';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { DetailErrorComponent } from './components/detail-error/detail-error.component';
import { BcFooterModule } from '@bancolombia/design-system-web/bc-footer';
import { CircleLoadingComponent } from './components/circle-loading/circle-loading.component';
import { BcCircleLoadingModule } from '@bancolombia/design-system-web/bc-circle-loading';
import { TotalBoxComponent } from './components/total-box/total-box.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NoDataComponent } from './components/no-data/no-data.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BcTableModule,
    BcPaginatorModule,
    BcModalModule,
    BcInputModule,
    BcOffCanvasModule,
    BcInputSelectModule,
    BcInputDateModule,
    BcAccordionModule,
    BcCheckboxModule,
    BcFileDownloadModule,
    ReactiveFormsModule,
    BcButtonModule,
    BcIconButtonModule,
    BcHeaderModule,
    BcTooltipModule,
    MatAutocompleteModule,
    BcSwitchModule,
    BcRadioModule,
    BcCardSelectModule,
    BcLoaderModule,
    BcAlertModule,
    BcFooterModule,
    BcCircleLoadingModule,
    BcBreadcrumbModule,
    FormsModule,
    BcIllustrationModule.forRoot({
      path: "https://library-sdb.apps.bancolombia.com/bds/6.15.2"
    }),
    BcIconModule.forRoot({
      path: "https://library-sdb.apps.bancolombia.com/bds/9.0.2"
  }),
  ],

  exports:[
    CommonModule,
    RouterModule,
    FormsModule,
    BcOffCanvasModule,
    BcAccordionModule,
    BcInputModule,
    BcInputSelectModule,
    BcInputDateModule,
    BcInputDateRangeModule,
    BcBreadcrumbModule,
    BcTableModule,
    BcPaginatorModule,
    BcIconModule,
    BcPopOverModule,
    BcModalModule,
    BcCheckboxModule,
    BcFileDownloadModule,
    BcButtonModule,
    BcIconButtonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    BcHeaderModule,
    BcTooltipModule,
    BcSwitchModule,
    BcRadioModule,
    BcCardSelectModule,
    BcLoaderModule,
    BcAlertModule,
    BcIllustrationModule,
    BcFooterModule,
    CircleLoadingComponent,
    TotalBoxComponent,
    DropdownComponent,
    NoDataComponent,
  ],

  declarations: [
      CustomToastComponent,
      DetailErrorComponent,
      CircleLoadingComponent,
      TotalBoxComponent,
      DropdownComponent,
      NoDataComponent
  ]
})

export class SharedModule { }
