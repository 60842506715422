import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TransmissionRoutingModule } from './transmission-routing.module';
import { TransmissionComponent } from './pages/transmission/transmission.component';
import { TransmissionDetailComponent } from './pages/transmission-detail/transmission-detail.component';
import { TransmissionDetailTableComponent } from './components/transmission-detail-table/transmission-detail-table.component';
import { TransmissionTableComponent } from './components/transmission-table/transmission-table.component';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';



@NgModule({
  declarations: [
    TransmissionComponent,
    TransmissionDetailComponent,
    TransmissionDetailTableComponent,
    TransmissionTableComponent
  ],
  imports: [
    CommonModule,
    TransmissionRoutingModule,
    SharedModule
  ],
  providers:[
    PendingChangesGuard
  ]
})
export class TransmissionModule { }
