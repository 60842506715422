import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TransmissionDTO } from '../../models/transmission/TransmissionDTO';

@Injectable({
  providedIn: 'root'
})
export class TransmissionStoreService {

  private data=new BehaviorSubject<any[]>([]);
  private deleteData=new BehaviorSubject<Set<any>>(new Set())
  private editData=new BehaviorSubject<Set<any>>(new Set())


  private totalElements=new BehaviorSubject<number>(0);
  private currentPage=new BehaviorSubject<number>(1);
  private loading=new BehaviorSubject<boolean>(true);
  private isSearch=new BehaviorSubject<boolean>(false);

  private formData=new BehaviorSubject<TransmissionDTO>({
    page: 0,
    elements: 7,
    crm:"SAP",
    keyWord: "",
  })

  emptyData(){
    this.data.next([])
  }

  getData(){
    return this.data
  }

  setAllData(value:any){
    this.data.next(value.data)
  }

  getIsSearch(){
    return this.isSearch
  }

  setIsSearch(value:boolean){
    this.isSearch.next(value)
  }

  getCurrentPage(){
    return this.currentPage
  }

  setCurrentPage(value:number){
    this.currentPage.next(value)
  }

  getFormData(){
    return this.formData
  }

  setFormData(value:any){
    this.formData.next(value)
  }

  setDefaultFormData(){
    this.formData.next({
      page: 0,
      elements: 7,
      crm:"SAP",
      keyWord: "",
    })
  }

  //Methods for loading

  getLoading(){
    return this.loading
  }

  setLoading(value:boolean){
    this.loading.next(value)
  }

  // Methods for edit data
getEditData(){
  return this.editData.getValue()
}

setEditData(newValue:any){
  this.editData.value.add(newValue)
  this.editData.next(this.editData.value)
}

setAllEditData(value:Set<any>){
  this.editData=new BehaviorSubject<Set<any>>(value)
  this.editData.next(this.editData.value)
}

cancelEditData(value:any){
  const copyData=this.editData.getValue();
  copyData.delete(value)
  this.setAllEditData(copyData)
}



// Methods for delete data
getDeleteData(){
  return this.deleteData.getValue()
}

setDeleteData(newValue:any){
  this.deleteData.value.add(newValue)
  this.deleteData.next(this.deleteData.value)
}

setAllDeleteData(value:Set<any>){
  this.deleteData=new BehaviorSubject<Set<any>>(value)
  this.deleteData.next(this.deleteData.value)
}

cancelDeleteData(value:any){
  const copyData=this.deleteData.getValue();
  copyData.delete(value)
  this.setAllDeleteData(copyData)
}


//Methods for total
getTotal(){
  return this.totalElements
}

setTotal(value:number){
  this.totalElements.next(value)
}

}
