import { AbstractControl, ValidatorFn } from "@angular/forms";

export class CustomValidator {

  /**
   * Valida si el campo del formulario corresponde a un numero
   * @param control campo del formulario que es analizado
   * @returns null, en caso de que el campo contenga solo valores numericos, null o un string vacio "".
   * { 'numeric': true } en caso de que el campo contenga caracteres diferentes a numeros
   */
  static numeric(control: AbstractControl) {
    const val = control.value;

    if (val === null || val === '') {
      return null;
    }

    if (!/^\d+(?:\.\d+)?$/.test(val)) {
      return { 'numeric': true };
    }

    return null;
  }

}

/**
 * Valida si el valor de una columna ya existe en una lista de valores.
 */
export function validateColumn(array: string[], column: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (array.includes(value)) {
      return { [column]: true };
    }
    return null;
  };
}


