<div class="bc-content-body">
  <div class="bc-container bc-my-3">
    <div class="bc-row bc-margin-top-2">
      <div class="bc-card">
        <div class="bc-card-body">
          <div class="bc-row bc-justify-content-center">
            <h1>Homologación categorización</h1>
          </div>
          <br />
          <div class="bc-row">
            <div class="bc-col-2">
              <button
                (click)="openModalNewField()"
                bc-button
                typeButton="terciary"
              >
                <div class="bc-row">
                  <div class="bc-offset-1">
                    <bc-icon size="3xl">save</bc-icon>
                  </div>
                  Grabar
                </div>
              </button>
            </div>
            <div class="bc-col-2">
              <button (click)="clickNew()" bc-button typeButton="terciary">
                <div class="bc-row">
                  <div class="bc-offset-1">
                    <bc-icon size="3xl">checks</bc-icon>
                  </div>
                  Nuevo
                </div>
              </button>
            </div>
            <div class="bc-col-4">
              <div class="bc-col-7">
                <button
                  (click)="openModalCancel(modalCancel)"
                  bc-button
                  typeButton="terciary"
                >
                  <div class="bc-row">
                    <div class="bc-offset-1">
                      <bc-icon size="3xl" class="bc-icon-left">error</bc-icon>
                    </div>
                    Cancelar
                  </div>
                </button>
              </div>
            </div>
          </div>
          <br />
          <div class="bc-page-header">
            <div class="bc-container bc-page-header-content">
              <div class="bc-page-header-content-left bc-col-2 bc-col-xs-12">
                <bc-form-field>
                  <input
                    (keyup.enter)="generalSearch(0)"
                    bc-input
                    [(ngModel)]="filterText"
                    id="search"
                    type="text"
                    class="bc-input"
                    aria-label="Buscar"
                  />
                  <label for="search"><b>Palabra Clave</b></label>
                </bc-form-field>
              </div>
              <div class="bc-page-header-content-left bc-col-2 bc-col-xs-12" >
                <br>
                <bc-input-select
                  componentId="bc-select2"
                  [values]="crms"
                  [defaultIndex]=0
                  label="CRM"
                  (valueOutput)="switchCrm($event)"
                  id="CrmSelectTransmission"
                >
                </bc-input-select>
              </div>
              <div class="bc-col-2">
                <button bc-button sizeButton="small" (click)="generalSearch(0)">
                  Buscar
                </button>
              </div>
              <div class="bc-col-2">
                <button
                  bc-button
                  sizeButton="small"
                  typeButton="secondary"
                  (click)="generalSearch(1)"
                >
                  Limpiar
                </button>
              </div>
              <div class="bc-col-1"></div>
              <div class="bc-col-2">
                <app-total-box [total]="total"></app-total-box>
              </div>

              <div class="bc-page-header-content-right">
                <div class="bc-page-header-content-right-icons">
                  <input
                    (click)="downloadExcel()"
                    [ngClass]="{ 'dark-image': isClicked }"
                    type="image"
                    src="assets/icons/excel_48_color.svg"
                    alt="excel"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />

          <app-categorization-table [crmType]="crm"></app-categorization-table>

          <bc-modal
            customWidth="small"
            typeIcon="warning"
            textBtn="Aceptar"
            textBtnLeft="Cancelar"
            customWidth="large"
            [outerCloseTap]="false"
            (stateClick)="onAcceptDeleteEdit($event)"
            [isCancellable]="true"
            #modalConfirm
          >
            <div modalContent>
              <div>
                <h3 class="text-center">Espera</h3>
              </div>
              <div>
                <h4 class="text-center">
                  {{
                    deleteorEdit
                      ? "Si eliminas estos registros, no podrás recuperarlos nuevamente."
                      : "Estos registros van a ser modificados"
                  }}
                </h4>
              </div>
              <br />
              <div class="bc-container">
                <div class="bc-row">
                  <div
                    class="bc-col-12"
                    componentId="containerTable"
                    *ngIf="dataOperation.length > 0"
                  >
                    <bc-table-content>
                      <div class="table-container-modal">
                        <table aria-describedby="Lista de Datos" id="table">
                          <thead>
                            <tr>
                              <th
                                *ngFor="
                                  let column of columns.slice(
                                    0,
                                    columns.length - 11
                                  )
                                "
                                bc-cell
                              >
                                {{ column }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of dataOperation">
                              <td
                                *ngFor="
                                  let column of columns.slice(
                                    0,
                                    columns.length - 11
                                  )
                                "
                                bc-cell
                              >
                                {{ row[column] }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </bc-table-content>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <h5 class="text-center">
                  {{
                    deleteorEdit
                      ? "¿Estás seguro que deseas eliminar la información seleccionada?"
                      : "¿Estás seguro que deseas grabar la informacion modificada?"
                  }}
                </h5>
              </div>
            </div>
          </bc-modal>
          <bc-modal
            customWidth="small"
            typeIcon="warning"
            textBtn="Aceptar"
            textBtnLeft="Cancelar"
            customWidth="large"
            [outerCloseTap]="false"
            [hideCloseButton]="true"
            (stateClick)="onDecisionHomologation($event)"
            [isCancellable]="true"
            #modalConfirmHomologation
          >
            <div modalContent>
              <div>
                <h3 class="text-center">Espera</h3>
              </div>
              <div>
                <h4 class="text-center">
                  Esta es la nueva categorización que vas a incluir en el envío
                  a SmartSupervision
                </h4>
              </div>
              <br />
              <div class="bc-container">
                <div class="bc-row">
                  <div
                    class="bc-col-12"
                    *ngIf="tableValue.length > 0"
                    componentId="containerTable"
                  >
                    <bc-table-content>
                      <div class="table-container-modal">
                        <table aria-describedby="Lista de Datos" id="table">
                          <thead>
                            <tr>
                              <th *ngFor="let column of columnsFilter" bc-cell>
                                {{ column }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let columns of tableValue">
                              <td *ngFor="let column of columns" bc-cell>
                                {{ column }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </bc-table-content>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <h5 class="text-center">
                  ¿Estás seguro que deseas homologarla e incluirla?
                </h5>
              </div>
            </div>
          </bc-modal>

          <bc-modal
            [title]="title"
            textBtn="Confirmar"
            textBtnLeft="Cancelar"
            [isCancellable]="true"
            [outerCloseTap]="false"
            (stateClick)="cancelDeleteorEdit($event)"
            #modalCancel
          >
            <div modalContent>
              <div>
                <h3 class="text-center">Espera</h3>
              </div>
              <div>
                <h4 class="text-center">
                  ¿Estás seguro que deseas cancelar dichas operaciones?
                </h4>
              </div>
            </div>
          </bc-modal>

          <bc-modal
            [title]="title"
            textBtn="Confirmar"
            textBtnLeft="Cancelar"
            [isCancellable]="true"
            typeIcon="warning"
            [hideCloseButton]="true"
            [outerCloseTap]="false"
            (stateClick)="openModalOperations($event)"
            #modalNewField
          >
            <div modalContent>
              <h3 class="text-center">Espera</h3>
              <div *ngFor="let ele of dataNewField | keyvalue">
                <div *ngIf="setToArray(dataNewField[ele.key]).length != 0">
                  <div>
                    <h4 class="text-center">
                      Creaste un nuevo código para el campo {{ ele.key }} en la
                      SFC
                    </h4>
                  </div>
                  <div class="bc-container">
                    <div class="bc-row">
                      <div class="bc-col-2"></div>
                      <div class="bc-col-8" componentId="tableId">
                        <bc-table-content>
                          <table
                            bc-table
                            aria-describedby="Lista de Datos"
                            id="table"
                          >
                            <thead>
                              <tr>
                                <th
                                  *ngFor="
                                    let column of setToArray(
                                      dataNewField[ele.key]
                                    )[0] | keyvalue
                                  "
                                  bc-cell
                                >
                                  {{ column.key }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let row of setToArray(dataNewField[ele.key]);
                                  let i = index
                                "
                              >
                                <td
                                  *ngFor="
                                    let element of setToArray(
                                      dataNewField[ele.key]
                                    )[i] | keyvalue
                                  "
                                  bc-cell
                                >
                                  {{ element.value }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </bc-table-content>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <div>
                <h5 class="text-center">
                  ¿Estás seguro que deseas grabar la información creada?
                </h5>
              </div>
            </div>
          </bc-modal>

          <bc-modal
            title="Nueva homologación"
            [isCancellable]="true"
            [outerCloseTap]="false"
            #modalNew
            customwidth="large"
            (stateClick)="presave($event)"
            (closeModalEvent)="close()"
            textBtn="Guardar"
            [primaryButtonDisabled]="!newHomologationForm.valid"
            textBtnLeft="Cancelar"
          >
            <div modalContent>
              <div>
                <form [formGroup]="newHomologationForm" autocomplete="off">
                  <div class="bc-row">
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field>
                        <bc-input-select
                          componentId="entidad"
                          name="Entidad"
                          formatType="simple"
                          [values]="entities"
                          label="Entidad *"
                          (valueOutput)="selectedEntity($event)"
                          formControlName="entity"
                        >
                        </bc-input-select>
                      </bc-form-field>
                    </div>
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field
                        [attr.disabled]="isEmpty('entity') && disabledProduct ? null : true"
                      >
                        <bc-input-select
                          componentId="product"
                          name="product"
                          [filter]="true"
                          formatType="simple"
                          [values]="products"
                          label="Producto *"
                          (valueOutput)="showProductOptions($event)"
                          formControlName="product"
                        >
                        </bc-input-select>
                      </bc-form-field>
                    </div>
                  </div>
                  <div *ngIf="newProduct" class="bc-row">
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field>
                        <input
                          bc-input
                          type="text"
                          id="idproduct"
                          class="bc-input"
                          aria-label="columna1"
                          maxlength="50"
                          formControlName="idproduct"
                        />
                        <label for="idproduct">ID Producto *</label>
                        <span *ngIf="isValidField('idproduct')">{{
                          getFieldError("idproduct")
                        }}</span>
                      </bc-form-field>
                    </div>
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field>
                        <input
                          bc-input
                          type="text"
                          id="productName"
                          class="bc-input"
                          aria-label="columna2"
                          maxlength="100"
                          formControlName="productName"
                        />
                        <label for="productName">Producto *</label>
                        <span *ngIf="isValidField('productName')">{{
                          getFieldError("productName")
                        }}</span>
                      </bc-form-field>
                    </div>
                  </div>
                  <div class="bc-row">
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field
                        [attr.disabled]="productValidity() ? null : 'true'"
                      >
                        <input
                          bc-input
                          type="text"
                          id="idtypology"
                          class="bc-input"
                          aria-label="columna1"
                          maxlength="50"
                          formControlName="idtypology"
                        />
                        <label for="idtypology">ID Tipología *</label>
                        <span *ngIf="isValidField('idtypology')">{{
                          getFieldError("idtypology")
                        }}</span>
                      </bc-form-field>
                    </div>
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field
                        [attr.disabled]="productValidity() ? null : 'true'"
                      >
                        <input
                          bc-input
                          type="text"
                          id="typologyName"
                          class="bc-input"
                          aria-label="columna2"
                          maxlength="100"
                          formControlName="typologyName"
                        />
                        <label for="typologyName">Tipología *</label>
                        <span *ngIf="isValidField('typologyName')">{{
                          getFieldError("typologyName")
                        }}</span>
                      </bc-form-field>
                    </div>
                  </div>
                  <div class="bc-row">
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field [attr.disabled]="showAllFields()">
                        <input
                          bc-input
                          type="text"
                          list="productCodeOptions"
                          id="productCode"
                          aria-label="columna1"
                          maxlength="50"
                          formControlName="productCode"
                          (keyup)="
                            showSelectedValue('productCode', 'productDetail')
                          "
                          type="text"
                        />
                        <datalist id="productCodeOptions">
                          <option
                            *ngFor="let option of digitalProductSFC"
                            [value]="option.ref"
                          ></option>
                        </datalist>
                        <label for="productCode">Código Producto SFC *</label>
                        <span *ngIf="isValidField('productCode')">{{
                          getFieldError("productCode")
                        }}</span>
                      </bc-form-field>
                    </div>
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field
                        [attr.disabled]="showAllFields() || productSFCDisable"
                      >
                        <input
                          bc-input
                          type="text"
                          id="productDetail"
                          class="bc-input"
                          aria-label="columna2"
                          maxlength="100"
                          formControlName="productDetail"
                        />
                        <label for="productDetail"> Detalle producto * </label>
                        <span *ngIf="isValidField('productDetail')">{{
                          getFieldError("productDetail")
                        }}</span>
                      </bc-form-field>
                    </div>
                  </div>
                  <div class="bc-row">
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field [attr.disabled]="showAllFields()">
                        <input
                          bc-input
                          type="text"
                          list="motiveCodeOptions"
                          id="motiveCode"
                          class="bc-input"
                          aria-label="columna1"
                          maxlength="50"
                          formControlName="motiveCode"
                          (keyup)="
                            showSelectedValue('motiveCode', 'motiveDetail')
                          "
                        />
                        <datalist id="motiveCodeOptions">
                          <option
                            *ngFor="let option of motiveFields"
                            [value]="option.ref"
                          ></option>
                        </datalist>
                        <label for="motiveCode">Código Motivo SFC *</label>
                        <span *ngIf="isValidField('motiveCode')">{{
                          getFieldError("motiveCode")
                        }}</span>
                      </bc-form-field>
                    </div>
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field
                        [attr.disabled]="showAllFields() || motiveSFCDisable"
                      >
                        <input
                          bc-input
                          type="text"
                          id="motiveDetail"
                          class="bc-input"
                          aria-label="columna2"
                          maxlength="100"
                          formControlName="motiveDetail"
                        />
                        <label for="motiveDetail">Detalle Motivo *</label>
                        <span *ngIf="isValidField('motiveDetail')">{{
                          getFieldError("motiveDetail")
                        }}</span>
                      </bc-form-field>
                    </div>
                  </div>
                  <div class="bc-row">
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field [attr.disabled]="showAllFields()">
                        <input
                          bc-input
                          type="text"
                          list="channelCodeOptions"
                          id="channelCode"
                          class="bc-input"
                          aria-label="columna1"
                          maxlength="50"
                          formControlName="channelCode"
                          (keyup)="
                            showSelectedValue('channelCode', 'channelDetail')
                          "
                        />
                        <datalist id="channelCodeOptions">
                          <option
                            *ngFor="let option of channelFields"
                            [value]="option.ref"
                          ></option>
                        </datalist>
                        <label for="channelCode">Código Canal</label>
                        <span *ngIf="isValidField('channelCode')">{{
                          getFieldError("channelCode")
                        }}</span>
                      </bc-form-field>
                    </div>
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field
                        [attr.disabled]="showAllFields() || channelSFCDisable"
                      >
                        <input
                          bc-input
                          type="text"
                          id="channelDetail"
                          class="bc-input"
                          aria-label="columna2"
                          maxlength="100"
                          formControlName="channelDetail"
                        />
                        <label for="channelDetail">Detalle Canal</label>
                      </bc-form-field>
                    </div>
                  </div>
                  <div class="bc-row bc-margin-bottom-2">
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field [attr.disabled]="showAllFields()">
                        <input
                          bc-input
                          type="text"
                          list="digitalProductCodeOptions"
                          id="digitalProductCode"
                          class="bc-input"
                          aria-label="columna2"
                          maxlength="50"
                          formControlName="digitalProductCode"
                          (keyup)="
                            showSelectedValue(
                              'digitalProductCode',
                              'digitalProduct'
                            )
                          "
                        />
                        <datalist id="digitalProductCodeOptions">
                          <option
                            *ngFor="let option of digitalFields"
                            [value]="option.ref"
                          ></option>
                        </datalist>
                        <label for="digitalProductCode"
                          >Código Producto Digital *</label
                        >
                        <span *ngIf="isValidField('digitalProductCode')">{{
                          getFieldError("digitalProductCode")
                        }}</span>
                      </bc-form-field>
                    </div>
                    <div class="bc-col-6 bc-col-xs-12">
                      <bc-form-field
                        [attr.disabled]="
                          showAllFields() || digitalProductSFCDisable
                        "
                      >
                        <input
                          bc-input
                          type="text"
                          id="digitalProduct"
                          class="bc-input"
                          aria-label="columna1"
                          maxlength="100"
                          formControlName="digitalProduct"
                        />
                        <label for="digitalProduct">Producto Digital *</label>
                        <span *ngIf="isValidField('digitalProduct')">{{
                          getFieldError("digitalProduct")
                        }}</span>
                      </bc-form-field>
                    </div>
                  </div>
                  <div class="pt-1 mb-4"></div>
                </form>
              </div>
            </div>
          </bc-modal>

          <bc-modal
            [title]="title"
            textBtn="Confirmar"
            textBtnLeft="Cancelar"
            [isCancellable]="true"
            typeIcon="warning"
            [outerCloseTap]="false"
            (stateClick)="emptyChannelConfirm($event)"
            [hideCloseButton]="true"
            customWidth="small"
            #modalChannel
          >
            <div modalContent>
              <div>
                <h4 class="text-center">El campo canal esta vacío.</h4>
              </div>
              <br />
              <div>
                <h5 class="text-center">¿Está seguro que desea continuar?</h5>
              </div>
            </div>
          </bc-modal>

          <bc-modal
            [title]="title"
            textBtn="Confirmar"
            textBtnLeft="Cancelar"
            [isCancellable]="true"
            typeIcon="warning"
            [outerCloseTap]="false"
            (stateClick)="openNew($event)"
            [hideCloseButton]="true"
            customWidth="small"
            #modalContinueNew
          >
            <div modalContent>
              <div>
                <h4 class="text-center">Hay cambios sin guardar</h4>
              </div>
              <br />
              <div>
                <h5 class="text-center">¿Está seguro que desea continuar?</h5>
              </div>
            </div>
          </bc-modal>
        </div>
      </div>
    </div>
  </div>
</div>

<app-circle-loading></app-circle-loading>
