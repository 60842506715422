<div class="menu-content"
    (click)="$event.stopPropagation()">
    <div class="bc-container">
    <div class="bc-row">
          <input
            [(ngModel)]="filterText"
            (ngModelChange)="filterTextFunction()"
            bc-input
            class="bc-margin-top-1 bc-margin-left-2 bc-margin-right-2 bc-margin-bottom-2"
            type="text"
            placeholder="Buscar"
          />
        </div>
        <div class="bc-row">
          <div  *ngIf="!loading"  class="container">
              <label
                *ngFor="let item of  columnOptionsFilter"
                class="label-filter"
                >
                <input
                  [(ngModel)]="selectedValue"
                  [id]="item"
                  type="radio"
                  [value]="item"
                    />
                    {{ item }}
                  <br>
              </label>
          </div>

          <div *ngIf="loading"  class="container  centralize">
              <bc-loader
                speedLoader="2"
                sizeLoader="xl"
              ></bc-loader>
          </div>
        </div>
        <br>
      <div class="bc-row">
        <button
          bc-button
          class="bc-margin-bottom-1"
          sizeButton="small"
          typeButton="primary"
          (click)="searchFilter()"
          [disabled]="isDisableFilterSearch()"
        >
          Buscar
        </button>
        <button
          bc-button
          class="bc-margin-bottom-2"
          sizeButton="small"
          typeButton="secondary"
          (click)="clearFilter()"
        >
          Limpiar
        </button>
      </div>
    </div>
</div>
