import { Component, ChangeDetectorRef, ViewChild, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TransmissionStoreService } from 'src/app/core/stores/transmissionStore/transmission-store.service';
import { TransmissionService } from 'src/app/core/services/transmission/transmission.service';
import { BaseTable } from '../../../../shared/components/baseTable/BaseTable';
import Swal from 'sweetalert2';
import { TransmissionDTO } from 'src/app/core/models/transmission/TransmissionDTO';
import {
  ITransmissionFields,
} from 'src/app/core/models/transmission/ITransmission';
import { ToastrService } from 'ngx-toastr';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';

@Component({
  selector: 'app-transmission-table',
  templateUrl: './transmission-table.component.html',
  styleUrls: ['./transmission-table.component.scss'],
})

export class TransmissionTableComponent extends BaseTable implements OnInit, OnDestroy {
  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;
  @Input() crmValue: string = "";
  readonly prohibitedFields = [parseInt("10"), parseInt("12"), parseInt("13"), parseInt("23")];
  public columns: string[] = [];
  public loading: boolean = true;
  public columnOptionsFilter: string[] = [];
  public filterText: string = '';
  public searchFormData!: TransmissionDTO;
  public momentToChange!: number;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private transmissionService: TransmissionService,
    private transmissionStoreService: TransmissionStoreService,
    public toastr: ToastrService
  ) {
    super(transmissionService, transmissionStoreService);
  }

  ngOnInit() {
    this.transmissionStoreService.setLoading(true);
    this.transmissionStoreService.getData().subscribe((data: any) => {
      if (data.length != 0) {
        this.arrayData = data;
        this.columns = Object.keys(this.arrayData[0]);
        this.reorganizarDataStandard();
      }
    });
    this.getData();
    this.transmissionStoreService.getTotal().subscribe((value: number) => {
      this.total = value;
    });

    this.transmissionStoreService
      .getCurrentPage()
      .subscribe((pageNumber: number) => {
        this.currentPage = pageNumber;
      });

    this.transmissionStoreService.getLoading().subscribe((value: boolean) => {
      this.loading = value;
    });

    this.transmissionStoreService.getFormData().subscribe((value: any) => {
      this.searchFormData = value;
    });

  }

  ngOnDestroy(): void {
    this.transmissionStoreService.setTotal(0)
  }

  ngOnChanges(changes:SimpleChanges) {
    console.log(changes);
    
    if(changes['crmValue'].previousValue != undefined){
      if (changes['crmValue'].currentValue != changes['crmValue'].previousValue){
        this.circleLoading.show(); //Necesario al cambiar de crm
        this.getData();
      }
    }
  }

  getData() {
    this.transmissionService.listFields(this.crmValue).subscribe({

      next: (response: any) => {

        if (response.data.length > 0) {
          response.data.forEach((item: any) => {
            this.columnOptionsFilter.push(item['Campo Reporte']);
          });
        }
        this.transmissionStoreService.setAllData(response);
        this.transmissionStoreService.setTotal(this.total);
        this.transmissionStoreService.setLoading(false);
        setTimeout(() => { 
          this.circleLoading.hide();
        }, 0);

      },
      error: () => {

        setTimeout(() => {
          this.circleLoading.hide();
        }, parseInt("200"));
      },
      complete: () => {
        setTimeout(() => {
          this.circleLoading.hide();
        }, parseInt("200"));
      }
    });
  }

  searchFilter($event: any) {
    this.searchFormData.keyWord = $event;
    this.transmissionStoreService.setLoading(true);
    this.transmissionService.listFilter(this.searchFormData.keyWord, this.searchFormData.crm).subscribe({
      next: (response: any) => {
        this.transmissionStoreService.setCurrentPage(1);
        this.transmissionStoreService.setFormData(this.searchFormData);
        this.transmissionStoreService.setIsSearch(true);
        this.transmissionStoreService.setAllData(response);
        this.transmissionStoreService.setTotal(response.total);
        this.transmissionStoreService.setLoading(false);
      },
    });
  }

  clearFilter($event: any) {
    this.getData();
  }

  modalConfirm(
    row: any,
    campo: string,
    fcolumn: string,
    column: string,
    element: any,
    on: boolean
  ) {
    let message = '';
    if (on) {
      message =
        "<p style='color:black'>Estas seguro que quieres apagar el campo " +
        campo +
        ' para momento ' +
        fcolumn +
        '</p>';
    } else {
      message =
        "<p style='color:black'>Estas seguro que quieres encender el campo " +
        campo +
        ' para momento ' +
        fcolumn +
        '</p>';
    }
    Swal.fire({
      title: message,
      position: 'top',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      allowOutsideClick: false,
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'customButton customButton-primary',
        cancelButton: 'customButton customButton-secondary',
      },
      buttonsStyling: false,
    }).then((result) => {
      this.postConfirmSwitch(on, result, element, row, column);
    });
  }

  postConfirmSwitch(
    on: boolean,
    result: any,
    element: any,
    row: any,
    column: string
  ) {
    if (result.isConfirmed) {   ;
      this.modifieState(row.all, column, on);
    }

    if (on) {
      if (result.isConfirmed) {
        element.isChecked = false;
        row[column] = false;
      } else {
        element.isChecked = true;
      }
    } else {
      if (result.isConfirmed) {
        element.isChecked = true;
        row[column] = true;
      } else {
        element.isChecked = false;
      }
    }
  }

  /**
   * Permite cambiar el switch en transmisión.
   */
  onChangeState(element: any, row: any, column: string, campo: string) {
    this.cd.detectChanges();
    const fcolumn = column.split(' ')[1];
    if (row[column]) {
      this.modalConfirm(row, campo, fcolumn, column, element, true);
    } else {
      this.modalConfirm(row, campo, fcolumn, column, element, false);
    }
  }

  modifieState(element: ITransmissionFields, moment: string, on: boolean) {
    switch (moment) {
      case 'Momento 1':
        element.moment1Aqr = !element.moment1Aqr;
        this.momentToChange = 1;
        break;
      case 'Momento 2':
        element.moment2Aqr = !element.moment2Aqr;
        this.momentToChange = 2;
        break;
      case 'Momento 3':
        element.moment3Aqr = !element.moment3Aqr;
        this.momentToChange = 3;
        break;
    }
    this.circleLoading.show();
    this.transmissionService
      .changeState(element, this.momentToChange, this.crmValue)
      .subscribe({
        next: () => {
        },
        error: () => {
          this.circleLoading.hide();
          this.alertMessage('No se grabaron los cambios', { status: 'error' });
          window.location.reload();
        },
        complete: () => {
          this.circleLoading.hide();
          if (on) {
            this.alertMessage('Apagado exitosamente', { status: 'ok' });
          } else {
            this.alertMessage('Encendido exitosamente', { status: 'ok' });
          }
        }

      }
      );


  }

  /**
   * Linkea al usuario a la página detalle de acuerdo al campo reporte que haya seleccionado.
   */
  onClickReport(column: string) {
    this.router.navigate(["/transmision/" + column]);
  }

  toggleDropdown(event: MouseEvent, dropdown: any) {
    event.stopPropagation();

    if (dropdown) {
      if (this.activeDropdown != null) {
        this.activeDropdown.classList.remove('active');
      }

      if (this.activeDropdown === dropdown) {
        this.activeDropdown = null;
      } else {
        if (this.disableAll) {
          this.activeDropdown = null;
        } else {
          this.activeDropdown = dropdown;
          this.activeDropdown.classList.toggle('active');
        }
      }
    }
  }

  goToDetail(cod: any) {
    localStorage.setItem('detailFieldName', cod['Campo Reporte']);
    localStorage.setItem('crmType',this.crmValue);
    if (!(this.prohibitedFields.includes(cod['Cod']))) {
      this.router.navigate(['transmision/campo', cod['Cod']]);
    }

  }

  alertMessage(message: string, payload: any) {
    this.toastr.show(message, '', {
      tapToDismiss: false,
      closeButton: true,
      progressBar: true,
      timeOut: 12000,
      extendedTimeOut: 10000,
      toastClass: 'toast-icon',
      payload: payload,
      positionClass: 'toast-top-center',
    });
  }

  onChangePage(e: any) {
    this.circleLoading.show();
    setTimeout(() => {
      this.onChangePageStandard(e);
      this.circleLoading.hide();
    }, 200);

  }

  detectRow(cod: any) {
    if (this.prohibitedFields.includes(cod['Cod'])) {
      return false
    }
    else {
      return true
    }
  }
}
