import { Component, ViewChild } from '@angular/core';
import { HistoryService } from 'src/app/core/services/history/history.service';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';
import { HistoryStoreService } from 'src/app/core/stores/historyStore/history-store.service';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})


export class HistoryComponent {

  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;

  public maxDate: Date=new Date();
  public maxima: any = Date.now();
  public minDate!:string;
  public showFinalDate: boolean = false;
  public total = 0;
  public crm:string;
  public selectedTable = [
    {
      label: "Categorización",
      value: "VCategorizacion"
    },
    {
      label: "Transmisión",
      value: "Campos"
    },
    {
      label: "Detalle Transmisión",
      value: "VTransmision"
    }
  ]
  public isClicked!: boolean;
  public searchForm!: FormGroup;
  public tableValue:string="VCategorizacion"
  public crms = [
    {
      label: "SAP",
      value: "SAP"
    },
    {
      label: "SALESFORCE",
      value: "SALESFORCE"
    }
  ]

  constructor(
    private historyService: HistoryService,
    private historyStoreService: HistoryStoreService,
    private downloadService: DownloadService,
    private fb:NonNullableFormBuilder,

  ) {this.crm = "SAP" }



  ngOnInit() {
    this.searchForm=this.fb.group({
      page: 0,
      elements: 7,
      table:"VCategorizacion",
      keyWord: "",
      startDate:"",
      endDate: "",
    });

    this.historyStoreService.getTotal().subscribe((value:number)=>{
      this.total=value
    })
  }

  ngOnDestroy(): void {
    this.historyStoreService.setTotal(0);
    this.historyStoreService.emptyData()
  }


    /**
   * Genera y descarga el historial de cambios realizados en la ventana Homologacion Categorizacion
   */
  downloadExcel() {
    this.circleLoading.show();
    this.historyService.download(this.searchForm.value,this.crm,true).subscribe({
      next: (data) => {
        if (data) {
          this.downloadService.downloadLogs("Historial de Modificación", "Hoja 1", data.data)
        }
      },
      error: () => { this.circleLoading.hide(); },
      complete: () => { this.circleLoading.hide(); this.isClicked = true; }
    })
    this.isClicked = true;
  }

  searchHistory(page=0){
 
    this.historyStoreService.setLoading(true)
    this.historyStoreService.setTotal(0);
    this.historyService.download(this.searchForm.value,this.crm).subscribe({
      next:(response:any)=>{
      this.historyStoreService.setCurrentPage(1)
      this.historyStoreService.setIsSearch(true)
      this.historyStoreService.setFormData(this.searchForm.value)
      this.historyStoreService.setAllData(response,page)
      this.historyStoreService.setTotal(response.total)
      this.historyStoreService.setLoading(false)
    }})
  }


  searchHistorySelect(event:any){

    if(this.tableValue != event){
      this.tableValue = event      

      this.cleanHistory()
    }

  }


  cleanHistory(){
    this.historyStoreService.setLoading(true)
    this.historyStoreService.setIsSearch(false)
    this.searchForm.controls['keyWord'].setValue("")
    this.searchForm.controls['startDate'].setValue("")
    this.searchForm.controls['endDate'].setValue("")
    this.historyStoreService.setFormData(this.searchForm.value)
    Object.keys(this.searchForm.controls).forEach(key =>{
      this.searchForm.controls[key].setErrors(null)
    });
    this.showFinalDate = false
    
    this.historyService.list(0,this.tableValue,this.crm,parseInt("7")).subscribe({
      
      next:(response:any)=>{
        
      
      this.historyStoreService.setCurrentPage(1)
      this.historyStoreService.setAllData(response,0)
      this.historyStoreService.setTotal(response.total)
      this.historyStoreService.setLoading(false)
    }})
  
  }

    /**
   * Define una fecha minima para el date picker de fecha final
   * @param e, fecha seleccionada en el datepicker de fecha inicial
   */

  initialDate(e:any){
    if(e){
      setTimeout(()=>{ this.showFinalDate = false }, 1)
      this.minDate = e;
      if(this.searchForm.value['endDate']<e){
        this.searchForm.controls['endDate'].setValue("") 
      }
    
    this.searchForm.controls['startDate'].setValue(this.minDate)
    setTimeout(()=>{ this.showFinalDate = true }, 1)
    }
  }
  finalDate(e:any){
    this.searchForm.controls['endDate'].setValue(e)
  }

  switchCrm(evento:any){
    if(this.crm != evento){
      this.crm = evento      
      this.circleLoading.show();
      this.cleanHistory()
    }
    this.circleLoading.hide();
  }
}
