import { Component } from '@angular/core';
import { IuserInformation } from 'src/app/core/models/history/history';
import { HistoryService } from 'src/app/core/services/history/history.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',

})
export class HeaderComponent {

  public user:string = '';
  constructor(private headersService: HistoryService) {

 }
  ngOnInit(): void {
    this.user = this.headersService.username;
    if(!this.user){
      this.headersService.name().subscribe((data: IuserInformation) => {
        this.user = data.displayName
      })
  }
}
}
