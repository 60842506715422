import {FormControl, FormGroup} from '@angular/forms';

export class BasePage {
  public columns:string[]=[];
  public dataOperation:{[key: string]:any}[]=[];
  public deleteorEdit:boolean=true //True delete and false edit
  public arrayData = [];
  public pages:any = [];
  public itemsPerPage = parseInt("7");
  public currentPage: number = 1;
  public total = 0;
  public search:string = "";
  public title = "";
  public dataEdit: any = null;
  public dataDelete = null;
  public showSelect = false;
  public nameComponent = "";
  public isClicked: boolean = false;
  public form!: FormGroup;
  public searhControl = new FormControl('', []);
  public activeDropdown: any = null;

  public columnsFilter:string[]=[
    "ID Entidad",
    "Nombre Entidad",
    "ID Producto",
    "Producto",
    "ID Tipología",
    "Tipología",
    "Código Producto SFC",
    "Detalle producto",
    "Código Motivo SFC",
    "Detalle Motivo",
    "Código Canal",
    "Detalle Canal",
    "Código Producto Digital",
    "Producto Digital"
  ]


  constructor(
    private service: any,
    private storeService: any,
    nameComponent: string) {
    this.nameComponent = nameComponent;
  }

/**
   * Abre el modal para cancelar las operaciones.
   */
  openModalCancel(modal:any){
    if(Object.keys(this.storeService.getDeleteData()).length!=0 || Object.keys(this.storeService.getNewEditData()).length!=0){
      modal.openModal()
    }
  }

  /**
   * Crea un arreglo a partir de un objeto.
   */
  setToArray(setData: Set<any>): any[] {
    return Array.from(setData);
  }


  /**
   * Abre el modal para guardar la información.
   */
  openModalSave(modal:any,type:number){
    let haveData=0
    if(type==0){
      this.deleteorEdit=true
      this.dataOperation=Object.values(this.storeService.getDeleteData())
      haveData=Object.keys(this.dataOperation).length
      if(haveData==0){
        this.deleteorEdit=false
        this.dataOperation=Object.values(this.storeService.getNewEditData())
      }
    }else if(type==1){
      this.deleteorEdit=false
      this.dataOperation=Object.values(this.storeService.getNewEditData())
    }
    haveData=Object.keys(this.dataOperation).length
    if(haveData>0){
      this.columns=Object.keys(this.dataOperation[0])
      modal.openModal();
    }
  }


  deleteModal(row:any, modal: any) {
    this.dataDelete = row;
    modal.openModal();
  }





}
