import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { TransmissionComponent } from '../transmission/pages/transmission/transmission.component';
import { TransmissionDetailComponent } from '../transmission/pages/transmission-detail/transmission-detail.component';
import { environment } from 'src/environments/environment';
import { MsalGuard } from '@azure/msal-angular';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';

const guards: any[] = environment.enableMsal === 'true' ? [MsalGuard] : [];

const transmissionRoutes: Routes = [

      { path: '', component: TransmissionComponent,

      },
      {
        path: 'campo/:cod',
        component: TransmissionDetailComponent,
        canDeactivate: [PendingChangesGuard],
        data: { page: 'transmission' }
      }
    ]

@NgModule({
  imports: [RouterModule.forChild(transmissionRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class TransmissionRoutingModule {}
