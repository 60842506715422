import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent {

  @Input() columnOptionsFilter: string[] = [];
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<boolean>();


  public filtersOptions: string[] = [];
  public selectedValue:string=""
  public filterText: string = '';
  public loading:boolean=true

  ngOnChanges() {
    if(this.columnOptionsFilter!=undefined){
      this.loading=false
      this.filtersOptions=[...this.columnOptionsFilter]
    }
  }

  searchFilter(){
    this.search.emit(this.selectedValue);
  }

  clearFilter(){
    this.selectedValue=""
    this.filterText=""
    this.clear.emit(true)
  }


  isDisableFilterSearch(){
    return this.selectedValue==""?true:false
  }


filterTextFunction() {
  if (this.filterText.length != 0) {
    this.columnOptionsFilter = this.filtersOptions.filter(
      (item: string) => {
        return item.toLowerCase().includes(this.filterText.toLowerCase());
      }
    );
  } else {
    this.columnOptionsFilter = this.filtersOptions;
  }
}


}
