import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { environment } from 'src/environments/environment';
import { MsalGuard } from '@azure/msal-angular';
import { EmptyComponent } from './empty/empty.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

const guards: any[] = environment.enableMsal === "true" ? [AuthGuard,MsalGuard] : [];

const routes: Routes = [
  {
    path: '',
    component:MainComponent,
    children: [
      {
        path: 'inicio',
        component:  HomeComponent
      }
    ]
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'categorizacion',
        canActivateChild:guards,
        loadChildren: () => import('../categorization/categorization.module').then(m => m.CategorizationModule)
      },
      {
        path: 'transmision',
        canActivateChild:guards,
        loadChildren: () => import('../transmission/transmission.module').then(m => m.TransmissionModule)
      },
      {
        path: 'history',
        canActivateChild:guards,
        loadChildren:()=> import('../history/history.module').then(m=>m.HistoryModule)
      }
    ]
  },
  {
    path: 'authentication',
    component: EmptyComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  },
  {
    path:'**',
    redirectTo:'authentication/login'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
  providers: [

  ],
})
export class LayoutRoutingModule { }
