import {NgModule} from '@angular/core';
import { BcHeaderModule} from '@bancolombia/design-system-web/bc-header';
import { BcMenuModule} from '@bancolombia/design-system-web/bc-menu';
import { SharedModule } from '../../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { EmptyComponent } from './empty/empty.component';
import { MainComponent } from './main/main.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { HistoryModule } from '../history/history.module';
import { CategorizationModule } from '../categorization/categorization.module';
import { TransmissionModule } from '../transmission/transmission.module';

@NgModule({
  declarations: [
    HeaderComponent,
    EmptyComponent,
    MainComponent
  ],
  imports: [
    BcHeaderModule,
    LayoutRoutingModule,
    BcMenuModule,
    SharedModule,
    HistoryModule,
    CategorizationModule,
    TransmissionModule
  ],
  exports: [
    HeaderComponent,
  ]
})
export class LayoutModule {
}
