export const TableHeaders=[{
    "idEntity": "ID Entidad",
    "entityName": "Nombre Entidad",
    "idProduct":"ID Producto",
    "product": "Producto",
    "idTypology": "ID Tipología",
    "typology": "Tipología",
    "sfcProduct_sfcId":"Código Producto SFC",
    "sfcProduct_sfcDetail":"Detalle Producto",
    "sfcMotive_sfcId":"Código Motivo SFC",
    "sfcMotive_sfcDetail":"Detalle Motivo",
    "sfcChannel_sfcId":"Código Canal",
    "sfcChannel_sfcDetail":"Detalle Canal",
    "sfcDigitalProduct_sfcId":"Código Producto Digital",
    "sfcDigitalProduct_sfcDetail":"Producto Digital",
}]

    export const thinCells =
    ["1", "2", "3", "5", "7", "8", "9", "11", "13", "14"]

    export const thickCells =
    ["4", "6", "10", "12"]
