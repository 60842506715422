import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFilterDetail } from '../../models/transmission/IFilterDetail';

@Injectable({
  providedIn: 'root'
})
export class TransmissionDetailStoreService {


  private data=new BehaviorSubject<{[key:number]:any}>({});
  private loading=new BehaviorSubject<boolean>(true);
  private total=new BehaviorSubject<number>(0);
  private reload=new BehaviorSubject<boolean>(false);
  private momentSelect=new BehaviorSubject<number>(1);
  private previousEditData=new BehaviorSubject<{[key:number]:any}>({});
  private newEditData=new BehaviorSubject<{[key:number]:any}>({});
  private deleteData=new BehaviorSubject<{[key:number]:any}>({});
  private pagesForCancel = new BehaviorSubject<Set<number>>(new Set());
  private currentPage=new BehaviorSubject<number>(1);
  private haveSearchFilter=new BehaviorSubject<boolean>(true);
  private valuesAutocomplete=new BehaviorSubject<{[key:string]:any}>({
    "Código SFC":{},
  })
  private newValuesColumns=new BehaviorSubject<{[key:number]:any}>({});
  private isFilter=new BehaviorSubject<boolean>(false);
  private field=new BehaviorSubject<string>("");

  private requestData = new BehaviorSubject<IFilterDetail> ({
    page: 0,
    elements: 7,
    keyWord: "",
    filterObject: {
        hmlCod: "",
        crmHRefCod: "",
        crmValue: "",
        crmDetail: "",
        sfcHRefCod: "",
        sfcValue: "",
        sfcDetail: ""
    }
})

private columnsIcon = new BehaviorSubject<{[key:string]:any}>({
  "Código SFC" : 'arrow2-down',
  "Nombre SFC" : 'arrow2-down',
  "Código CRM" : 'arrow2-down',
  "Nombre CRM" : 'arrow2-down'
})


getColumnsIcon(){
  return this.columnsIcon
}

setColumnsIcons(value:any){
  this.columnsIcon.next(value)
}

  getField(){
    return this.field
  }

  setField(value:string){
    this.field.next(value)
  }

  // Methods for newValuesColumns
  getNewValuesColumns(){
    return this.newValuesColumns.getValue()
  }

  setNewValuesColumns(value:any){
    const id=value["id"]
    const codigo=value["codigo"]
    const nombre=value["nombre"]
    const tempData: any = {...this.newValuesColumns.getValue(), [id]: [codigo,nombre]};
    this.newValuesColumns.next(tempData)
  }

  removeNewValuesColumns(id:number){
    const tempData= this.newValuesColumns.getValue()
    delete tempData[id]
    this.newValuesColumns.next(tempData)
  }

  emptyNewValuesColumns(){
    this.newValuesColumns.next({})
  }


  //Method for valuesautocomplete
  getValuesAutocomplete(){
    return this.valuesAutocomplete
  }

  emptyValuesAutocomplete(){
    this.valuesAutocomplete.next({
      "Código SFC":{},
    })
  }


  //Methods for loading
  getLoading(){
    return this.loading
  }

  setLoading(value:boolean){
    this.loading.next(value)
  }

    //Methods for total
  getTotal(){
    return this.total
  }

  setTotal(value:number){
    this.total.next(value)
  }

  //Methods for requestData

  getRequestData(){
    return this.requestData
  }

  setRequestData(value:IFilterDetail){
    this.requestData.next(value)
  }


  // Methods for data
  getData(){
    return this.data
  }

  setData(value:any){
    const numberPage=value.page
    const newValue=value.data
    const tempData: any = {...this.data.getValue(), [numberPage]: newValue};
    this.data.next(tempData);
  }

  //Methods for reload
  getReload(){
    return this.reload
  }

  setReload(value:boolean){
    this.reload.next(value)
  }

  //Methods for momentSelect
  getMomentSelect(){
    return this.momentSelect
  }

  setMomentSelect(value:number){
    this.momentSelect.next(value)
  }

  // Method for isFilter
getIsFilter(){
  return this.isFilter
}

setIsFilter(value:boolean){
  this.isFilter.next(value)
}

  //Methods for haveSearchFilter
getHaveSearchFilter(){
  return this.haveSearchFilter.getValue()
}

setHaveSearchFilter(value:boolean){
  this.haveSearchFilter.next(value)
}


  setDataCancel(value:any,page:number){
    const arrayTemp=this.getData().getValue()[page]
    const filterArray= arrayTemp.filter(((element:any) => element["ID"] !== value["ID"]))
    filterArray.push(value)
    filterArray.sort((a:any, b:any) => a["ID"] - b["ID"]);
    const tempData:any= {...this.data.getValue(), [page]: filterArray};
    this.data.next(tempData)
  }

  cancelPreviousEditData(value:any){
    const id=value["ID"]
    delete this.previousEditData.getValue()[id]
    this.previousEditData.next(this.previousEditData.getValue())
  }


  cancelNewEditData(value:any){
    const id=value["ID"]
    delete this.newEditData.getValue()[id]
    this.newEditData.next(this.newEditData.getValue())
  }

  cancelDeleteData(value:any){
    const id=value["ID"]
    delete this.deleteData.getValue()[id]
    this.deleteData.next(this.deleteData.getValue())
  }

  setPageforCancel(value:number){
    const newValue=this.pagesForCancel.getValue()
    newValue.add(value)
    this.pagesForCancel.next(newValue)
  }


  setNewEditData(value:any){
    const id=value["ID"]
    const tempData: any = {...this.newEditData.getValue(), [id]: value};
    this.newEditData.next(tempData)
  }

  getNewEditData(){
    return this.newEditData.getValue()
  }

  setPreviousEditData(value:any){
    const id=value["ID"]
    const tempData: any = {...this.previousEditData.getValue(), [id]: value};
    this.previousEditData.next(tempData)
  }

  getPreviousEditData(){
    return this.previousEditData.getValue()
  }

  emptyPreviousEditData(){
    this.previousEditData.next({})
  }

  emptyNewEditData(){
    this.newEditData.next({})
  }



  setCurrentPage(value:number){
    this.currentPage.next(value)
  }

  setNewAllEditData(){
    this.newEditData.next({})
  }

  getDeleteData(){
    return this.deleteData.getValue()
  }

  setDeleteData(value:any){
    const id=value["ID"]
    const tempData: any = {...this.deleteData.getValue(), [id]: value};
    this.deleteData.next(tempData)
  }

  emptyData(){
    this.data.next({})
  }

  getCurrentPage(){
    return this.currentPage
  }


}
