import { Component, Input } from '@angular/core';
import { MappingService } from 'src/app/core/services/mapping/mapping.service';


@Component({
  selector: 'app-detail-error',
  templateUrl: './detail-error.component.html',
  styleUrls: ['./detail-error.component.scss']
})
export class DetailErrorComponent {

  @Input() data: any = { "detail": "" };

  constructor(
    private mappingService: MappingService,
  ) {
  }

  /**
   * Extrae el numero de acuerdo a la posición (Ej: 1023-3245 y posicion 0, extrae el 1023).
   */
  extractNumber(inputString: any, position: number) {
    return inputString.split('-')[position];
  }

  /**
   * Convierte a string.
   */
  convertString(inputString: any) {
    return inputString.toString()
  }

  /**
   * Convierte a arreglo.
   */
  convertArray(inputArray: any) {
    return inputArray
  }

  /**
   * Convierte el detalle del error de un formato de diccionario a otro.
   */
  convertDetail(originalDictionary: any): any {
    const convertedDictionary: any = {};

    for (const entityKey in originalDictionary) {
      if (originalDictionary.hasOwnProperty(entityKey)) {
        const properties = originalDictionary[entityKey];

        for (const propertyKey in properties) {
          if (properties.hasOwnProperty(propertyKey)) {
            const combinedKey = `${propertyKey} ${entityKey}`;

            convertedDictionary[combinedKey] = properties[propertyKey];
          }
        }
      }
    }

    return this.mappingService.categorizationDetailError(convertedDictionary)
  }

}
