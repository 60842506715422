import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class ErrorNotifierService implements HttpInterceptor {

  toastRef:any;

  /*
  status:ok,error,info,hand (hand is warning)
  */
  public dataResponse:any={
    "code":parseInt("400"),
    "status":"error",
    "page":"categorización",
    "operation":"edit",
    "detail":''
  }

  constructor(
    private toastr: ToastrService
  ) { }

  /**
   * Intercepta todas las response y muestra mensaje de error si alguna falla.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next:() => { },
        error:(error) => {
          let message="En estos momentos tenemos problemas de conexión, inténtalo más tarde"
          if(error.status==parseInt("400")){
            if(error.error.data.objectList != undefined){
              this.dataResponse={"status":"error","code":500}
              message="La fecha final es mayor a la fecha inicial."
            }else{
              if(Object.keys(error.error.data.validationDetail)[0]==="newTypology"){
                this.dataResponse={...this.dataResponse,detail:error.error,operation:"new"}
              }else if(Object.keys(error.error.data.validationDetail)[0]==="New data to update"){
                this.dataResponse.page="transmisión"
                this.dataResponse={...this.dataResponse,detail:error.error,operation:"newData"}
              }else{
                this.dataResponse={...this.dataResponse,detail:error.error,operation:"edit"}
              }
              message="La información suministrada es incorrecta, por favor verifica e intenta nuevamente."
            }

          }else{
            this.dataResponse={"status":"error","code":500}
          }
          if (error instanceof HttpErrorResponse) {
            this.alertMessage(message)
          }
        }
  }));

  }

  alertMessage(message:string){
    this.toastRef = this.toastr.show(message,"",{
      tapToDismiss: false,
      positionClass:'toast-top-center',
      closeButton: true,
      progressBar:true,
      timeOut:12000,
      extendedTimeOut:10000,
      toastClass: "toast-icon",
      payload:this.dataResponse
    });
  }


}
