import { Component, HostListener} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { SizeType } from '@bancolombia/design-system-web/bc-icon';
import { CategorizationStoreService } from 'src/app/core/stores/categorizationStore/categorization-store.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  {
  iframeWidth: number = innerWidth ;
  iframeHeight: number = innerHeight- parseInt("80") ;
  size:SizeType = "3xl"
  powerBi = this.sanitizer.bypassSecurityTrustResourceUrl(environment.powerBi) ;

  constructor(private msalService: MsalService,
              public categorizationStoreService: CategorizationStoreService,
              private sanitizer: DomSanitizer) { }
              
          
  login() {
      this.msalService.loginRedirect();
      this.calculateIframeSize();
  }
  ngOnInit(): void {
    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener('resize', () => {
      this.calculateIframeSize();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // Recalcula el tamaño del iframe cuando la ventana se redimensiona
    this.calculateIframeSize();
  }

  calculateIframeSize() {
    // Asigna el ancho y alto de la ventana al iframe
    this.iframeWidth = window.innerWidth ;
    this.iframeHeight = window.innerHeight - parseInt("80");
  }

}
