import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { CategorizationStoreService } from 'src/app/core/stores/categorizationStore/categorization-store.service';
import { Menu } from 'src/app/shared/constants/menuItems';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent {
  items = Menu;
  constructor(private authService: MsalService,
              public categorizationStoreService: CategorizationStoreService) {
  }




/**
 * Permite desloguearse y salir del modulo administrativo
 */
  logout(){
    this.authService.logout()
    this.categorizationStoreService.setOpen(false)
  }

}
