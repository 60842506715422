import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'app-categorization-error',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
})
export class CustomToastComponent extends Toast {

  isHidden=true


  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  /**
   * Muestra o no muestra el detalle del error.
   */
  toggleText() {
    this.isHidden = !this.isHidden;
  }

}
