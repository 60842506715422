import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './pages/login/login.component';


const routes: Routes = [
  { path: '', redirectTo: environment.enableMsal==="true"? 'authentication/login':'inicio', pathMatch: 'full' },
  { path: 'authentication/login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
