import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CategorizationComponent } from './pages/categorization/categorization.component';
import { SfcComponent } from './pages/sfc/sfc.component';
import { ReportComponent } from './pages/report/report.component';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { HomeComponent } from '../../pages/home/home.component';

const categorizationRoutes: Routes = [
      { path: '',redirectTo:'inicio',pathMatch:'full'},
      { path: 'inicio', component: HomeComponent},
      {
        path: 'homologacion',
        component: CategorizationComponent,
        canDeactivate: [PendingChangesGuard],
        data: { page: 'categorization' },
      },
      { path: 'sfc', component: SfcComponent},
      { path: 'reporte', component: ReportComponent}
];

@NgModule({
  imports: [RouterModule.forChild(categorizationRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class CategorizationRoutingModule {}
