import { Component, ViewChild, Input } from '@angular/core';
import {BcCircleLoadingComponent} from '@bancolombia/design-system-web/bc-circle-loading'
@Component({
  selector: 'app-circle-loading',
  templateUrl: './circle-loading.component.html'
})
export class CircleLoadingComponent {
  @ViewChild(BcCircleLoadingComponent, { static: false }) bcCircleLoading!: BcCircleLoadingComponent;
  @Input() circleLoadingText = "Cargando...";
  circleLoadingAriaLabel = "";
  loaderSpeed = "0.5";

  /**
   * Muestra en pantalla el circlo loading.
   */
  show() {
    this.bcCircleLoading.showCircleLoading();
  }

  /**
   * Quita de la pantalla el circle loading.
   */
  hide(){
    this.bcCircleLoading.hideCircleLoading();
  }
}
