<div class="bc-container">
  <div class="bc-row">
    <bc-table-container
      class="bc-col-12"
      [dataTable]="currentPageItems"
      *ngIf="!loading && total > 0"
      componentId="containerTable"
    >
      <bc-table-header title="">
        <bc-paginator
          type="full"
          [totalRegisters]="total!.toString()"
          [perPage]="itemsPerPage!.toString() ? itemsPerPage!.toString() : '7'"
          (onChangePage)="onChangePage($event)"
          perMultiple="7"
        >
        </bc-paginator>
      </bc-table-header>
      <bc-table-content>
        <div class="table-container">
          <table bc-table aria-describedby="Lista de Datos" id="table">
            <thead>
              <tr>
                <th>
                  <div
                    #drop
                    class="menu"
                    (click)="toggleDropdown($event,drop)">
                  Campo Reporte
                  <span
                        id="campo"
                        class="bc-icon"
                        aria-hidden="true"
                        aria-label="icono"
                      >
                        arrow2-down</span
                      >
                  <app-dropdown
                        [columnOptionsFilter]="columnOptionsFilter"
                        (search)="searchFilter($event)"
                        (clear)="clearFilter($event)"></app-dropdown>
                </div>
                </th>
                <th></th>
                <th
                  *ngFor="let column of columns.slice(3, columns.length-1)"
                  bc-cell
                ><div class="center-text">{{column}}</div></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of currentPageItems; let i = index">
                <td>
                  <div class="bc-row">
                    <div class="bc-col-7">
                      <div *ngIf="detectRow(row); else camposProhibidos">
                    <a bc-link (click)="goToDetail(row)" sizeLink="default">
                    
                      <span
                      class="custom-cursor"
                    >
                      {{ row["Campo Reporte"] }}
                    </span>
                    </a>
                  </div>
                  <ng-template #camposProhibidos>   
                      <a>          
                  <span
                    class="custom-cursor bc-tooltip"
                    bc-tooltip
                    bcTooltipText="Este campo se homologa desde la sección de categorización"
                  >
                    {{ row["Campo Reporte"] }}
                  </span>
                      </a> 
                  </ng-template>

                    </div>

                  </div>
                </td>
                <td>
                  <div class="center-text">
                    <bc-icon
                      size="sm"
                      class="bc-icon bc-tooltip"
                      bc-tooltip
                      bcTooltipPosition="'top'"
                      [bcTooltipText]="row['Detalle']"
                    >
                      info
                    </bc-icon>
                  </div>
                </td>
                <td

                  *ngFor="
                    let column of columns.slice(3, columns.length-1);
                    let j = index
                  "
                  bc-cell
                >
                <div class="center-text">
                  <bc-switch
                    #element
                    class="col-12"
                    [id]="i.toString() + j.toString()"
                    [disabled]="false"
                    (changeState)="
                      onChangeState(element, row, column, row['Campo Reporte'])
                    "
                    [isChecked]="row[column]"
                  >
                  </bc-switch>
                </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </bc-table-content>
      <bc-table-footer> </bc-table-footer>
    </bc-table-container>
    <div class="loader centralize" *ngIf="loading">
      <bc-loader speedLoader="2" sizeLoader="xl"></bc-loader>
    </div>
    <div class="loader centralize" *ngIf="!loading && total == 0">
      <br>
      <app-no-data></app-no-data>
    </div>
  </div>
</div>
<app-circle-loading></app-circle-loading>
