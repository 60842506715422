import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CategorizationStoreService } from '../stores/categorizationStore/categorization-store.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements  CanActivateChild {

  constructor(
    private router: Router,
    public categorizationStoreService: CategorizationStoreService,
    private msalService: MsalService
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.isLogged()) {
      return true;
    }
    this.router.navigate(['authentication/login']);
    return false;
  }


  isLogged(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0 ? true : false;
  }
}
