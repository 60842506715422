import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFilterRequest } from '../../models/categorization/IFilterRequest';

@Injectable({
  providedIn: 'root'
})
export class CategorizationStoreService {

private data=new BehaviorSubject<{[key:number]:any}>({});
private isSearch=new BehaviorSubject<boolean>(false)
private isFilter=new BehaviorSubject<boolean>(false)
private total=new BehaviorSubject<number>(0);
private currentPage=new BehaviorSubject<number>(1);
private deleteData=new BehaviorSubject<{[key:number]:any}>({});
private previousEditData=new BehaviorSubject<{[key:number]:any}>({});
private newEditData=new BehaviorSubject<{[key:number]:any}>({});
private valuesAutocomplete=new BehaviorSubject<{[key:string]:any}>({})
private haveSearchFilter=new BehaviorSubject<boolean>(true);


private reload=new BehaviorSubject<boolean>(false);
private pagesForCancel = new BehaviorSubject<Set<number>>(new Set());
private columns=new BehaviorSubject<string[]>([]);
private loading=new BehaviorSubject<boolean>(true)
private open=new BehaviorSubject<boolean>(false)

private requestData=new BehaviorSubject<IFilterRequest>({
  page:0,
  elements:7,
  keyWord: "",
  filterObject:{
        idEntity:"",
        entityName:"",
        idProduct:"",
        product: "",
        idTypology:"",
        typology: "",
        sfcProduct:{
            sfcId:"",
            sfcDetail:"",
        },
        sfcMotive: {
            sfcId:"",
            sfcDetail:"",
        },
        sfcChannel: {
            sfcId:"",
            sfcDetail:"",
        },
        sfcDigitalProduct: {
            sfcId:"",
            sfcDetail:"",
        }
    }
})

getOpen(){
  return this.open.getValue()
}

setOpen(value: boolean): Promise<void> {
  return new Promise<void>((resolve) => {
    this.open.next(value);
    resolve();
  });
}

getLoading(){
  return this.loading
}

setLoading(value:boolean){
  this.loading.next(value)
}

//Methods for haveSearchFilter
getHaveSearchFilter(){
  return this.haveSearchFilter.getValue()
}

setHaveSearchFilter(value:boolean){
  this.haveSearchFilter.next(value)
}


//Method for valuesautocomplete
getValuesAutocomplete(){
  return this.valuesAutocomplete
}

setValuesAutocomplete(value:any){
  this.valuesAutocomplete.next(value)
}

//Method for currentPage
getCurrentPage(){
  return this.currentPage
}

setCurrentPage(value:number){
  this.currentPage.next(value)
}

// Method for isSearch
getIsSearch(){
  return this.isSearch.getValue()
}

setIsSearch(value:boolean){
  this.isSearch.next(value)
}

// Method for isFilter
getIsFilter(){
  return this.isFilter.getValue()
}

setIsFilter(value:boolean){
  this.isFilter.next(value)
}

//Methods for columns
getColumns(){
  return this.columns
}

setColumns(value:string[]){
  this.columns.next(value)
}

//Methods for pageForCancel
getPageforCancel(){
  return this.pagesForCancel
}

setPageforCancel(value:number){
  const newValue=this.pagesForCancel.getValue()
  newValue.add(value)
  this.pagesForCancel.next(newValue)
}


//Methods for reload
getReload(){
  return this.reload
}

setReload(value:boolean){
  this.reload.next(value)
}

//Methods for total
getTotal(){
  return this.total
}

setTotal(value:number){
  this.total.next(value)
}

//Methods for requestData

getRequestData(){
  return this.requestData
}

setRequestData(value:any){
  this.requestData.next(value)
}

// Methods for data
getData(){
  return this.data
}

// Se utiliza para filtros y busquedas
setAllData(value:any){
  const numberPage=value.page
  const newValue=value.filterObject
  const newData={[numberPage]:newValue};
  this.data.next(newData)
}

emptyData(){
  this.data.next({})
}

// Se utiliza para paginacion
setData(value:any){
  const numberPage=value.page
  const newValue=value.filterObject
  const tempData: any = {...this.data.getValue(), [numberPage]: newValue};
  this.data.next(tempData);
}

setDataCancel(value:any,page:number){
  const arrayTemp=this.getData().getValue()[page]
  const filterArray= arrayTemp.filter(((element:any) => element["ID"] !== value["ID"]))
  filterArray.push(value)
  filterArray.sort((a:any, b:any) => a["ID"] - b["ID"]);
  const tempData:any= {...this.data.getValue(), [page]: filterArray};
  this.data.next(tempData)
}

// Actualiza el color la data en el global
setColorData(value:any,currentPage:number){
  const categorizationElements =this.getData().getValue()[currentPage];
  const index = categorizationElements.findIndex((item:any) => {
    return item["ID"] == value["ID"]
  });
  categorizationElements[index]["Color"] = value["Color"];

  const newValue={
    "totalElements":this.getTotal().getValue(),
    "filterObject":categorizationElements,
    "page":currentPage
  }

  this.setData(newValue)
}



// Methods for previous edit data
getPreviousEditData(){
  return this.previousEditData.getValue()
}

setPreviousEditData(value:any){
  const id=value["ID"]
  const tempData: any = {...this.previousEditData.getValue(), [id]: value};
  this.previousEditData.next(tempData)
}

emptyPreviousEditData(){
  this.previousEditData.next({})
}

cancelPreviousEditData(value:any){
  const id=value["ID"]
  delete this.previousEditData.getValue()[id]
  this.previousEditData.next(this.previousEditData.getValue())
}

// Methods for new edit data
getNewEditData(){
  return this.newEditData.getValue()
}

setNewEditData(value:any){
  const id=value["ID"]
  const tempData: any = {...this.newEditData.getValue(), [id]: value};
  this.newEditData.next(tempData)
}

emptyNewEditData(){
  this.newEditData.next({})
}

cancelNewEditData(value:any){
  const id=value["ID"]
  delete this.newEditData.getValue()[id]
  this.newEditData.next(this.newEditData.getValue())
}



// Methods for delete data
getDeleteData(){
  return this.deleteData.getValue()
}

setDeleteData(newValue:any){
  const id=newValue["ID"]
  const tempData: any = {...this.deleteData.getValue(), [id]: newValue};
  this.deleteData.next(tempData)
}

emptyDeleteData(){
  this.deleteData.next({})
}

cancelDeleteData(value:any){
  const id=value["ID"]
  delete this.deleteData.getValue()[id]
  this.deleteData.next(this.deleteData.getValue())
}





}
