import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(
    private router: Router,
    private msalService: MsalService
  ) {}

  ngOnInit(){
    if(environment.enableMsal==="true"){
      setTimeout(()=>{
        if(!this.isLogged()){
          this.router.navigate(['authentication/login'])
        }
      },parseInt(environment.time))
    }


  }

  /**
   * Verifica si un usuario se ha logueado en la pagina
   * @returns True or false dependiendo si se ha logueado un usuario
   */
  isLogged(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0 ? true : false;
  }

}


