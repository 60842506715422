<article class="bc-row host">
  <section class="bc-col-1 icon-section icon-{{ options.payload?.status }}">
    <em class="bc-icon bc-st icon-color-black">{{ options.payload?.status }}</em>
  </section>
  <section class="bc-col-10">
    <div class="bc-container">
      <div class="bc-row">
        <div
          *ngIf="title"
          [class]="options.titleClass"
          [attr.aria-label]="title"
        >
          {{ title }}
        </div>
        <div
          *ngIf="message && options.enableHtml"
          role="alert"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          [class]="options.messageClass"
          [attr.aria-label]="message"
        >
          <p class="bc-opensans-font-style-2-regular">{{ message }}</p>
        </div>
      </div>
      <div
        class="bc-row"
        *ngIf="options.payload?.status == 'error' && options.payload?.code == 400"
      >
        <a (click)="toggleText()">Detalles:</a>
        <div class="bc-col-12">
          <div class="bc-container detailContainer" [hidden]="isHidden">
            <app-detail-error [data]="options.payload"></app-detail-error>
          </div>
        </div>
      </div>
      <div
        class="bc-row"
        *ngIf="options.payload?.status == 'error' && options.payload?.code == 400"
      >
        <u>Message ID:</u>&nbsp;
        <p class="bc-opensans-font-style-2-regular">
          {{ options.payload.detail.header.messageId }}
        </p>
      </div>
    </div>
  </section>
  <section class="bc-col-1">
    <button type="button" class="bc-alert-close" (click)="remove()">
      <em aria-label="Cerrar" class="bc-icon">error</em>
    </button>
  </section>
</article>

<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
