import { Component, ViewChild } from '@angular/core';
import { TransmissionService } from 'src/app/core/services/transmission/transmission.service';
import { TransmissionStoreService } from 'src/app/core/stores/transmissionStore/transmission-store.service'
import { BasePage } from '../../../../shared/components/basePage/BasePage';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { MappingService } from 'src/app/core/services/mapping/mapping.service';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-transmission',
  templateUrl: './transmission.component.html',
  styleUrls: ['./transmission.component.scss'],

})
export class TransmissionComponent extends BasePage {

  @ViewChild('modalConfirm', { static: true }) modalConfirm!: BcModalComponent;
  @ViewChild('modalCancel', { static: true }) modalCancel!: BcModalComponent;
  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;
  public crm: string;
  public crms = [
    {
      label: "SAP",
      value: "SAP"
    },
    {
      label: "SALESFORCE",
      value: "SALESFORCE"
    }
  ]
  public selectedValue: string = "";
  public searchForm!: FormGroup;

  constructor(
    public transmissionService: TransmissionService,
    public transmissionStoreService: TransmissionStoreService,
    public downloadService: DownloadService,
    public mappingService: MappingService,
    private fb:NonNullableFormBuilder
  ) {
    super(transmissionService, transmissionStoreService, 'Homologacion');
    this.crm = "SAP"
  }

  ngOnInit(){
    this.searchForm=this.fb.group({
      page: 0,
      elements: 7,
      crm:"SAP",
      keyWord: ""
    });

    this.transmissionStoreService
    .getTotal()
    .subscribe((totalElements: number) => {
      this.total = totalElements;
    });

  }

  ngOnDestroy(): void {
    this.transmissionStoreService.setTotal(0);
    this.transmissionStoreService.emptyData()
  }

  /**
   * Descarga el excel con la información de la tabla.
   */
  downloadExcel() {
    this.circleLoading.show();
    this.transmissionService.listFields(this.searchForm.value.crm).subscribe({
      next: (response: any) => {
      if (response.data) {
        this.downloadService.downloadExcelTransmission("Tabla transmisión", "Hoja 1", response.data, this.crm)
      }
      },
      error: () => { this.circleLoading.hide();},
      complete: () => { this.circleLoading.hide();this.isClicked = true;
      }

    }  )
  }

  searchTransmission(page=0){
    this.transmissionStoreService.setLoading(true)
    this.transmissionService.listFilter(this.searchForm.value.keyWord,this.searchForm.value.crm).subscribe({
      next:(response:any)=>{
      this.transmissionStoreService.setCurrentPage(1)
      this.transmissionStoreService.setFormData(this.searchForm.value)
      this.transmissionStoreService.setIsSearch(true)
      this.transmissionStoreService.setAllData(response)
      this.transmissionStoreService.setTotal(response.total)
      this.transmissionStoreService.setLoading(false)
    }})

  }
  
  clearTransmission(){
    this.transmissionStoreService.setLoading(true)
    this.searchForm.reset()
    Object.keys(this.searchForm.controls).forEach(key =>{
      this.searchForm.controls[key].setErrors(null)
    });
    this.transmissionService.listFields(this.searchForm.value.crm).subscribe({
      next:(response:any)=>{
        this.transmissionStoreService.setCurrentPage(1)
        this.transmissionStoreService.setAllData(response)
        this.transmissionStoreService.setTotal(response.data.length);
        this.transmissionStoreService.setDefaultFormData()
        this.transmissionStoreService.setIsSearch(false)
        this.transmissionStoreService.setLoading(false)
      }
  })

  }

  switchCrm(evento:any){
    if(this.crm != evento){
      this.crm = evento
      this.circleLoading.show();
      this.searchForm.setValue({
        page: 0,
        elements: 7,
        crm:evento,
        keyWord: ""
      })
      
    }
    this.circleLoading.hide();
  }
}
