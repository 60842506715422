<div class="bc-container">
  <div class="bc-row">
    <div
      class="bc-col-12"
      componentId="containerTable"
      *ngIf="!loading && total > 0"
    >
      <bc-table-header title="">
        <div class="right-align">
            <bc-paginator
            type="full"
            [totalRegisters]="this.total!.toString()"
            [perPage]="itemsPerPage!.toString() ? itemsPerPage!.toString() : '7'"
            (onChangePage)="onChangePage($event)"
            perMultiple="7"
          >
          </bc-paginator>
        </div>
      </bc-table-header>
      <br />
      <br />
      <bc-table-content>
        <div class="table-container">
          <table
        aria-describedby="Lista de Datos"
        id="table">
          <thead>
            <tr>
              <th
                class="center-text"
                *ngFor="let column of columns.slice(0, columns.length )"
                bc-cell
              >
                {{column}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of currentPageItems; let i = index">
              <td
                *ngFor="let column of columns"
                bc-cell
              >
                <input
                  class="bc-margin-top-1 center-text no-border"
                  [value]="row[column]"
                  [readonly]="true"
                />
              </td>
            </tr>
          </tbody>
        </table>
        </div>

      </bc-table-content>
    </div>
      <div class="loader centralize" *ngIf="loading">
        <bc-loader speedLoader="2" sizeLoader="xl"></bc-loader>
      </div>

      <div class="loader centralize bc-col-12" *ngIf="!loading && total == 0">
        <app-no-data></app-no-data>
      </div>
  </div>
</div>

<app-circle-loading></app-circle-loading>
