import { Injectable } from '@angular/core';
import { colors } from 'src/app/shared/constants/colors';
import { ICategorizationEntities, ICategorizationProducts, ICategorizationTypologies } from '../../models/categorization/ICategorizationNew';
import { INewTypology } from '../../models/categorization/INewTypology';
import { ITransmissionExcel } from '../../models/categorization/ITransmissionExcel';
import { DatePipe } from '@angular/common';
import { IObjectListHistory } from '../../models/history/history';
import {  ICreateHomologation, ITransmissionFields } from '../../models/transmission/ITransmission';


@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor(
    public datepipe: DatePipe
  ) { }

  transmissionDetailEditFtoB(data:any[]){
    return data.map((item:any)=>({
      hmlCod: item["ID"],
      sfcHRefCod: item["Codigo hrefSFC"],
      sfcValue: item["Código SFC"],
      sfcDetail: item["Nombre SFC"],
    }))
  }

  /**
* Modo: Front to Back
* @param data data con informacion de las nuevas homologaciones
* Consulta: Organiza la data para la creacion de nuevas homologaciones
*/
  categorizationSaveFtoB(data: any[]) {
    return data.map((item: any) => ({
      idOrTypology: item["ID"],
      idOrProduct: item["ID Producto"],
      idOrEntity: null,
      idEntity: item["ID Entidad"],
      entityName: item["Nombre Entidad"],
      idProduct: item["ID Producto"],
      product: item["Producto"],
      idTypology: item["ID Tipología"],
      typology: item["Tipología"],
      sfcProduct: this.mapSfcData(item, "Código Producto SFC", "productHmlRefId", "productHmlId"),
      sfcMotive: this.mapSfcData(item, "Código Motivo SFC", "motiveHmlRefId", "motiveHmlId"),
      sfcChannel: this.mapSfcData(item, "Código Canal", "channelHmlRefId", "channelHmlId"),
      sfcDigitalProduct: this.mapSfcData(item, "Código Producto Digital", "digitalHmlRefId", "digitalHmlId")
    }));
  }

  /**
  * Modo: Front to Back
  * Consulta: Organiza la data para los campos de la sfc
  */
  mapSfcData(item: any, sfcIdKey: string, hmlRefIdKey: string, hmlIdKey: string) {
    return {
      sfcId: item[sfcIdKey],
      sfcDetail: item[`Detalle ${sfcIdKey.split(' ')[1]}`],
      hmlRefId: item[hmlRefIdKey],
      hmlId: item[hmlIdKey]
    };
  }

  /**
  * Modo: Back to Front
  *
  * Consulta: Obtener los detalles de los cambios efectuados en categorizacion Homologacion
  */
  historyCategorizationBtoF(item: IObjectListHistory) {
    return {
      "Fecha y hora": this.datepipe.transform(item.date, "dd/MM/yyyy h:mm:ss a"),
      "Usuario": item.user,
      "Operación": item.operation,
      "ID Entidad": item.tableDTO.idEntity,
      "Nombre Entidad": item.tableDTO.entityName,
      "ID Producto": item.tableDTO.idProduct,
      "Producto": item.tableDTO.product,
      "ID Tipología": item.tableDTO.idTypology,
      "Tipología": item.tableDTO.typology,
      "Código Producto SFC": item.tableDTO.sfcProduct.sfcId,
      "Detalle Producto": item.tableDTO.sfcProduct.sfcDetail,
      "Código Motivo SFC": item.tableDTO.sfcMotive.sfcId,
      "Detalle Motivo": item.tableDTO.sfcMotive.sfcDetail,
      "Código Canal": item.tableDTO.sfcChannel.sfcId,
      "Detalle Canal": item.tableDTO.sfcChannel.sfcDetail,
      "Código Producto Digital": item.tableDTO.sfcDigitalProduct.sfcId,
      "Producto Digital": item.tableDTO.sfcDigitalProduct.sfcDetail,
    }
  }

  historyTransmisionBtoF(item:  any) {
    return {
      "Fecha y hora": this.datepipe.transform(item.date, "dd/MM/yyyy h:mm:ss a"),
      "Usuario": item.user,
      "Operación": item.operation,
      "Momento":item.tableDTO.moment,
      "Campo":item.tableDTO.fieldName,
      "Código SFC": item.tableDTO.VDetailTransmissionSummaryDTO[0].sfcValue,
      "Nombre SFC": item.tableDTO.VDetailTransmissionSummaryDTO[0].sfcDetail,
      "Código CRM": item.tableDTO.VDetailTransmissionSummaryDTO[0].crmValue,
      "Nombre CRM": item.tableDTO.VDetailTransmissionSummaryDTO[0].crmDetail
    }
  }

  historyDetailBtoF(item: any) {
    return {
      "Fecha y hora": this.datepipe.transform(item.date, "dd/MM/yyyy h:mm:ss a"),
      "Usuario": item.user,
      "Operación": item.operation,
      "Campo Reporte": item.tableDTO.name,
      "Momento 1": item.tableDTO.moment1Aqr,
      "Momento 2": item.tableDTO.moment2Aqr,
      "Momento 3": item.tableDTO.moment3Aqr,

    }
  }

  transmissionDataBtoF(item:ITransmissionFields){
      return {
      "Cod":item.fieldCod,
      "Campo Reporte": item.name,
      "Detalle":item.detail,
      "Momento 1": item.moment1Aqr,
      "Momento 2": item.moment2Aqr,
      "Momento 3": item.moment3Aqr,
      "all": item
      }
    }
  
  




  /**
  * Modo: Back to Front
  *
  * Consulta: Obtener los detalles de los errores
  */
  categorizationDetailError(originalDictionary: any) {
    const dictionary: { [key: string]: string } = {
      "Id SfcProduct": "Código Producto SFC",
      "Detail SfcProduct": "Detalle Producto",
      "Id SfcMotive": "Código Motivo SFC",
      "Detail SfcMotive": "Detalle Motivo",
      "Id SfcChannel": "Código Canal",
      "Detail SfcChannel": "Detalle Canal",
      "Id SfDigitalProduct": "Código Producto Digital",
      "Detail SfDigitalProduct": "Producto Digital",
      "Object IdOrTypology": "ID Tipología",
      "Id IdOrTypology": "ID Tipología",
      "Detail IdOrTypology": "Tipología",
      "Object IdOrProduct": "ID Producto",
      "Id  IdOrProduct": "ID Producto",
      "Detail IdOrProduct": "Producto",
      "Object Sfc Value": "Código SFC",
      "Object Sfc Detail":"Nombre SFC"
    }

    const finalDictionary: { [key: string]: string[] } = {};

    for (const key in originalDictionary) {
      if (dictionary[key]) {
        finalDictionary[dictionary[key]] = originalDictionary[key];
      }
    }
    return finalDictionary
  }
  /**
  * Modo: Front a Front
  *
  * Consulta: Obtener valores para actualizar columna individual
  */
  categorizationUpdateFtoF(column: string) {
    const dictionary: { [key: string]: string[] } = {
      "product": ["Código Producto SFC", "Detalle Producto", "productHmlRefId", "productId"],
      "motive": ["Código Motivo SFC", "Detalle Motivo", "motiveHmlRefId", "motiveId"],
      "channel": ["Código Canal", "Detalle Canal", "channelHmlRefId", "channelId"],
      "digital": ["Código Producto Digital", "Producto Digital", "digitalHmlRefId", "digitalId"],
    }
    return dictionary[column]
  }

  /**
  * Modo: Back a Front
  *
  * Consulta: Optener los valores unicos por columna
  */
  categorizationUniqueBtoF(valor: any, column: string) {
    const dictionary: { [key: number]: any[] } = {};

    for (const item of valor) {
      const {
        hmlRefCod,
        detail,
        value,
        field,
        channel
      } = item;

      dictionary[value] = [hmlRefCod, detail, value, field.toString(), channel.toString()];
    }
    return { [column]: dictionary }
  }



  /**
  * Modo: Front a Back
  *
  * Consulta: Nombre de columnas unicas
  */
  categorizationFilterFtoB(column: any) {
    const dictionary: any = {
      "Nombre Entidad": "entityName",
      "ID Entidad": "idEntity",
      "Producto": "product",
      "ID Producto": "idProduct",
      "ID Tipología": "idTypology",
      "Tipología": "typology",
      "Código Producto SFC": "productIdSfc",
      "Detalle Producto": "productDetailSfc",
      "Código Motivo SFC": "motiveIdSfc",
      "Detalle Motivo": "motiveDetailSfc",
      "Código Canal": "channelIdSfc",
      "Detalle Canal": "channelDetailSfc",
      "Código Producto Digital": "digitalIdSfc",
      "Producto Digital": "digitalDetailSfc"
    }
    return dictionary[column]
  }

  /**
  * Modo: Back a Front
  *
  * Consulta: Informacion de las homologaciones
  */
  categorizationDataBtoF(data: any) {
    const arrayData = data.objectList
    const res = arrayData.map((obj: any) => {
      return {
        "ID Entidad": obj.idEntity,
        "Nombre Entidad": obj.entityName,
        "ID Producto": obj.idProduct,
        "Producto": obj.product,
        "ID Tipología": obj.idTypology,
        "Tipología": obj.typology,
        "Código Producto SFC": obj.sfcProduct.sfcId,
        "Detalle Producto": obj.sfcProduct.sfcDetail,
        "Código Motivo SFC": obj.sfcMotive.sfcId,
        "Detalle Motivo": obj.sfcMotive.sfcDetail,
        "Código Canal": obj.sfcChannel.sfcId,
        "Detalle Canal": obj.sfcChannel.sfcDetail,
        "Código Producto Digital": obj.sfcDigitalProduct.sfcId,
        "Producto Digital": obj.sfcDigitalProduct.sfcDetail,
        "ID": obj.idOrTypology,
        "productHmlRefId": obj.sfcProduct.hmlRefId,
        "productHmlId": obj.sfcProduct.hmlId,
        "motiveHmlRefId": obj.sfcMotive.hmlRefId,
        "motiveHmlId": obj.sfcMotive.hmlId,
        "channelHmlRefId": obj.sfcChannel.hmlRefId,
        "channelHmlId": obj.sfcChannel.hmlId,
        "digitalHmlRefId": obj.sfcDigitalProduct.hmlRefId,
        "digitalHmlId": obj.sfcDigitalProduct.hmlId,
        "Color": colors["white"],
        "Editando": false
      }
    });
    return { ...data, "filterObject": res }
  }

  /**
 * Modo: Front a Back
 *
 * Consulta: Busqueda por filtro
 */
  categorizationColumnFtoB(column: string) {
    const mappingDict: any = {
      "ID Entidad": "idEntity",
      "Nombre Entidad": "entityName",
      "ID Producto": "idProduct",
      "Producto": "product",
      "ID Tipología": "idTypology",
      "Tipología": "typology",
      "Código Producto SFC": "sfcProduct.sfcId",
      "Detalle Producto": "sfcProduct.sfcDetail",
      "Código Motivo SFC": "sfcMotive.sfcId",
      "Detalle Motivo": "sfcMotive.sfcDetail",
      "Código Canal": "sfcChannel.sfcId",
      "Detalle Canal": "sfcChannel.sfcDetail",
      "Código Producto Digital": "sfcDigitalProduct.sfcId",
      "Producto Digital": "sfcDigitalProduct.sfcDetail"
    }

    return mappingDict[column].split(".")
  }


  /**
  * Modo: Back a Front
  *
  * Consulta: Reorganiza la lista de entidades.
  */
  categorizationDataEntities(data: any) {
    return data.map((obj: ICategorizationEntities) => {
      return {
        label: obj.detail,
        value: obj.hmlRefCod,
        ref: obj.value
      }
    });
  }

  /**
* Modo: Back a Front
*
* Consulta: Reorganiza la lista de productos.
*/
  categorizationDataProducts(data: any) {
    return data.map((obj: ICategorizationProducts) => {
      return {
        label: obj.detail,
        value: obj.productCod,
        ref: obj.value
      }
    });
  }

  /**
* Consulta: Retorna el valor del objeto.
*/
  categorizationDataTypologies(data: any) {
    return data.map((obj: ICategorizationTypologies | ICategorizationProducts) => obj.value);
  }

  /**
* Consulta: Organiza la data para general el excel de Homologaciones.
*/
  finalValidationTable(data: any) {
    return data.map((obj: INewTypology) =>
      [obj.idEntity, obj.entityName, obj.idProduct, obj.product, obj.idTypology, obj.typology,
      obj.sfcProduct.sfcId, obj.sfcProduct.sfcDetail, obj.sfcMotive.sfcId, obj.sfcMotive.sfcDetail,
      this.replaceNoData(obj.sfcChannel.sfcId), this.replaceNoData(obj.sfcChannel.sfcDetail), obj.sfcDigitalProduct.sfcId, obj.sfcDigitalProduct.sfcDetail]
    );
  }

  /**
* Consulta: Reemplaza los campos null vacio y NoDataSSv por "".
*/
  replaceNoData(data: string) {
    if (data == "null vacio" || data == "NoDataSSv") {
      return ""
    }
    else {
      return data
    }
  }

  /**
* Modo: Back a Front
*
* Consulta: Reeorganiza los datos de la tabla de transmision.
*/
  transmissionExcel(data: any) {
    return data.map((obj: ITransmissionExcel) => {
      return {
        name: obj.name,
        moment1Aqr: obj.moment1Aqr,
        moment2Aqr: obj.moment2Aqr,
        moment3Aqr: obj.moment3Aqr
      }
    }
    );
  }



    /**
* Modo: Back a Front
*
* Consulta: Reeorganiza los datos de la tabla de transmision-detalle dependiendo el momento seleccionado.
*/
  transmissionDetail(item: any, momento:number) {
      if (momento==1) {
        return {
          "ID":item.hmlCod,
          "Código SFC": item.sfcValue,
          "Nombre SFC": item.sfcDetail,
          "Código CRM": item.crmValue,
          "Nombre CRM": item.crmDetail,
          "Codigo hrefCRM": item.crmHRefCod,
          "Codigo hrefSFC": item.sfcHRefCod,
          "Color": colors["white"],
          "Editando": false
        };
      }else {
        return {
          "ID":item.hmlCod,
          "Código CRM": item.crmValue,
          "Nombre CRM": item.crmDetail,
          "Código SFC": item.sfcValue,
          "Nombre SFC": item.sfcDetail,
          "Codigo hrefCRM": item.crmHRefCod,
          "Codigo hrefSFC": item.sfcHRefCod,
          "Color": colors["white"],
          "Editando": false
        }
      }
  }




  transmissionDetailColumnNames(column: string) {
    const columnMappings: {[key: string]: string} = {
      "Código SFC": "sfcCode",
      "Nombre SFC": "sfcName",
      "Código CRM": "crmCode",
      "Nombre CRM": "crmName"
    };

    return columnMappings[column];
  }


   transmissionColumnFtoB(column: string) {
     const mappingDict: {[key: string]: string} = {
      "Código SFC": "sfcValue",
      "Nombre SFC": "sfcDetail",
      "Código CRM": "crmValue",
      "Nombre CRM": "crmDetail"
     }

     return mappingDict[column].split(".")
   }


   newHomologationTransmissionFtoB(input : { [key: string]: string } ): ICreateHomologation{
    return {
      hmlCod: null,
      crmHRefCod: null,
      crmValue: input["Código CRM"],
      crmDetail: input["Nombre CRM"],
      sfcHRefCod: null,
      sfcValue: input["Código SFC"],
      sfcDetail: input["Nombre SFC"]
  };

   }

   changeStateTransmissionSwitch(element: ITransmissionFields , crmType:string){ 
      return{
        fieldCod:element.fieldCod,
        name:element.name,
        detail:element.detail,
        typeAqrIn:element.typeAqrIn,
        typeSf:element.typeSf,
        typeAqrOut:element.typeAqrOut,
        moment1Aqr: element.moment1Aqr,
        moment2Aqr: element.moment2Aqr,
        moment3Aqr: element.moment3Aqr,
        crmType: crmType
      }
   }
}
