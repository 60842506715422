import { Component, ViewChild} from '@angular/core';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';
import { link_tablas_homologacion,link_web_service,link_portal_sfc } from "src/environments/environment";

@Component({
  selector: 'app-sfc',
  templateUrl: './sfc.component.html',
  styleUrls: ['./sfc.component.scss']
})
export class SfcComponent {

  @ViewChild(CircleLoadingComponent) CircleLoading!: CircleLoadingComponent;
  linkHomologaciones:string=link_tablas_homologacion
  linkWebService:string=link_web_service
  linkPortalSfc:string=link_portal_sfc
}
