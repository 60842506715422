import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';


@Injectable()
export class HeadersService implements HttpInterceptor {
  /**
   * Intercepta todas las request y les coloca el header correspondiente.
   */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers: any = {
      'Strict-Transport-Security': 'max-age= 63072000; includeSubdomains; preload',
      'Content-Security-Policy': 'default-src "self" "unsafe-inline" "unsafe-eval" *',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'X-XSS-Protection': '1; mode=block',
      'Referrer-Policy': 'same-origin',
      'X-Permitted-Cross-Domain-Policies': 'master-only',
      'Cache-Control': 'no-cache; must-revalidate; pre-check= 0; post-check= 0; max-age= 0; s-maxage= 0; no-store',
      Server: 'tpc',
      Pragma: 'no-cache',
      Expires: '0',
      'Access-Control-Allow-Origin': environment.api
    };

    if (request.url !== 'https://api.ipify.org/?format=json') {
      const customReq = request.clone({
        setHeaders: headers
      });
      return next.handle(customReq);
    }
    return next.handle(request);
  }
}
