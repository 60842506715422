import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { CategorizationRoutingModule } from './categorization-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import {CategorizationTableComponent} from './components/categorization-table/categorization-table.component';
import { CategorizationComponent } from './pages/categorization/categorization.component';
import { SfcComponent } from './pages/sfc/sfc.component';
import { ReportComponent } from './pages/report/report.component';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { BcCircleLoadingModule } from '@bancolombia/design-system-web/bc-circle-loading';

@NgModule({
  declarations: [
    CategorizationTableComponent,
    CategorizationComponent,
    SfcComponent,
    ReportComponent,
  ],
  imports: [
    CommonModule,
    CategorizationRoutingModule,
    SharedModule,
    BcCircleLoadingModule
  ],
  providers:[
    PendingChangesGuard
  ]
})
export class CategorizationModule {
}
