<app-header></app-header>
<bc-menu class="menu-app-showcase" [logoutFunction]="logout.bind(this)" state="close" >
  <bc-menu-drawer>
    <bc-menu-item *ngFor="let item of items" [routerLink]="[item.path]" routerLinkActive="bc-menu-item-active"
      [identifierContent]="item.identifier" [text]="item.text" [iconName]="item.icon">
    </bc-menu-item>
  </bc-menu-drawer>
  <bc-menu-main>
    <bc-menu-body identifier="id6"></bc-menu-body>
  </bc-menu-main>
  <div class="bc-content-body">
    <div class="bc-container bc-my-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</bc-menu>