<div class="bc-container">
  <div class="bc-row">
    <div
      class="bc-col-12"
      componentId="containerTable"
      *ngIf="!loading && total > 0"
    >
      <bc-table-header title="">
        <div class="right-align">
          <bc-paginator
            type="full"
            [totalRegisters]="this.total.toString()"
            [perPage]="
              itemsPerPage!.toString() ? itemsPerPage!.toString() : '7'
            "
            (onChangePage)="onChangePage($event)"
            perMultiple="7"
          >
          </bc-paginator>
        </div>
      </bc-table-header>
      <br />
      <bc-table-content>
        <div class="table-container">
          <table aria-describedby="Lista de Datos" id="tabla categorizacion">
            <thead>
              <tr>
                <th bc-cell>Operaciones</th>
                <th
                  class="center-text"
                  *ngFor="let column of columns.slice(0, columns.length - 11)"
                  bc-cell
                >
                  <div
                    #drop
                    class="menu"
                    (click)="toggleDropdown($event, drop, column)"
                  >
                    {{ column }}
                    <span
                      class="bc-icon"
                      id="{{ column }}"
                      aria-hidden="true"
                      aria-label="icono"
                    >
                      arrow2-down</span
                    >
                    <app-dropdown
                        [columnOptionsFilter]="columnOptionsFilter[column]"
                        (search)="searchFilter($event,column,0)"
                        (clear)="clearFilter($event,column,1)"></app-dropdown>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of currentPageItems; let i = index">
                <td bc-cell [ngStyle]="getRowStyle(row, 'exist',editColumns)">
                  <div class="bc-row">
                    <div class="bc-col-3">
                      <bc-icon-button
                        (click)="editRow(row)"
                        type="ghost"
                        size="medium"
                        customIcon="edit"
                      >
                      </bc-icon-button>
                    </div>
                    <div class="bc-col-3">
                      <bc-icon-button
                        (click)="deleteRow(row)"
                        type="ghost"
                        size="medium"
                        customIcon="erase"
                      >
                      </bc-icon-button>
                    </div>
                    <div class="bc-col-3">
                      <bc-icon-button
                        (click)="cancelRow(row)"
                        type="ghost"
                        size="medium"
                        customIcon="error"
                      >
                      </bc-icon-button>
                    </div>
                  </div>
                </td>
                <td
                  *ngFor="let column of columns.slice(0, 6)"
                  bc-cell
                  [ngStyle]="getRowStyle(row, column,editColumns)"
                >
                  <input
                    class="bc-margin-top-1 center-text no-border"
                    [ngStyle]="getRowStyle(row, column,editColumns)"
                    [value]="row[column]"
                    [readonly]="true"
                  />
                </td>
                <ng-container *ngFor="let option of tableOptions">
                  <td bc-cell [ngStyle]="getRowStyle(row, option[0],editColumns)">
                    <input
                      class="bc-margin-top-1 center-text no-border"
                      [ngStyle]="getRowStyle(row, option[0],editColumns)"
                      [value]="row[option[0]]"
                      [readonly]="!row.Editando"
                      [type]="option[1] == 'channel' ? 'text' : 'number'"
                      (input)="updateValue($event, row, option[1], true)"
                      [attr.list]="option[2]"
                    />
                    <datalist [id]="option[2]">
                      <option
                        *ngFor="
                          let option of valuesList[option[1]]
                            | keyvalue : keyAscOrder
                        "
                        [value]="option.key"
                      ></option>
                    </datalist>
                  </td>
                  <td bc-cell [ngStyle]="getRowStyle(row, option[4],editColumns)">
                    <input
                      [id]="option[3] + row['ID']"
                      class="bc-margin-top-1 center-text no-border"
                      [ngStyle]="getRowStyle(row, option[4],editColumns)"
                      [value]="row[option[4]]"
                      [readonly]="true"
                      (input)="updateValue($event, row, option[1], false)"
                    />
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </bc-table-content>
    </div>
    <div class="loader centralize" *ngIf="loading">
      <bc-loader speedLoader="2" sizeLoader="xl"></bc-loader>
    </div>
    <div class="loader centralize" *ngIf="!loading && total == 0">
      <app-no-data></app-no-data>
    </div>
  </div>
</div>
<app-circle-loading></app-circle-loading>
