import { Component, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  iframeWidth: number = innerWidth - (innerWidth*parseFloat("0.2"))  ;
  iframeHeight: number = innerHeight- parseInt("120") ;
  powerBi = this.sanitizer.bypassSecurityTrustResourceUrl(environment.powerBi) ;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener('resize', () => {
      this.calculateIframeSize();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // Recalcula el tamaño del iframe cuando la ventana se redimensiona
    this.calculateIframeSize();
  }

  calculateIframeSize() {
    // Asigna el ancho y alto de la ventana al iframe
    this.iframeWidth = window.innerWidth - (innerWidth*parseFloat("0.2"));
    this.iframeHeight = window.innerHeight - parseInt("120");
  }
}
