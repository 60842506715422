import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-box',
  templateUrl: './total-box.component.html',
  styleUrls: ['./total-box.component.scss']
})
export class TotalBoxComponent {


  @Input()
  total: number = 0;

  public totalMap = [{
    '=0' : 'No se encontraron ',
    '=1' : 'Se encontró ',
    'other' : 'Se encontraron ',
   },
   {
    '=0': 'resultados',
    '=1' : 'registro',
    'other' : 'registros',
    },
   ]
}
