import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { CategorizationService } from 'src/app/core/services/categorization/categorization.service';
import { CategorizationStoreService } from 'src/app/core/stores/categorizationStore/categorization-store.service';
import { BasePage } from '../../../../shared/components/basePage/BasePage';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CustomValidator,
  validateColumn,
} from 'src/app/shared/validators/CustomValidators';
import { ISaveRequest } from 'src/app/core/models/categorization/ISaveRequest';
import { MappingService } from 'src/app/core/services/mapping/mapping.service';
import { INewTypology } from 'src/app/core/models/categorization/INewTypology';
import { ToastrService } from 'ngx-toastr';
import {
  defaultNewTypology,
  defaultValuesForm,
  newProductDefault,
} from 'src/app/shared/constants/categorization';
import Swal from 'sweetalert2';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';
import { IDownloadExcelData } from 'src/app/core/models/categorization/ICategorizationTable';

export interface IFields {
  label: string;
  value: number;
  ref: string;
}

export interface IProducts {
  label: string;
  value: number | null;
  ref: string;
}

@Component({
  selector: 'app-categorization',
  templateUrl: './categorization.component.html',
  styleUrls: ['./categorization.component.scss'],
})
export class CategorizationComponent
  extends BasePage
  implements OnInit,OnDestroy {

  @ViewChild('modalConfirm', { static: true }) modalConfirm!: BcModalComponent;
  @ViewChild('modalConfirmHomologation', { static: true })
  modalConfirmHomologation!: BcModalComponent;
  @ViewChild('modalCancel', { static: true }) modalCancel!: BcModalComponent;
  @ViewChild('modalNew', { static: true }) modalNew!: BcModalComponent;
  @ViewChild('modalChannel', { static: true }) modalChannel!: BcModalComponent;
  @ViewChild('modalNewField', { static: true })
  modalNewField!: BcModalComponent;
  @ViewChild('modalContinueNew', { static: true })
  modalContinueNew!: BcModalComponent;
  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;

  public excelData: IDownloadExcelData[] = [];
  public dataNewField: { [key: string]: Set<any> } = {
    producto: new Set(),
    motivo: new Set(),
    canal: new Set(),
    digital: new Set(),
  };
  public checkdataNewField: { [key: string]: Set<number> } = {
    producto: new Set(),
    motivo: new Set(),
    canal: new Set(),
    digital: new Set(),
  };
  public filterText: string = '';
  public newHomologationForm!: FormGroup;
  public productSFCDisable: boolean | null = null;
  public motiveSFCDisable: boolean | null = null;
  public channelSFCDisable: boolean | null = true;
  public digitalProductSFCDisable: boolean | null = null;
  public newProduct: boolean = false;
  public tableValue: string[] = [];
  public entities: IFields[] = [];
  public saveData: ISaveRequest = {
    dataToDelete: [],
    previousDataToUpdate: [],
    newDataToUpdate: []
  };
  public morePopUps: boolean = false;
  public numberElementsEdit: number = 0;
  public numberElementsDelete: number = 0;
  public digitalProductSFC: IProducts[] = [];
  public motiveFields: IProducts[] = [];
  public channelFields: IFields[] = [];
  public completechannelFields: IFields[] = [];
  public digitalFields: IFields[] = [];
  public products: IProducts[] = newProductDefault;
  public typologies = [];
  public productsToValidate = [];
  public createTypologies: INewTypology = defaultNewTypology;
  public listOptions = ['product', 'channel', 'motive', 'digital'];
  public haveNewField = false;
  public disabledProduct: boolean = false;
  public crm:string;
  public crms = [
    {
      label: "SAP",
      value: "SAP"
    },
    {
      label: "SALESFORCE",
      value: "SALESFORCE"
    }
  ]
  constructor(
    private mappingService: MappingService,
    public categorizationService: CategorizationService,
    public downloadService: DownloadService,
    public categorizationStoreService: CategorizationStoreService,
    private formBuilder: FormBuilder,
    public toastr: ToastrService,
  ) {
    super(categorizationService, categorizationStoreService, 'Homologacion');
    this.crm = "SAP"
  }

  ngOnDestroy(): void {
    this.categorizationStoreService.setTotal(0);
    this.categorizationStoreService.emptyData()
  }


  ngOnInit(): void {

    this.newHomologationForm = this.formBuilder.group({
      entity: [''],
      product: [''],
      idproduct: ['', [Validators.required, CustomValidator.numeric]],
      productName: ['', Validators.required],
      idtypology: ['', [Validators.required, CustomValidator.numeric]],
      typologyName: ['', Validators.required],
      productCode: ['', [Validators.required, CustomValidator.numeric]],
      productDetail: ['', Validators.required],
      motiveCode: ['', [Validators.required, CustomValidator.numeric]],
      motiveDetail: ['', Validators.required],
      channelCode: ['', [CustomValidator.numeric]],
      channelDetail: [''],
      digitalProduct: ['', Validators.required],
      digitalProductCode: ['', [Validators.required, CustomValidator.numeric]],
    });

    this.categorizationStoreService
      .getTotal()
      .subscribe((totalElements: number) => {
        this.total = totalElements;
      });
  }

  /**
   * @ignore
   */
  resetDataNewField() {
    this.dataNewField = {
      producto: new Set(),
      motivo: new Set(),
      canal: new Set(),
      digital: new Set(),
    };
    this.checkdataNewField = {
      producto: new Set(),
      motivo: new Set(),
      canal: new Set(),
      digital: new Set(),
    };
  }

  openModalOperations(event: any) {
    this.modalNewField.shutDown();
    if (event) {
      this.openModalSave(this.modalConfirm, 0);
    } else {
      this.resetDataNewField();
    }
  }

  /**
   * @ignore
   */
  addNewField(column1: string, column2: string, field: string, valueEdit: any) {
    this.haveNewField = true;
    const newProduct = {
      [column1]: valueEdit[column1],
      [column2]: valueEdit[column2],
    };
    if (!this.checkdataNewField[field].has(valueEdit[column1])) {
      this.checkdataNewField[field].add(valueEdit[column1]);
      this.dataNewField[field].add(newProduct);
    }
  }

  /**
   * @ignore
   */
  openModalNewField() {
    this.haveNewField = false
    const keysEditData = Object.keys(
      this.categorizationStoreService.getNewEditData()
    );
    const editData = this.categorizationStoreService.getNewEditData();
    keysEditData.forEach((ele: string) => {
      const valueEdit = editData[parseInt(ele)];
      if (valueEdit.productHmlRefId == null) {
        this.addNewField("Código Producto SFC", "Detalle Producto", "producto", valueEdit)
      }
      if (valueEdit.motiveHmlRefId == null) {
        this.addNewField("Código Motivo SFC", "Detalle Motivo", "motivo", valueEdit)
      }
      if (valueEdit.channelHmlRefId == null && valueEdit['Código Canal'] == "" && valueEdit['Detalle Canal'] == "") {
        valueEdit['Código Canal'] = "NoDataSSv"
        valueEdit['Detalle Canal'] = "null vacio"
        valueEdit.channelHmlRefId = this.categorizationStoreService.getValuesAutocomplete().getValue()["channel"]["NoDataSSv"][0]
      }
      if (valueEdit.channelHmlRefId == null) {
        this.addNewField("Código Canal", "Detalle Canal", "canal", valueEdit)
      }
      if (valueEdit.digitalHmlRefId == null) {
        this.addNewField("Código Producto Digital", "Producto Digital", "digital", valueEdit)
      }
    });

    if (this.haveNewField) {
      this.modalNewField.openModal();
    } else {
      this.openModalSave(this.modalConfirm, 0);
    }

  }

  /**
   * @ignore
   */
  canDeactivate() {
    const elementsToDelete = this.categorizationStoreService.getDeleteData();
    const elementsToEdit = this.categorizationStoreService.getPreviousEditData();
    if (
      Object.keys(elementsToDelete).length == 0 &&
      Object.keys(elementsToEdit).length == 0
    ) {
      return true;
    }
    return false;
  }

  /**
   * Permite generar y descargar un archivo de Excel con información de todas las homologaciones.
   */
  downloadExcel() {
    this.circleLoading.show();
    this.categorizationService.list(this.crm).subscribe({
      next: (data: any) => {
        if (data) {
          this.downloadService.downloadExcelCategorization(
            'Tabla homologación',
            'Produ-mot-canal-diga',
            data,
            this.crm
          );
        }
      },
      error: () => { this.circleLoading.hide(); },
      complete: () => { this.circleLoading.hide(); this.isClicked = true; }
    });

  }

  /**
   * Permite cancelar las ediciones o eliminaciones que ha realizado el usuario.
   * @param event Indica si se cancela lo operacion o se continua (1 o 0).
   */
  cancelDeleteorEdit(event: any) {
    if (event == 1) {
      this.categorizationStoreService.emptyPreviousEditData();
      this.categorizationStoreService.emptyNewEditData();
      this.categorizationStoreService.emptyDeleteData();
      this.categorizationStoreService.emptyData();
      this.categorizationStoreService.setCurrentPage(1);
      this.categorizationStoreService.setTotal(0);
      this.categorizationStoreService.setReload(true);
      this.categorizationService.removeSymbolFilter();
      this.modalCancel.shutDown();
    } else {
      this.modalCancel.shutDown();
    }
  }


  alertMessage(message: string, payload: any) {
    this.toastr.show(message, '', {
      tapToDismiss: false,
      closeButton: true,
      progressBar: true,
      timeOut: 12000,
      extendedTimeOut: 10000,
      toastClass: 'toast-icon',
      payload: payload,
      positionClass: 'toast-top-center',
    });
  }

  /**
   * Permite aceptar/rechazar la edicion/eliminación de homologaciones.
   * @param event Indica si se cancela lo operacion o se continua (1 o 0).
   */
  onAcceptDeleteEdit(event: number) {
    if (event) {
      this.createRequestEditDelete();
      this.modalConfirm.shutDown();
      if (!this.morePopUps) {
        this.circleLoading.show();
        this.categorizationService.save(this.saveData,this.crm).subscribe({
          next: (data: any) => {
            this.postResponseEditDelete(data);
          },
          error: () => { this.circleLoading.hide() },
          complete: () => { this.circleLoading.hide() }
        },

        );
      } else {
        this.openModalSave(this.modalConfirm, 1);
      }
    } else {
      if (this.saveData.dataToDelete.length != 0) {
        this.modalConfirm.shutDown();
        Swal.fire({
          title:
            "<p style='color:black'>Los datos de actualizar no han sido guardados, esta seguro de continuar</p>",
          position: 'top',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          allowOutsideClick: false,
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'customButton customButton-primary',
            cancelButton: 'customButton customButton-secondary',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.circleLoading.show();
            this.categorizationService.save(this.saveData,this.crm).subscribe({
              next: (data: any) => {
                this.postResponseEditDelete(data);
              },
              error: () => { this.circleLoading.hide() },
              complete: () => { this.circleLoading.hide() }
            });
          } else {
            this.modalConfirm.openModal()
          }
        }
        );

      } else {
        this.modalConfirm.shutDown();
      }
    }
  }

  /**
   * @ignore
   */
  createRequestEditDelete() {
    if (this.deleteorEdit) {
      const dataDelete = this.categorizationStoreService.getDeleteData();
      const dataDeleteFinal:any[]=[]
      Object.keys(dataDelete).forEach((item:any)=>{
        dataDeleteFinal.push(dataDelete[item])
      })
      const dataDeleteBack=this.mappingService.categorizationSaveFtoB(dataDeleteFinal)
      this.numberElementsDelete = Object.keys(dataDelete).length;
      this.saveData.dataToDelete=dataDeleteBack
      const dataEdit = this.categorizationStoreService.getPreviousEditData();
      if (Object.keys(dataEdit).length != 0) {
        this.morePopUps = true;
      }
    } else {
      this.morePopUps = false;
      const previousRowsEdited= this.categorizationStoreService.getPreviousEditData();
      const newRowsEdited = this.categorizationStoreService.getNewEditData()
      const previousRowsEditedFinal: any = [];
      const newRowsEditedFinal: any = [];
      Object.keys(previousRowsEdited).forEach((item:any)=>{
        previousRowsEditedFinal.push(previousRowsEdited[item])
        newRowsEditedFinal.push(newRowsEdited[item])
      })
      const previousFinal=this.mappingService.categorizationSaveFtoB(previousRowsEditedFinal)
      const newFinal=this.mappingService.categorizationSaveFtoB(newRowsEditedFinal)
      this.saveData.previousDataToUpdate=previousFinal
      this.saveData.newDataToUpdate =  newFinal;
    }
  }


  /**
   * @ignore
   */
  postResponseEditDelete(data: any) {
    if (
      data.data.dbOperationResultDTOList[0].elementsModified > 0 &&
      data.data.dbOperationResultDTOList[1].elementsModified > 0
    ) {
      this.alertMessage('Grabado exitosamente', { status: 'ok' });
    } else if (data.data.dbOperationResultDTOList[0].elementsModified > 0) {
      this.alertMessage('Actualizado Exitosamente', { status: 'ok' });
    } else {
      this.alertMessage('Eliminado Exitosamente', { status: 'ok' });
    }
    this.categorizationService.removeSymbolFilter();
    this.saveData = {
      dataToDelete: [],
      previousDataToUpdate: [],
      newDataToUpdate: []
    };
    this.categorizationStoreService.emptyPreviousEditData();
    this.categorizationStoreService.emptyDeleteData();
    this.categorizationStoreService.emptyData();
    this.categorizationStoreService.setCurrentPage(1);
    this.categorizationStoreService.setTotal(0);
    this.categorizationStoreService.setReload(true);
    this.categorizationStoreService.setHaveSearchFilter(true);
    this.categorizationService.getAutocompleteTable();
    this.resetDataNewField();
  }

  /**
   * Permite crear una nueva homologación.
   * @param event Indica si se cancela lo operacion o se continua (1 o 0).
   */
  onDecisionHomologation(event: any) {
    if (event) {
      this.circleLoading.show();
      this.categorizationService
        .createTypology(this.createTypologies,this.crm)
        .subscribe({
          next: () => {
            this.alertMessage('Homologación creada exitosamente', {
              status: 'ok',
            });
            this.categorizationStoreService.setHaveSearchFilter(true);
          },
          error: () => { this.circleLoading.hide(); },
          complete: () => {
            this.circleLoading.hide();
            this.isClicked = true;
            this.categorizationStoreService.setReload(true);
          }
        });
      this.modalConfirmHomologation.shutDown();
      this.close();
    } else {
      this.modalConfirmHomologation.shutDown();
      this.modalNew.openModal();
    }
  }

  /**
 * Reestablece los valores del filtro de busqueda.
 * @param event Indica si se cancela lo operacion o se continua (1 o 0).
 */
  restartValueQr() {
    return {
      page: 0,
      elements: 7,
      keyWord: '',
      filterObject: {
        idEntity: '',
        entityName: '',
        idProduct: '',
        product: '',
        idTypology: '',
        typology: '',
        sfcProduct: {
          sfcId: '',
          sfcDetail: '',
        },
        sfcMotive: {
          sfcId: '',
          sfcDetail: '',
        },
        sfcChannel: {
          sfcId: '',
          sfcDetail: '',
        },
        sfcDigitalProduct: {
          sfcId: '',
          sfcDetail: '',
        },
      },
    };
  }

  /**
   * @ignore
   */
  modalConfirmOperation(option: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      Swal.fire({
        title:
          "<p style='color:black'>Hay cambios sin guardar,esta seguro que desea continuar</p>",
        position: 'top',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        allowOutsideClick: false,
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'customButton customButton-primary',
          cancelButton: 'customButton customButton-secondary',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestGeneralSearch(option);
        }
        resolve(result.isConfirmed);
      });
    });
  }

  /**
   * @ignore
   */
  requestGeneralSearch(option: number) {
    this.categorizationStoreService.emptyPreviousEditData();
    this.categorizationStoreService.emptyDeleteData();
    this.categorizationStoreService.setLoading(true);
    let resData: any;

    if (option == 0) {
      let qr = this.categorizationStoreService.getRequestData().getValue();
      if (this.total == 0) {
        qr = this.restartValueQr();
      }
      qr.keyWord = this.filterText
      qr.page = 0;
      this.categorizationService.listFilter(qr,this.crm).subscribe({
        next: (data: any) => {
          data.page = 0;
          resData = this.mappingService.categorizationDataBtoF(data);

          this.categorizationStoreService.setTotal(
            resData.totalElements
          );
          this.categorizationStoreService.setCurrentPage(1);
          this.categorizationStoreService.setAllData(resData);
          this.categorizationStoreService.setRequestData(qr);
          this.categorizationStoreService.setIsFilter(true);
          this.categorizationStoreService.setLoading(false);
        },
      });
    } else {
      this.filterText = '';
      this.categorizationStoreService.setRequestData(this.restartValueQr());
      this.categorizationStoreService.setIsFilter(false);
      this.categorizationStoreService.setIsSearch(false);
      this.categorizationStoreService.emptyData();
      this.categorizationStoreService.setCurrentPage(1);
      this.categorizationStoreService.setTotal(0);
      this.categorizationStoreService.setReload(true);
    } 
  }

  /**
   * Permite hacer una busqueda general en cualquier columna de homologaciones.
   * @param option 0 is search and 1 is clean
   */
  generalSearch(option: number) {
    const elementsToDelete = this.categorizationStoreService.getDeleteData();
    const elementsToEdit = this.categorizationStoreService.getPreviousEditData();
    if (
      Object.keys(elementsToDelete).length != 0 ||
      Object.keys(elementsToEdit).length != 0
    ) {
      this.modalConfirmOperation(option);
    } else {
      this.requestGeneralSearch(option);
    }
  }

  /**
   * @ignore
   */
  newHomologation() {
    this.categorizationService.entitiesList(this.crm).subscribe({
      next: (data: any) => {
        if (data) {
          this.entities = this.mappingService.categorizationDataEntities(data);
          this.entities.sort((c, d) =>
            c.label.toLowerCase().localeCompare(d.label.toLowerCase())
          );
        }
      },
    });
    this.modalNew.openModal();
    this.digitalProductSFC = [];
    this.motiveFields = [];
    this.channelFields = [];
    this.digitalFields = [];
    this.callCategorization('product', this.digitalProductSFC);
    this.callCategorization('motive', this.motiveFields);
    this.callCategorization('channel', this.channelFields);
    this.callCategorization('digital', this.digitalFields);
  }

  /**
   * @ignore
   */
  callCategorization(category: string, targetArray: IProducts[]): void {
    this.categorizationService.sfcFields(category).subscribe({
      next: (data: IProducts[]) => {
        if (data) {
          targetArray.push(
            ...this.mappingService.categorizationDataEntities(data)
          );

          if (category == 'channel') {
            this.completechannelFields = this.channelFields;
            this.channelFields = this.channelFields.filter(
              (item) => item.label !== 'null vacio'
            );

            this.channelFields.sort(
              (a, b) => parseInt(a.ref) - parseInt(b.ref)
            );
          }
          targetArray.sort((a, b) => parseInt(a.ref) - parseInt(b.ref));
        }
      },
    });
  }

  /**
   * Permite determinar si un campo del formulario de creación esta vacío.
   * @param field es el campo que se desea analizar
   * @return true si el campo esta lleno, false si este se encuentra vacío
   */
  isEmpty(field: string): boolean {
    if (this.newHomologationForm.value[field] != '') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Al seleccionar una nueva entidad se reinician los demas campos del formulario,
   * ademas se hace la busqueda de los productos asociados a la entidad seleccionada y
   *  se organizan de manera alfabetica
   * @param e es el código ("hmlRefCod") de la entidad
   */
  selectedEntity(e: any) {
    this.disabledProduct = false;
    const objetoBuscado = this.entities.find((object) => object.value === e);

    this.newHomologationForm.controls['product'].setValue('');
    if (
      this.newHomologationForm.controls['idtypology'].value != '' ||
      this.newHomologationForm.controls['typologyName'].value != ''
    ) {
      this.newHomologationForm.controls['idtypology'].reset();
      this.newHomologationForm.controls['typologyName'].reset();
    }
    if (this.newProduct) {
      this.newProduct = false;
      this.newHomologationForm.controls['idproduct'].reset();
      this.newHomologationForm.controls['productName'].reset();
    }

    if (objetoBuscado) {
      this.createTypologies.idOrEntity = objetoBuscado.value;
      this.createTypologies.idEntity = objetoBuscado.ref;
      this.createTypologies.entityName = objetoBuscado.label;
    }
    this.categorizationService.productsList(e, this.crm).subscribe({
      next: (data: any) => {
        if (data) {
          const productos: IProducts[] =
            this.mappingService.categorizationDataProducts(data);
          productos.sort((c, d) =>
            c.label.toLowerCase().localeCompare(d.label.toLowerCase())
          );
          this.products = newProductDefault.concat(productos);
          this.productsToValidate =
            this.mappingService.categorizationDataTypologies(data);

        } else {
          this.products = newProductDefault;
          this.productsToValidate = [];
        }
        this.disabledProduct = true
      },
    });
  }

  /**
   * Permite determinar si un campo del formulario presenta errores o ha sido tocado.
   * @param field campo que se desea analizar
   * @return true si el campo ha sido alterado y mal diligenciado, null si no presenta error y no ha sido alterado
   */
  isValidField(field: string) {
    return (
      this.newHomologationForm.controls[field].errors &&
      !this.newHomologationForm.controls[field].pristine
    );
  }

  /**
   * Permite determinar si un campo del formulario es valido o no
   * @param field campo que se desea analizar
   * @return true si el campo cumple las validaciones, false en caso de presentar error
   */
  validField(field: string) {
    return this.newHomologationForm.controls[field].valid;
  }

  /**
   * Indica cual es el error presentado en los campos del formulario de nueva Homologacion.
   * @param field campo que sera analizado
   * @return mensaje que sera muestra en el formulario dependiendo del error o null en caso de no tener errores
   */
  getFieldError(field: string) {
    if (!this.newHomologationForm.controls[field]) {
      return null;
    }
    const errors = this.newHomologationForm.controls[field].errors || {};
    for (const key of Object.keys(errors)) {
      switch (key) {
        case 'required':
          return 'El campo es requerido';
        case 'numeric':
          return 'El campo debe ser numérico';
        case 'TypologyExists':
          return 'Tipología existente';
        case 'ProductExists':
          return 'Producto existente';
      }
    }
    return null;
  }
  /**
   * Al seleccionar un producto en el formulario de nuevas homologaciones se reestablecen los campos de Tipología y se buscan las tipologias que esten asociadas a este producto.
   * @param e corresponde al codigo ("productCod") del producto seleccionado
   */
  showProductOptions(e: any) {
    if (
      this.newHomologationForm.controls['idtypology'].value != '' ||
      this.newHomologationForm.controls['typologyName'].value != ''
    ) {
      this.newHomologationForm.controls['idtypology'].reset();
      this.newHomologationForm.controls['typologyName'].reset();
    }
    if (e == null) {
      this.newProduct = true;
      this.validators();
    } else {
      this.newProduct = false;
      this.categorizationService.typologiesList(e).subscribe({
        next: (data: any) => {
          this.typologies =
            this.mappingService.categorizationDataTypologies(data);
          this.validators();

          const objetoBuscado = this.products.find(
            (object) => object.value === e
          );
          if (objetoBuscado) {
            this.createTypologies.idOrProduct = objetoBuscado.value;
            this.createTypologies.idProduct = objetoBuscado.ref;
            this.createTypologies.product = objetoBuscado.label;
          }
        },
      });
    }
  }

  /**
   * Valida si se ha dado clic en cancelar o guardar al abrir el formulario de nueva homologacion y si el campo canal esta vacio o no.
   * @param e 1 si se ha dado clic en guardar y 0 si se ha dado clic en cancelar.
   */
  presave(e: any) {
    if (e) {
      if (this.newHomologationForm.value['channelCode']) {
        this.modalNew.shutDown();
        this.save();
      } else {
        this.modalNew.shutDown();
        this.modalChannel.openModal();
      }
    } else {
      this.close();
      this.newHomologation();
    }
  }

  /**
   * Genera la informacion que sera mostrada en el modal de confirmacion al crear una nueva homologación.
   */
  save() {
    this.completeFields();
    this.tableValue = this.mappingService.finalValidationTable([
      this.createTypologies,
    ]);
    this.modalConfirmHomologation.openModal();
  }

  /**
   * Teniendo en cuenta los datos registrados en el formulario de la nueva homologacion, crea un objeto llamado "createTypologies"
   */
  completeFields() {
    this.createTypologies.idTypology =
      this.newHomologationForm.value['idtypology'];
    this.createTypologies.typology =
      this.newHomologationForm.value['typologyName'];

    let objetoBuscado = this.digitalProductSFC.find(
      (object) => object.ref === this.newHomologationForm.value['productCode']
    );

    //Codigo Producto SFC
    if (objetoBuscado) {
      this.createTypologies.sfcProduct.hmlRefId = objetoBuscado.value;
      this.createTypologies.sfcProduct.sfcDetail = objetoBuscado.label;
      this.createTypologies.sfcProduct.sfcId = objetoBuscado.ref;
    } else {
      this.createTypologies.sfcProduct.hmlRefId = null;
      this.createTypologies.sfcProduct.sfcDetail =
        this.newHomologationForm.value['productDetail'];
      this.createTypologies.sfcProduct.sfcId =
        this.newHomologationForm.value['productCode'];
    }

    objetoBuscado = this.motiveFields.find(
      (object) => object.ref === this.newHomologationForm.value['motiveCode']
    );

    //Motive SFC
    if (objetoBuscado) {
      this.createTypologies.sfcMotive.hmlRefId = objetoBuscado.value;
      this.createTypologies.sfcMotive.sfcDetail = objetoBuscado.label;
      this.createTypologies.sfcMotive.sfcId = objetoBuscado.ref;
    } else {
      this.createTypologies.sfcMotive.hmlRefId = null;
      this.createTypologies.sfcMotive.sfcDetail =
        this.newHomologationForm.value['motiveDetail'];
      this.createTypologies.sfcMotive.sfcId =
        this.newHomologationForm.value['motiveCode'];
    }

    objetoBuscado = this.channelFields.find(
      (object) => object.ref === this.newHomologationForm.value['channelCode']
    );

    //Channel SFC
    if (objetoBuscado) {
      this.createTypologies.sfcChannel.hmlRefId = objetoBuscado.value;
      this.createTypologies.sfcChannel.sfcDetail = objetoBuscado.label;
      this.createTypologies.sfcChannel.sfcId = objetoBuscado.ref;
    } else {
      if (this.newHomologationForm.value['channelCode'] === '') {
        const empty = this.completechannelFields.find(
          (object) => object.ref === 'NoDataSSv'
        );
        this.createTypologies.sfcChannel.hmlRefId = empty!.value;
        this.createTypologies.sfcChannel.sfcDetail = empty!.label;
        this.createTypologies.sfcChannel.sfcId = empty!.ref;
      } else {
        this.createTypologies.sfcChannel.hmlRefId = null;
        this.createTypologies.sfcChannel.sfcDetail =
          this.newHomologationForm.value['channelDetail'];
        this.createTypologies.sfcChannel.sfcId =
          this.newHomologationForm.value['channelCode'];
      }
    }

    objetoBuscado = this.digitalFields.find(
      (object) =>
        object.ref === this.newHomologationForm.value['digitalProductCode']
    );

    //Digital product SFC
    if (objetoBuscado) {
      this.createTypologies.sfcDigitalProduct.hmlRefId = objetoBuscado.value;
      this.createTypologies.sfcDigitalProduct.sfcDetail = objetoBuscado.label;
      this.createTypologies.sfcDigitalProduct.sfcId = objetoBuscado.ref;
    } else {
      this.createTypologies.sfcDigitalProduct.hmlRefId = null;
      this.createTypologies.sfcDigitalProduct.sfcDetail =
        this.newHomologationForm.value['digitalProduct'];
      this.createTypologies.sfcDigitalProduct.sfcId =
        this.newHomologationForm.value['digitalProductCode'];
    }

    if (this.newProduct) {
      this.createTypologies.idOrProduct = null;
      this.createTypologies.idProduct =
        this.newHomologationForm.value['idproduct'];
      this.createTypologies.product =
        this.newHomologationForm.value['productName'];
    }
  }

  /**
   * Restaura los valores por defecto al cerrar el formulario.
   */
  close() {
    this.newHomologationForm.reset(defaultValuesForm);
    this.productSFCDisable = null;
    this.motiveSFCDisable = null;
    this.channelSFCDisable = true;
    this.digitalProductSFCDisable = null;
    this.newProduct = false;
    this.entities = [];
    this.products = newProductDefault;
    this.clearAndUpdateValidator('channelDetail');
    this.modalNew.shutDown();
  }

  /**
   * Limpia los validators de los campos del formulario de nuevas homologaciones.
   * @param field es el campo al cual se le limpiaran los validators.
   */
  clearAndUpdateValidator(field: string) {
    this.newHomologationForm.get(field)?.clearValidators();
    this.newHomologationForm.get(field)?.updateValueAndValidity();
  }

  /**
   * Agrega validators a los campos del formulario de nueva homologacion (producto y tipologia)
   */
  validators() {
    if (this.newProduct) {
      this.newHomologationForm
        .get('idproduct')
        ?.setValidators([Validators.required, CustomValidator.numeric]);
      this.newHomologationForm
        .get('productName')
        ?.setValidators([Validators.required]);
    } else {
      this.clearAndUpdateValidator('idproduct');
      this.clearAndUpdateValidator('productName');
    }
    this.newHomologationForm
      .get('idtypology')
      ?.setValidators([
        Validators.required,
        CustomValidator.numeric,
        validateColumn(this.typologies, 'TypologyExists'),
      ]);
    this.newHomologationForm
      .get('idproduct')
      ?.setValidators([
        CustomValidator.numeric,
        validateColumn(this.productsToValidate, 'ProductExists'),
      ]);
  }

  /**
   * Se encarga del validar que el campo producto contenga valores correctos
   */
  productValidity() {
    if (this.newProduct) {
      if (this.isEmpty('productName') && this.isEmpty('idproduct')) {
        return this.validField('productName') && this.validField('idproduct');
      } else {
        return false;
      }
    } else {
      if (this.newHomologationForm.value['product'] != '') {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Despliega el modal de validacion en caso de que el campo canal se encuentre vacio.
   * @param e 1 corresponde a aceptar, 0 cancelar la operacion.
   */
  emptyChannelConfirm(e: any) {
    if (e) {
      this.save();
    } else {
      this.modalNew.openModal();
    }
    this.modalChannel.shutDown();
  }

  /**
   * Determina si los campos Entidad, Prodcuto y Tipologia ya han sido digitados y no presentan errores.
   * Esto permite que los demas campos del formulario de nueva homologacion sean habilitados.
   * @return true si hay error en alguno de los campos, null en caso de que hayan sido digitados correctamente.
   */
  showAllFields() {
    if (
      this.validField('typologyName') &&
      this.validField('idtypology') &&
      this.productValidity()
    ) {
      return null;
    } else {
      return true;
    }
  }

  /**
   * Se encarga de autocompletar los campos si los campos Entidad, Producto y Tipologia ya han sido digitados y no presentan errores.
   * @param field corresponde al id del campo
   * @param field2 corresponde al detalle del campo
   */
  showSelectedValue(field: string, field2: string) {
    const value = this.newHomologationForm.value[field];
    let items: any[];

    switch (field) {
      case 'productCode':
        items = this.digitalProductSFC;
        this.productSFCDisable = this.setFormValueAndDisable(
          items,
          value,
          field2,
          this.productSFCDisable
        );
        break;

      case 'motiveCode':
        items = this.motiveFields;
        this.motiveSFCDisable = this.setFormValueAndDisable(
          items,
          value,
          field2,
          this.motiveSFCDisable
        );
        break;

      case 'channelCode':
        items = this.channelFields;
        this.channelSFCDisable = this.setFormValueAndDisable(
          items,
          value,
          field2,
          this.channelSFCDisable
        );
        if (this.newHomologationForm.get('channelCode')?.value == '') {
          this.newHomologationForm.get('channelDetail')?.clearValidators();
          this.newHomologationForm
            .get('channelDetail')
            ?.updateValueAndValidity();
          this.channelSFCDisable = true;
        } else {
          this.newHomologationForm
            .get('channelDetail')
            ?.setValidators(Validators.required);
        }
        break;

      case 'digitalProductCode':
        items = this.digitalFields;
        this.digitalProductSFCDisable = this.setFormValueAndDisable(
          items,
          value,
          field2,
          this.digitalProductSFCDisable
        );
        break;
    }
  }

  /**
   * Se encarga de autocompletar el campo detalle en caso de que se exista el id en la BD
   * @param items contiene, id, cod y detalle de los campos
   * @param value codigo digitado en el formulario de nueva homologacion
   * @param field2 campo que sera autocompletado
   * @param disabled booleano que indica si esta deshabilitado o no el campo autocompletado
   * @return true si el campo de detalle debe ser deshabilitado o null si se habilita
   */
  setFormValueAndDisable(
    items: any[],
    value: any,
    field2: string,
    disabled: boolean | null
  ): boolean | null {
    for (const item of items) {
      if (item.ref === value) {
        this.newHomologationForm.controls[field2].setValue(item.label);
        return true;
      }
    }
    if (disabled) {
      this.newHomologationForm.controls[field2].setValue('');
    }
    return null;
  }

  /**
   * Se encarga de abrir el modal de nueva homologacion
   */
  clickNew() {
    const elementsToDelete = this.categorizationStoreService.getDeleteData();
    const elementsToEdit = this.categorizationStoreService.getPreviousEditData();
    if (
      Object.keys(elementsToDelete).length != 0 ||
      Object.keys(elementsToEdit).length != 0
    ) {
      this.modalContinueNew.openModal();
    } else {
      this.newHomologation();
    }
  }

  /**
   * Valida si se esta editando alguna homologacion y si se desea continuar o no descartando los cambios.
   * @param e 1 en caso de dar clic en confirmar, 0 para el caso de cancelar
   */
  openNew(e: any) {
    this.modalContinueNew.shutDown();
    if (e) {
      this.categorizationStoreService.emptyPreviousEditData();
      this.categorizationStoreService.emptyDeleteData();
      this.generalSearch(1);
      this.newHomologation();
    }
  }

  switchCrm(evento:any){
    if(this.crm != evento){
      this.crm = evento      
      this.circleLoading.show();
    }
    this.circleLoading.hide();
  }
}
