import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { IChangeStateResponse, ICreateHomologation, IDetailFilter, ISfcValuesResponse, ITransmissionFields } from '../../models/transmission/ITransmission';
import { map } from 'rxjs';
import { MappingService } from '../mapping/mapping.service';
import { IFilterDetail } from '../../models/transmission/IFilterDetail';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService {

  public transmissionUrl = environment.api + "transmission/";

  constructor(private http: HttpClient, private mappingService: MappingService) { }




  /**
   * Obtiene los valores para autocompletar la tabla.
   */
  listFields(crm: string) {
    return this.http.get(this.transmissionUrl + "fields/" + crm).pipe(
      map((response: any) => {
        return {
          total: response.length,
          data: response.data.sort((a: ITransmissionFields, b: ITransmissionFields) => a.name.localeCompare(b.name))
            .map((item: ITransmissionFields) => (
              this.mappingService.transmissionDataBtoF(item)
            ))
        }
      })
    );
  }

  /**
   * Obtiene los valores del detalle de las tablas de transmision detalle.
   * @param momento: 1 en caso de que se haya seleccionado "Momento 1 y 4", o 2 en caso de haber seleccionado "Momento 2 y 3"
   */
  listDetail(momento: number, field: string, crmType: string = "SAP", page = 0, elements = "7") {
    const url = this.transmissionUrl + `detail/findBy?field=${field}&moment=${momento}&page=${page}&elements=${elements}&crmType=${crmType}`
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return {
          total: response.data.totalElements,
          data: response.data.objectList.map((item: any) => (
            this.mappingService.transmissionDetail(item, momento)
          ))
        }
      }
      ))
  }

  /**
 * Obtiene los valores del detalle de las tablas de transmision detalle.
 * @param momento: 1 en caso de que se haya seleccionado "Momento 1 y 4", o 2 en caso de haber seleccionado "Momento 2 y 3"
 */
  downloadDetail(momento: number, field: string, crmType: string) {
    const url = this.transmissionUrl + `detail/download?field=${field}&moment=${momento}&crmType=${crmType}`
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return {
          data: response.data.map((item: any) => (
            this.mappingService.transmissionDetail(item, momento)
          ))
        }
      }
      ))
  }

  /**
 * Obtiene los valores del cada columna de la tabla de detalle.
 * @param columnName: Columna para la cual se quiere extraer la informacion
 * @param field: Campo del cual se esta observando el detalle de las transmisiones
 * @param moment:  1 en caso de que se haya seleccionado "Momento 1 y 4", o 2 en caso de haber seleccionado "Momento 2 y 3"
 * @param crmType: Tipo de Crm seleccionado, ya sea "Sap" o "Salesforce"
 */
  detailFilter(columnName: string, field: number, moment: number, crmType: string) {
    const url = this.transmissionUrl + `detail/filter/words?columnName=${columnName}&field=${field}&moment=${moment}&crmType=${crmType}`
    return this.http.get<IDetailFilter>(url);
  }

  listFilter(word: string, crm: string) {
    const body = { keyWord: word }
    return this.http.post(this.transmissionUrl + "filter/" + crm, body).pipe(
      map((response: any) => {
        return {
          total: response.data.length,
          data: response.data.sort((a: ITransmissionFields, b: ITransmissionFields) => a.name.localeCompare(b.name))
            .map((item: ITransmissionFields) => (
              this.mappingService.transmissionDataBtoF(item)
            ))
        };
      })
    )
  }


  editDetail(data: any, moment: number, field: string, crmType:string) {
    return this.http.post(this.transmissionUrl + `detail/save?moment=${moment}&field=${field}&crmType=${crmType}`, data);
  }

  /**
 * Obtiene los valores del cada columna de la tabla de detalle.
 * @param field: Campo en al cual se le cambiara uno de sus estados
 * @param moment:  Momento que se desea activar o desactivar
 * @param crmType: Tipo de Crm seleccionado, ya sea "Sap" o "Salesforce"
 */
  changeState(field: ITransmissionFields, moment: number, crmType: string) {
    const element = this.mappingService.changeStateTransmissionSwitch(field, crmType);
    return this.http.patch<IChangeStateResponse>(this.transmissionUrl + `state/${moment}/${crmType}`, element);
  }

  /**
 * Lista las homologaciones dado un filtro.
 */
  listFilterDetail(data: IFilterDetail, field: string, moment: string, crmType: string) {
    return this.http.post(this.transmissionUrl + `detail/filter/${field}/${moment}/${crmType}`, data).pipe(
      map((response: any) => {
        return {
          total: response.data.totalElements,
          data: response.data.objectList.map((item: any) => (
            this.mappingService.transmissionDetail(item, Number(moment))
          ))
        }
      }
      ))
  }


  /**
* Crea una nueva homologacion
*/
  createHomologation(newHomologation: ICreateHomologation, moment: number, field: string, crmType:string) {

    return this.http.post(this.transmissionUrl + `detail/new?moment=${moment}&field=${field}&crmType=${crmType}`, newHomologation)


  }


  /**
   * Extrae los valores de la SFC para realizar el autocompletado de los campos 
   */

  filterSfcValues(field: string, channel: number = parseInt("4")) {
    return this.http.get<ISfcValuesResponse>(this.transmissionUrl + `detail/filter/sfcValues?field=${field}&channel=${channel}`).pipe(
      map((response: ISfcValuesResponse) => {
        return response.data;
      })
    )
  }


}
