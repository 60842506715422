import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { HistoryDTO } from 'src/app/core/models/history/history';
import { HistoryService } from 'src/app/core/services/history/history.service';
import { HistoryStoreService } from 'src/app/core/stores/historyStore/history-store.service';
import { BaseTable } from 'src/app/shared/components/baseTable/BaseTable';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html'
})
export class HistoryTableComponent extends BaseTable{
  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;
  @Input() crmType: string = "";
  @Input() tableType: string = "";
  public columns:string[]=[]
  public loading:boolean=true
  public isSearch:boolean=false
  public searchData!:HistoryDTO;
  
  constructor(
    private cdr: ChangeDetectorRef,
    public historyStoreService: HistoryStoreService,
    public historyService: HistoryService
  ) {
    super(historyService, historyStoreService);
  }

  ngOnInit(){


    this.historyStoreService.getLoading().subscribe((value:boolean)=>{
      this.loading=value
    })

    this.historyStoreService.getData().subscribe((data: any) => {
      if (Object.keys(data).length != 0) {
        this.reorganizarData(data);
      }
    });

    this.historyStoreService.getIsSearch().subscribe((value:boolean)=>{
      this.isSearch=value
    })

    this.historyStoreService.getTotal().subscribe((value:number)=>{
      this.total=value
    })

    this.historyStoreService
      .getCurrentPage()
      .subscribe((pageNumber: number) => {
        this.currentPage = pageNumber;
      });

    this.historyStoreService.getFormData().subscribe((value: any) => {
        this.searchData = value;
      });

    this.historyStoreService.getReload().subscribe((value:boolean)=>{
        this.getHistoryData(0)
      })

  }


  getHistoryData(page:number){
    if(this.isSearch){
      this.searchData.page=page
      this.historyService.download(this.searchData,this.crmType).subscribe({
        next:(response:any)=>{
        this.historyStoreService.setData(response,page)
        this.historyStoreService.setTotal(response.total)
        this.historyStoreService.setLoading(false)
        },
        complete:()=>{
          this.circleLoading.hide();
        }
    })
    }else{
      this.historyService.list(page,this.tableType,this.crmType,parseInt("7")).subscribe({
        next:(response:any)=>{
          this.historyStoreService.setData(response,page)
          this.historyStoreService.setTotal(response.total)
          this.historyStoreService.setLoading(false)
      },
      complete:()=>{
        this.circleLoading.hide();
      }
    }
      )
    }


  }

  onChangePage(paginationData: any) {
    this.circleLoading.show();
    this.itemsPerPage = paginationData.PerPage;
    this.currentPage = paginationData.CurrentPage;
    const changePage = this.currentPage - 1;
    const valuesPage = this.historyStoreService.getData().getValue();
    const exists = Object.keys(valuesPage).includes(changePage.toString());
    if(exists){
      this.reorganizarData(valuesPage)
    }else{
      this.getHistoryData(changePage)
    }
  }

  reorganizarData(data: any) {
    this.arrayData = data[this.currentPage - 1];
    this.currentPageItems = this.arrayData;
    if(this.arrayData.length>0){
      this.columns=Object.keys(this.arrayData[0])
      
    }

    this.cdr.detectChanges();
    setTimeout(() => {   
      this.circleLoading.hide();
    }, parseInt("200"));
  }


}
