import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HistoryDTO } from '../../models/history/history';

@Injectable({
  providedIn: 'root'
})
export class HistoryStoreService {

  private data=new BehaviorSubject<{[key:number]:any}>({});
  private isSearch=new BehaviorSubject<boolean>(false);
  private loading=new BehaviorSubject<boolean>(true)
  private currentPage=new BehaviorSubject<number>(1);
  private reload=new BehaviorSubject<boolean>(false);
  private formData=new BehaviorSubject<HistoryDTO>({
    page: 0,
    elements: 7,
    table:"VCategorizacion",
    keyWord: "",
    startDate:"",
    endDate: ""
  })
  private totalElements=new BehaviorSubject<number>(0);

  emptyData(){
    this.data.next({})
  }

  getCurrentPage(){
    return this.currentPage
  }

  setCurrentPage(value:number){
    this.currentPage.next(value)
  }

  getTotal(){
    return this.totalElements
  }

  setTotal(value:number){
    this.totalElements.next(value)
  }


  getFormData(){
    return this.formData
  }

  setFormData(value:any){
    this.formData.next(value)
  }

  getData(){
    return this.data
  }

  setData(value:any,page:number){
    const numberPage=page
    const newValue=value.data
    const tempData: any = {...this.data.getValue(), [numberPage]: newValue};
    this.data.next(tempData);
  }

  setAllData(value:any,page:number){
    const newData={[page]:value.data};
    this.data.next(newData)
  }

  getLoading(){
    return this.loading
  }

  setLoading(value:boolean){
    this.loading.next(value)
  }

  getIsSearch(){
    return this.isSearch
  }

  setIsSearch(value:boolean){
    this.isSearch.next(value)
  }

  getReload(){
    return this.reload
  }

  setReload(value:boolean){
    this.reload.next(value)
  }


}
