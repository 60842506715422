<div class="bc-row bc-margin-top-2">
  <div class="bc-card">
    <div class="bc-card-body">
      <div class="bc-row bc-justify-content-center">
        <h1>Historial de Modificación</h1>
      </div>
      <br />

      <div class="bc-page-header">

        <div class="bc-container bc-page-header-content">
          <form [formGroup]="searchForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
            <br>
            <div class="bc-row">
              <div class="bc-page-header-content-left bc-col-2 bc-col-xs-12">
                <bc-input-select componentId="bc-select1" [values]="selectedTable" label="Historial"
                  formControlName="table" (valueOutput)="searchHistorySelect($event)">
                </bc-input-select>
              </div>
              <div class="bc-page-header-content-left bc-col-2 bc-col-xs-12">
                <bc-input-select componentId="bc-select2" [values]="crms" [defaultIndex]=0 label="CRM"
                  (valueOutput)="switchCrm($event)" id="CrmSelectTransmission">
                </bc-input-select>
              </div>
              <div class="bc-col-3 ">
                <app-total-box [total]="total"></app-total-box>
              </div>
            </div>
            <br />
            <div class="bc-row">
              <div class="bc-page-header-content-left bc-col-2 bc-col-xs-12">
                <bc-form-field>
                  <input bc-input id="search" type="text" aria-label="Buscar" formControlName="keyWord"
                    (keyup.enter)="searchHistory()" />
                  <label for="search">Palabra Clave</label>
                </bc-form-field>
              </div>

              <div class="bc-col-5">
                <bc-input-date class="bc-col-xl-6 bc-col-lg-6 bc-col-md-6 bc-col-sm-12 bc-col-xs-6"
                  CalendarFilter='[0,"hoy"]' CalendarHoliday="yes" type="simple" maxDate={{maxDate}}
                  (updateValue)="initialDate($event)">
                  <bc-form-field>
                    <em>calendar</em>
                    <input bc-input typeInput="input-default-left" type="date" formControlName="startDate" />
                    <em class="bc-input-select-toggle">arrow2-down</em>
                    <label for="idInput1">
                      Fecha Inicial
                    </label>
                    <span></span>
                  </bc-form-field>
                </bc-input-date>

                <div *ngIf="showFinalDate">
                  <bc-input-date class="bc-col-xl-6 bc-col-lg-6 bc-col-md-6 bc-col-sm-12 bc-col-xs-6"
                    CalendarFilter='[0,"hoy"]' CalendarHoliday="yes" type="simple" maxDate={{maxDate}}
                    minDate={{minDate}}  (updateValue)="finalDate($event)">
                    <bc-form-field>
                      <em>calendar</em>
                      <input bc-input typeInput="input-default-left" id="idInput2" type="date"
                        formControlName="endDate" />
                      <em class="bc-input-select-toggle">arrow2-down</em>
                      <label for="idInput2">
                        Fecha Final
                      </label>
                      <span></span>
                    </bc-form-field>
                  </bc-input-date>
                </div>
              </div>

              <div class="bc-col-2">
                <button (click)="searchHistory()" bc-button sizeButton="small">Buscar</button>
              </div>

              <div class="bc-col-2">
                <button (click)="cleanHistory()" bc-button sizeButton="small" typeButton="secondary">
                  Limpiar
                </button>
              </div>

              <div class="bc-col">
                <input (click)="downloadExcel()" type="image" [ngClass]="{ 'dark-image': isClicked }"
                  src="assets/icons/excel_48_color.svg" alt="excel" />
              </div>
            </div>
          </form>
        </div>

      </div>
      <br />
      <app-history-table [crmType]="crm" [tableType]="tableValue"></app-history-table>

    </div>
  </div>
</div>

<app-circle-loading></app-circle-loading>