
<div class="bc-row bc-margin-top-2">
  <div class="bc-card">
    <div class="bc-card-body">
      <div class="bc-row bc-justify-content-center">
        <h1>Homologación transmisión</h1>
      </div>
      <br />

      <div class="bc-page-header">
        <div class="bc-container bc-page-header-content" >
          <form [formGroup]="searchForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
            <div class="bc-page-header-content-left bc-col-2 bc-col-xs-12" >
              <br>
              <bc-input-select
                componentId="bc-select2"
                [values]="crms"
                label="CRM"
                formControlName="crm"
                (valueOutput)="switchCrm($event)"
                id="CrmSelectTransmission"
              >
              </bc-input-select>
            </div>
            <div class="bc-page-header-content-left bc-col-2 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input=""
                  id="search"
                  type="text"
                  class="bc-input"
                  aria-label="Buscar"
                  formControlName="keyWord"
                  (keyup.enter)="searchTransmission()"
                />
                <label for="search">Palabra Clave</label>
              </bc-form-field>
            </div>
          </form>
          <div class="bc-col-2">
            <button bc-button sizeButton="small" (click)="searchTransmission()">Buscar</button>
          </div>

          <div class="bc-col-2">
            <button bc-button sizeButton="small" typeButton="secondary" (click)="clearTransmission()">
              Limpiar
            </button>
          </div>

          <div class="bc-col-3">
            <app-total-box [total]="total"></app-total-box>
          </div>

          <div class="bc-page-header-content-right">
            <div class="bc-page-header-content-right-icons">
              <input
                (click)="downloadExcel()"
                [ngClass]="{ 'dark-image': isClicked }"
                type="image"
                src="assets/icons/excel_48_color.svg"
                alt="excel"
              />
            </div>
          </div>
        </div>
      </div>
        <app-transmission-table [crmValue]="crm"  ></app-transmission-table>      
    </div>
  </div>
</div>

<app-circle-loading></app-circle-loading>
