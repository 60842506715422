import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HistoryComponent } from './pages/history/history.component';

const historyRoutes: Routes = [
    {
      path: '', component: HistoryComponent,
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(historyRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [],
})

export class HistoryRoutingModule {
}
