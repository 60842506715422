<bc-header [menuInteraction]="true" headerType="bc-header-wrapper-black" [fixed]="true">
  <bc-header-left logoName="bancolombia-horizontal"
    channelName="Módulo Administrativo SmartSupervision"></bc-header-left>
  <bc-header-list *ngIf="user">
        <em class="bc-icon bc-sm">user</em>
        &nbsp;&nbsp;
        <p> {{user}}</p>
        &nbsp;&nbsp;&nbsp;
  </bc-header-list>
</bc-header>
