import { Injectable } from '@angular/core';
import { TableHeaders, thickCells, thinCells } from 'src/app/shared/constants/headers';
import { Cell, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { MappingService } from '../mapping/mapping.service';
import { IDownloadExcelData } from '../../models/categorization/ICategorizationTable';


@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  total = parseInt("15");
  mid = parseInt("7");
  thinCells = thinCells;
  thickCells = thickCells;

  public headers = TableHeaders;

  constructor(private mappingService: MappingService){}

  /**
   * Para el excel de homologacion categorizacion, se añaden, una fila con titulo y encabezado de cada columna, ademas se modifica el color y tamaño de las celdas
   * @param workSheet hoja de trabajo donde se realizaran los cambios.
   */
  addFirstsRows(workSheet: Worksheet) {
    const title = ["TABLA HOMOLOGACIÓN Producto, tipología, canal y producto digital"];
    const primera = workSheet.addRow(title)
    const col1 = primera.getCell(1);
    this.addColor(col1, 'D9D9D9');
    const headerNames = Object.values(this.headers[0]);
    workSheet.mergeCells("A1", "N1");
    workSheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    const header = workSheet.addRow(headerNames);
    for (let i = 1; i < this.total; i++) {
      const col = header.getCell(i);
      col.alignment = { vertical: 'middle', horizontal: 'center' }
      if (i < this.mid) {
        this.addColor(col, 'D9D9D9');
      }
      else {
        this.addColor(col, 'D9E1F2');
      }
      this.addBorder(col);
    };
  }

  /**
   * Modifica el color de una celda
   * @param cell celda que sera editada
   * @param color color con el cual se rellenara la celda
   */
  addColor(cell: Cell, color: string) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    }
  }

  /**
   * Añade todos los margenes a una celda
   * @param cell celda a la cual se le añaden margenes.
   */
  addBorder(cell: Cell) {
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }
  }

  /**
   * Modifica parametros de las columnas del archivo de excel.
   * @param workSheet Hoja de trabajo.
   */
  modifyWidth(workSheet: Worksheet) {
    workSheet.getRow(parseInt("2")).height = 50;
    workSheet.getRow(parseInt("2")).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true, };
    for (const i of this.thinCells) {
      workSheet.getColumn(parseInt(i)).width = 12
    }
    for (const i of this.thickCells) {
      workSheet.getColumn(parseInt(i)).width = 30
    }
  }

  /**
   * Genera las columnas con la informacion para el excel de homologacion categorizacion.
   */
  addData(data: IDownloadExcelData, workSheet: Worksheet) {

    const row = [Number(data.idEntity), data.entityName, Number(data.idProduct), data.product,
    Number(data.idTypology), data.typology, Number(data.sfcProduct.sfcId), data.sfcProduct.sfcDetail,
    Number(data.sfcMotive.sfcId), data.sfcMotive.sfcDetail, this.nodata(data.sfcChannel.sfcId),
    this.empty(data.sfcChannel.sfcDetail), Number(data.sfcDigitalProduct.sfcId), data.sfcDigitalProduct.sfcDetail]
    workSheet.addRow(Object.values(row));

  }

  /**
    * Genera el excel de categorización.
    * @param excelName Nombre del documento de excel
    * @param worksheetName  Nombre de la hoja de trabajo
    * @param element Data que sera insertada en las filas de la hoja de excel (informacion de las homologaciones).
    */
  downloadExcelCategorization(excelName: string, worksheetName: string, element: IDownloadExcelData[], crmType: string) {
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet(worksheetName);
    this.addFirstsRows(workSheet);
    this.modifyWidth(workSheet);

    element.forEach((data) => {
      this.addData(data, workSheet);
    }
    );
    for (let i = 1; i <= element.length + parseInt("2"); i++) {
      for (let v = 1; v <= parseInt("14"); v++) {
        this.addBorder(workSheet.getRow(i).getCell(v))
      }

    }

    this.save(workBook, excelName + ` ${crmType}`)
  }

  /**
   * Identifica que los datos correspondan a datos numericos
   * @param data es un string que debe contener solo numeros
   * @return "" en caso de recibir data que no corresponde a números.
   * @return Number(data) en caso de que data contenga solo caracteres númericos.
   */
  nodata(data: string) {
    if (isNaN(Number(data))) {
      return ""
    }
    else {
      return Number(data)
    }
  }

  /**
   * Identifica cuando se recibe un string con los caracteres "null vacio".
   * @param data string
   * @return "" en caso de recibir "null vacio" o el parametro de entrada en caso de ser diferente.
   */
  empty(data: string) {
    if (data == "null vacio") {
      return ""
    }
    else {
      return data
    }
  }

  /**
   * Genera el excel de transmisión.
   * @param excelName Nombre del documento de excel
   * @param worksheetName  Nombre de la hoja de trabajo
   * @param element Data que sera insertada en las filas de la hoja de excel (informacion de las homologaciones).
   */
  downloadExcelTransmission(excelName: string, worksheetName: string, element: any, crmType:string) {
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet(worksheetName);
    workSheet.getColumn(parseInt("1")).width = 40;
    for (let i = 2; i <= element.length + 1; i++) {
      workSheet.getColumn(i).width = 20
    }
    const titles = ["Campo Reporte", "Momento 1", "Momento 2", "Momento 3"]
    workSheet.addRow(titles)
    element.forEach((data: any) => {
      const row = [data["Campo Reporte"], this.isTrueOrFalse(data["Momento 1"]),
      this.isTrueOrFalse(data["Momento 2"]), this.isTrueOrFalse(data["Momento 3"])];
      workSheet.addRow(Object.values(row));
    })

    this.addFinalFormat(workSheet, titles, element , true)
    this.save(workBook, excelName + ` ${crmType}`)
  }

  /**
   * Identifica si la entrada es true or false y devulve un string
   * @param element true or false
   * @return "X" si la entrada es true y "" en caso de recibir un false
   */
  isTrueOrFalse(element: boolean) {
    if (element) {
      return "X"
    }
    else {
      return ""
    }
  }

  /**
   * Genera el excel del historial de cambios.
   * @param excelName Nombre del documento de excel
   * @param worksheetName  Nombre de la hoja de trabajo
   * @param element Data que sera insertada en las filas de la hoja de excel (informacion de las homologaciones).
   */

  downloadLogs(excelName: string, worksheetName: string, element: any) {
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet(worksheetName);
    const titles = Object.keys(element[0]);
    workSheet.addRow(titles)
    element.forEach((data: any) => {
      if (data["Código Canal"] == null) {
        data["Código Canal"] = ""
      }
      const row = data;
      workSheet.addRow(Object.values(row));
    })
    for (let i = 1; i <= titles.length; i++) {
      workSheet.getColumn(i).width = 20
    }
    this.addFinalFormat(workSheet, titles, element)
    this.save(workBook, excelName)

  }

  /**
   * Agrega colores a las casillas y margen al excel
   * @param workSheet Hoja de trabajo.
   * @param titles Titulos de las columnas.
   * @param element Contenido de las filas.
   * @param transmision Boleano que indica si es el excel de homologacion transmision o no.
   */
  addFinalFormat(workSheet:any, titles:string[], element:any, transmision?: boolean , detalle:number=0) {
    workSheet.getRow(1).height = 50;
    for (let i = 1; i < titles.length + 1; i++) {
      if (i % parseInt("2")) {
        this.addColor(workSheet.getRow(1+detalle).getCell(i), 'D9D9D9');
      }
      else {
        this.addColor(workSheet.getRow(1+detalle).getCell(i), 'D9E1F2');
      }
    };
    for (let i = 1; i <= element.length + 1 + detalle; i++) {
      if (transmision) {
        workSheet.getRow(i).alignment = { vertical: 'middle', horizontal: 'center', wrapText: false, };
      }

      workSheet.getRow(i).height = 20;
      for (let v = 1; v <= titles.length; v++) {
        this.addBorder(workSheet.getRow(i).getCell(v))
      }
    }
  }

  /**
 * Permite descargar el archivo de excel creado
 * @param workBook Libro de trabajo con los datos (puede contener varias hojas).
 * @param excelName Nombre del archivo de excel.
 */
  save(workBook:any, excelName:string) {
    workBook.xlsx.writeBuffer().then((data:any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, excelName);
    });
  }



  downloadExcelTransmissionDetail(excelName: string, worksheetName: string, element: any, momento:number, crmType:string) {
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet(worksheetName);
    const firstRow = [["Momento 1 y 4"],["Momento 2 y 3"]];
    const primera = workSheet.addRow(firstRow[`${momento}`])
    const col1 = primera.getCell(1);
    this.addColor(col1, 'D9D9D9');
    workSheet.mergeCells("A1", "D1");
    workSheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    const titles = [["Código SFC", "Nombre SFC", "Código CRM", "Nombre CRM"],
    ["Código CRM", "Nombre CRM", "Código SFC", "Nombre SFC"]]
    for (let i = 1; i <= titles[0].length; i++) {
      if(!(i%parseInt("2"))){
        workSheet.getColumn(i).width = 50
      }
      else{
        workSheet.getColumn(i).width = 20
      }

    }

    workSheet.addRow(titles[`${momento}`])
    element.forEach((data:any) => {
      if(momento==1){
        const row = [data["Código CRM"], data["Nombre CRM"], data["Código SFC"], data["Nombre SFC"]];
        workSheet.addRow(Object.values(row));
      }else{
        const row = [data["Código SFC"], data["Nombre SFC"], data["Código CRM"],data["Nombre CRM"]];
        workSheet.addRow(Object.values(row));
      }

    })

    this.addFinalFormat(workSheet, titles[`${momento}`], element , true, 1)
    const momentSelected = firstRow[momento]
    this.save(workBook, excelName +` ${momentSelected}`+ ` ${crmType}`)
  }
}

